<div class="show-more-page">
  <a [routerLink]="backLink" class="is-block is-position-relative">
    <svg-arrow-back class="is-position-absolute is-top-5px" />
    <h3 class="is-inline-block is-size-4 has-text-weight-bold has-text-deep-sea-green pl-5">
      {{ headline }}
    </h3>
  </a>
  <div *ngIf="subheadline" class="is-size-14px mb-2 pl-5">
    &nbsp;{{ subheadline }}
  </div>

  @if(teaserList && teaserList?.videos.length) {
  <ul class="is-grid-272px is-justify-content-space-between-desktop is-justify-content-space-around-touch" infiniteScroll
    [infiniteScrollDistance]="2" (scrolled)="onScroll()">

    @for (teaser of teaserList.videos; track teaser.id) {
    <li class="mb-5"><app-video-teaser [teaser]="teaser" size="small" [breadcrumb]="['Videos', categorySlug]" /></li>
    }
  </ul>
  }

  @if (loading) {
  <div class="is-flex is-justify-content-center">
    <app-ye-loading-spinner />
  </div>
  }

  @if (!loading && !teaserList?.videos.length) {
  <p i18n="Show More Page">Keine Ergebnisse</p>
  }
</div>