import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-teaser-slider-empty-message',
  standalone: true,
  templateUrl: './teaser-slider-empty-message.component.html',
  styleUrls: ['./teaser-slider-empty-message.component.sass'],
  imports: [CommonModule]
})
export class TeaserSliderEmptyMessageComponent {
  @Input() displayHeadline = true;
}
