import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LevelNumbers } from 'src/app/api/models/level-numbers';

@Component({
  selector: 'app-level-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './level-label.component.html',
  styleUrls: ['./level-label.component.sass'],
})
export class LevelLabelComponent {
  @Input()
  set levels(levels: Array<LevelNumbers>) {
    if (!levels) {
      this.spanTitle = '';
      this.spanContent = '?';
    } else if (levels.length === 1) {
      this.spanTitle = levels[0].label;
      this.spanContent = this.getLevelTitle(levels[0].number);
    } else {
      const lastIndex = levels.length - 1;
      this.spanTitle = `${levels[0].label} bis ${levels[lastIndex].label}`;
      this.spanContent = `${this.getLevelTitle(
        levels[0].number,
      )}-${this.getLevelTitle(levels[lastIndex].number)}`;
    }
  }

  getLevelTitle(levelNumber: number) {
    return levelNumber === -1 ? $localize`alle` : levelNumber.toString();
  }

  spanTitle: string;
  spanContent: string;
}
