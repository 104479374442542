<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M17.51 34.52C20.8723 34.52 24.1591 33.523 26.9547 31.655C29.7503 29.787 31.9293 27.132 33.216 24.0256C34.5027 20.9193 34.8393 17.5012 34.1834 14.2035C33.5274 10.9058 31.9083 7.8767 29.5308 5.49921C27.1533 3.12172 24.1242 1.50263 20.8265 0.846677C17.5289 0.190729 14.1107 0.527385 11.0044 1.81408C7.89805 3.10076 5.24301 5.2797 3.37503 8.07533C1.50704 10.871 0.51001 14.1577 0.51001 17.52C0.51001 22.0287 2.30108 26.3527 5.48919 29.5408C8.67731 32.729 13.0013 34.52 17.51 34.52Z"
    fill="white"
  />
  <path
    d="M13.4 14.6301H15.16V12.9201C15.0797 12.0028 15.2784 11.0826 15.73 10.2801C16.0411 9.85483 16.4542 9.51468 16.9313 9.29099C17.4084 9.0673 17.9341 8.96727 18.46 9.00011C19.52 8.95275 20.5812 9.06056 21.61 9.32011L21.17 11.9301C20.7111 11.7979 20.2374 11.724 19.76 11.7101C19.07 11.7101 18.46 11.9601 18.46 12.6401V14.6401H21.26L21.07 17.1801H18.46V26.0001H15.16V17.1701H13.4V14.6301Z"
    fill="#0E5C63"
  />
</svg>
