import { Injectable } from '@angular/core';
import { Route, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserActions } from 'src/app/user/user.actions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(private store: Store) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!environment.production) return true;

    return this.store
      .dispatch(new UserActions.FetchCurrentUser())
      .pipe(
        map(
          () =>
            !!this.store.selectSnapshot(state => state.user.user)?.admin_user,
        ),
      );
  }
}
