import { Component } from '@angular/core';

@Component({
  selector: 'app-beginner-videos',
  template: `<app-show-more-page
    type="video"
    subPath="beginner_videos"
    categorySlug="Anfänger"
    headline="Für Anfänger"
    subheadline="Einfache Sequenzen für Yoga-Neulinge"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class BeginnerVideosComponent {}
