import { Component } from '@angular/core';

@Component({
  selector: 'app-short-videos',
  template: `<app-show-more-page
    type="video"
    subPath="search?duration_min=20&duration_max=30"
    categorySlug="Kurz"
    headline="20 - 30 Minuten"
    subheadline="Kurze Klassen für deine tägliche Dosis Yoga"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class ShortVideosComponent {}
