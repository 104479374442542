import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export const isLoggedIn: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const isLoggedIn = !!inject(CookieService).check('secure_user_id');

  if (isLoggedIn) return true;

  return inject(Router).createUrlTree(['user', 'sign_in']);
};
