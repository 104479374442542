import { Component, Input, OnDestroy } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CoreActions } from 'src/app/core/core.actions';
import { BookmarksActions } from '../bookmarks.actions';

@Component({
  selector: 'app-delete-bookmarks-list-modal',
  templateUrl: './delete-bookmarks-list-modal.component.html',
  styleUrls: ['./delete-bookmarks-list-modal.component.sass'],
})
export class DeleteBookmarksListModalComponent implements OnDestroy {
  public static readonly MODAL_ID = 'DeleteBookmarksListModal';
  @Input() listId: number;
  loading = false;
  error = '';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store) {}

  deleteList() {
    this.loading = true;
    this.error = '';
    this.store
      .dispatch(new BookmarksActions.DeleteList(this.listId))
      .pipe(
        takeUntil(this.destroy$),
        catchError(e => {
          this.loading = false;
          this.error = e;
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.store.dispatch([
          new CoreActions.ShowToast({
            text: $localize`Liste gelöscht`,
            icon: 'checkmark',
            color: 'success',
            autohide: true,
          }),
          new BookmarksActions.FetchLists(),
          new CoreActions.CloseAllModals(),
          new CoreActions.SetBottomsheet(null),
          new Navigate(['meine-videos', 'meine-listen']),
        ]);
      });
  }

  keepList() {
    this.store.dispatch([
      new CoreActions.CloseAllModals(),
      new CoreActions.SetBottomsheet(null),
    ]);
  }

  closeModal() {
    this.store.dispatch(
      new CoreActions.CloseModal(DeleteBookmarksListModalComponent.MODAL_ID),
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
