<div class="teaser-slider">
  <div class="headlines">
    <a *ngIf="headlineLink && state !== 'empty'; else noHeadlineLink" [href]="headlineLink" class="headline-link">
      <h3 class="headline">
        {{ headline }}
      </h3>
    </a>
    <ng-template #noHeadlineLink>
      <h3 class="headline">
        {{ headline }}
      </h3>
    </ng-template>
    <div *ngIf="subheadline" class="subheadline">
      {{ subheadline }}
    </div>
  </div>

  <div class="list">
    <ul #slider class="slider is-flex" (scroll)="checkCarets()" (cdkObserveContent)="refresh()">
      <ng-content class="teasers"></ng-content>
    </ul>
    <ng-content *ngIf="state === 'empty'" select="[slot=empty]"></ng-content>
    <div *ngIf="showLeftCaret" [@fadeInOut] class="caret caret-left" (click)="scrollLeft()">
      <svg-arrow-back></svg-arrow-back>
    </div>
    <div *ngIf="showRightCaret" [@fadeInOut] class="caret caret-right" (click)="scrollRight()">
      <svg-arrow></svg-arrow>
    </div>
  </div>
</div>