<div class="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center">
  <img ngSrc="production/uploads/components/recommended-by/grazia.png" alt="Grazia Logo" width="107" height="34" class="mr-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/vital.png" alt="Vital Logo" width="77" height="34" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/jolie.png" alt="Jolie Logo" width="73" height="46" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/petra.png" alt="Petra Logo" width="109" height="48" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/flow.png" alt="Flow Logo" width="88" height="33" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/emotion.png" alt="Emotion Logo" width="147" height="33" class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/yoga-journal.png" alt="Yoga-Journal Logo" width="208" height="50"
    class="mx-4 my-3" />
  <img ngSrc="production/uploads/components/recommended-by/fuer-sie.png" alt="Für Sie Logo" width="123" height="29" class="ml-4 my-3" />
</div>