import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { ArrowSvg } from 'src/app/svg/arrow/arrow.svg';
import { UserActions } from 'src/app/user/user.actions';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [CommonModule, ArrowSvg],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.sass'],
})
export class UserMenuComponent {
  constructor(
    private store: Store,
    private window: Window,
  ) {}

  signOut() {
    this.store
      .dispatch(new UserActions.SignOut())
      .pipe(take(1))
      .subscribe(() => (this.window.location.href = '/'));
  }
}
