import { Images } from './images';
import { LevelNumbers } from './level-numbers';

export class VideoTeaser {
  id: number;
  bookmarks_lists: number[];
  slug: string;
  images: Images;
  title: string;
  teacher: string;
  published_at: string;
  levels: Array<LevelNumbers>;
  rating_count: number;
  rating: 0.0 | 0.5 | 1.0 | 1.5 | 2.0 | 2.5 | 3.0 | 3.5 | 4.0 | 4.5 | 5.0;
  comment_count: number;
  style: string;
  effort: 0 | 1 | 2 | 3 | 4;
  path: string;
  duration_in_words: string;
  short_description: string;
  is_new: boolean;
  image_alt: string;
  is_favorite: boolean;
  last_touch_time?: string;

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  constructor(jsonResponse: any) {
    Object.assign(this, jsonResponse);
  }
  getTeaserTitle(): string {
    return this.title;
  }
  getTeaserImage(): string {
    return this.images.i370_208;
  }
  getTeaserUrl(): string {
    return `/videos/${this.slug}`;
  }
  getTeaserHeadline(): string {
    return this.title;
  }
  getTeaserHeadlineUrl(): string {
    return 'HeadlineUrl';
  }
  getTeaserDescription(): string {
    return this.title;
  }
  getClassName(): string {
    return 'video-teaser';
  }
}
