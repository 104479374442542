import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';
import { CoreActions } from 'src/app/core/core.actions';
import { BookmarksActions } from '../bookmarks.actions';

@Component({
  selector: 'app-create-bookmarks-list-modal',
  templateUrl: './create-bookmarks-list-modal.component.html',
  styleUrls: ['./create-bookmarks-list-modal.component.sass'],
})
export class CreateBookmarksListModalComponent implements OnDestroy {
  public static readonly MODAL_ID = 'CreateBookmarksListModal';

  @Input() videoId?: number;

  // TODO: put these in the state like itemListsResponse
  error = '';
  loading = false;
  name = '';
  disabled = true;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  submit() {
    this.loading = true;
    this.error = '';
    this.store
      .dispatch(new BookmarksActions.CreateList(this.name.trim()))
      .pipe(
        takeUntil(this.destroy$),
        catchError(e => {
          this.loading = false;
          this.error = e;
          this.disabled = true;
          return EMPTY;
        }),
      )
      .subscribe(async () => {
        if (this.videoId) {
          const createdList: BookmarksList = this.store.selectSnapshot(
            state => state.bookmarks.createdList,
          );
          await this.store
            .dispatch(
              new BookmarksActions.AddItemToList(this.videoId, createdList.id),
            )
            .toPromise();
          await this.store
            .dispatch(new BookmarksActions.RefetchItemLists(this.videoId))
            .toPromise();
        }

        this.store.dispatch([
          new CoreActions.ShowToast({
            text: $localize`Liste wurde erfolgreich erstellt`,
            icon: 'heart',
            color: 'success',
            autohide: true,
          }),
          new BookmarksActions.FetchLists(),
        ]);
        this.closeModal();
      });
  }

  closeModal() {
    this.store.dispatch(
      new CoreActions.CloseModal(CreateBookmarksListModalComponent.MODAL_ID),
    );
  }

  setButtonState() {
    if (this.name.trim()) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    // not sure why we have to trigger changeDetection here, but otherwise it won't work
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
