import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CoreActions } from 'src/app/core/core.actions';
import { BookmarksActions } from '../bookmarks.actions';

@Component({
  selector: 'app-rename-bookmarks-list-modal',
  templateUrl: './rename-bookmarks-list-modal.component.html',
  styleUrls: ['./rename-bookmarks-list-modal.component.sass'],
})
export class RenameBookmarksListModalComponent implements OnInit, OnDestroy {
  public static readonly MODAL_ID = 'RenameBookmarksListModal';
  @Input() listId: number;
  error = '';
  loading = false;
  newName = '';
  currentName: string;
  disabled = true;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.currentName = this.store.selectSnapshot(
      state => state.bookmarks.bookmarksList,
    )?.title;
    this.newName = this.currentName || '';
  }
  submit() {
    this.loading = true;
    this.error = '';
    this.store
      .dispatch(
        new BookmarksActions.RenameList(this.listId, this.newName.trim()),
      )
      .pipe(
        takeUntil(this.destroy$),
        catchError(e => {
          this.loading = false;
          this.error = e;
          this.disabled = true;
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.store.dispatch([
          new CoreActions.ShowToast({
            text: $localize`Neuer Name übernommen`,
            icon: 'heart',
            color: 'success',
            autohide: true,
          }),
          new BookmarksActions.FetchList(this.listId),
          new BookmarksActions.FetchLists(),
          new CoreActions.SetBottomsheet(null),
          new CoreActions.CloseAllModals(),
        ]);
      });
  }

  closeModal() {
    this.store.dispatch(
      new CoreActions.CloseModal(RenameBookmarksListModalComponent.MODAL_ID),
    );
  }

  setButtonState() {
    if (this.newName.trim() && this.currentName !== this.newName) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    // not sure why we have to trigger changeDetection here, but otherwise it won't work
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
