<div
  class="has-background-f3eee9 is-flex is-flex-direction-column is-align-items-center is-position-relative py-70px px-4-mobile px-7-tablet-only px-4-desktop has-border-radius-3-desktop"
  [ngStyle]="{ 'min-height.px': minHeight }"
>
  <div class="is-position-absolute is-top-24px is-centered-horizontally">
    <svg-star></svg-star>
    <svg-star></svg-star>
    <svg-star></svg-star>
    <svg-star></svg-star>
    <svg-star></svg-star>
  </div>
  <div class="is-position-absolute is-top-68px is-left-100px-mobile is-left-250px-tablet-only is-left-100px-desktop">
    <svg-quote></svg-quote>
  </div>
  <div class="z-index-1">
    <ng-content></ng-content>
  </div>
</div>
