import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Unit } from '../models/unit';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(private httpClient: HttpClient) {}
  servicePath = environment.apiPath + '/v1/units/';
  getUnit(id: string): Observable<Unit> {
    return this.httpClient.get<Unit>(this.servicePath + id).pipe(catchError(this.handleError));
  }
  handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      errorMessage = 'Error occurred ' + err.error.message;
    } else {
      errorMessage = 'System Error occurred ' + err.error.message;
    }
    return throwError(errorMessage);
  }
}
