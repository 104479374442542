import { Component } from '@angular/core';

@Component({
  selector: 'app-neck-shoulder-videos',
  template: `<app-show-more-page
    type="video"
    subPath="neck_and_shoulder_videos"
    categorySlug="NeckShoulders"
    headline="Classes for neck & shoulders"
    subheadline="Tease out tension with these targeted practices"
  ></app-show-more-page>`,
})
export class NeckShoulderVideosComponent {}
