import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable()
export class CurrentPathInterceptor implements HttpInterceptor {
  @Select(state => state.core.redirected)
  redirected$: Observable<boolean>;

  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    let redirected = this.store.selectSnapshot(state => state.core.redirected);
    let modifiedReq = request;
    if (!redirected) {
      modifiedReq = request.clone({
        headers: request.headers.set('current-path', this.getCurrentPath()),
      });
    }
    return next.handle(modifiedReq);
  }

  getCurrentPath(): string {
    return document.location.href;
  }
}
