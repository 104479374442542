import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ausgezeichnet-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ausgezeichnet-bar.component.html',
  styleUrls: ['./ausgezeichnet-bar.component.sass'],
})
export class AusgezeichnetBarComponent {}
