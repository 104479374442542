import { Component } from '@angular/core';

@Component({
  selector: 'app-popular-programs',
  template: `<app-show-more-page
    type="program"
    subPath="popular_videos"
    headline="Die beliebtesten Programme"
    subheadline="Erreiche mit unseren Yoga-Programmen und -Kursen deine Ziele"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class PopularProgramsComponent {}
