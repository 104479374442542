<div class="padded-on-small centered">
  <div class="xlarge-headline">
    <div class="404-header" i18n>Einatmen.</div>
    <div class="404-header" i18n>Ausatmen.</div>
  </div>
  <div id="explanation" class="is-flex is-flex-wrap-wrap is-inline-block centered padding-10-mobile">
    <div class="part-one" i18n>404 - Seite nicht gefunden.</div>
    <div class="part-two" i18n>Leider können wir die gesuchte Seite nicht finden.</div>
  </div>
</div>
<div class="centered">
  <img id="img-404" class="ls-is-cached lazyloaded" ngSrc="production/uploads/components/not-found-page/404.gif" width="324" height="282"
    priority alt="404" />
</div>
<div class="padded-on-small mb-6 centered">
    <a class="button is-primary has-text-weight-semibold has-border-radius-6 px-6" routerLink="/" href="/" i18n>Zurück zur Startseite</a>
</div>