import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CoreActions } from 'src/app/core/core.actions';
import { Utility } from 'src/app/shared/utility';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.sass'],
})
export class DiscoverComponent implements OnInit, OnDestroy {
  @ViewChild('filterComponent', { read: ViewContainerRef })
  filterComponent!: ViewContainerRef;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public childPage: 'curated' | 'filtered';

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(
      new CoreActions.AddMetaTags([{ name: 'robots', content: 'noindex' }]),
    );
    this.store
      .select(state => state.router.state)
      .pipe(
        takeUntil(this.destroy$),
        map(state => state?.queryParams),
        distinctUntilChanged(),
      )
      .subscribe(queryParams => {
        if (Utility.containsFilterOptionKeys(queryParams)) {
          this.childPage = 'filtered';
        } else {
          this.childPage = 'curated';
        }
      });
    this.loadFilter();
  }

  async loadFilter() {
    const { FilterComponent } = await import(
      '../../../filter/filter.component'
    );
    this.filterComponent.clear();
    this.filterComponent.createComponent(FilterComponent);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
