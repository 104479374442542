import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { CoreActions } from 'src/app/core/core.actions';
import { BookmarksActions } from '../../bookmarks.actions';
import { BookmarksListChecked } from '../bookmarks-menu.component';

@Component({
  selector: 'app-bookmarks-menu-list-item',
  templateUrl: './bookmarks-menu-list-item.component.html',
  styleUrls: ['./bookmarks-menu-list-item.component.sass'],
})
export class BookmarksMenuListItemComponent {
  @Input() videoRef: VideoTeaser;
  @Input() listChecked: BookmarksListChecked;

  constructor(private store: Store) {}

  toggleList() {
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let action;
    const currentList = this.videoRef.bookmarks_lists;
    this.listChecked.checked = !this.listChecked.checked;
    if (this.listChecked.checked) {
      action = new BookmarksActions.AddItemToList(
        this.videoRef.id,
        this.listChecked.id,
      );
      this.videoRef.bookmarks_lists = this.videoRef.bookmarks_lists.concat([
        this.listChecked.id,
      ]);
    } else {
      action = new BookmarksActions.RemoveItemFromList(
        this.videoRef.id,
        this.listChecked.id,
      );
      this.videoRef.bookmarks_lists = this.videoRef.bookmarks_lists.filter(
        bookmarksId => bookmarksId !== this.listChecked.id,
      );
    }
    this.store.dispatch(action).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.refetchLists();
      },
      error: () => {
        this.showErrorMessage();
        this.refetchLists();
        this.videoRef.bookmarks_lists = currentList;
      },
    });
  }

  showSuccessMessage() {
    this.store.dispatch(
      new CoreActions.ShowToast({
        text: $localize`Änderungen wurden gespeichert`,
        icon: 'checkmark',
        color: 'success',
        autohide: true,
      }),
    );
  }

  showErrorMessage() {
    this.store.dispatch(
      new CoreActions.ShowToast({
        text: $localize`Speichern fehlgeschlagen.<br>Versuche es später noch einmal.`,
        icon: 'warning',
        color: 'error',
        autohide: true,
      }),
    );
  }

  refetchLists() {
    this.store.dispatch([
      new BookmarksActions.RefetchItemLists(this.videoRef.id),
      new BookmarksActions.FetchLists(),
    ]);

    const pageListId = this.store.selectSnapshot(
      state => state.bookmarks.bookmarksList,
    )?.id;
    if (pageListId === this.listChecked.id) {
      this.store.dispatch(new BookmarksActions.FetchList(pageListId));
    }
  }
}
