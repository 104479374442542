<div class="rename-bookmarks-list-modal">
  <input type="text" style="display: none" />
  <!-- ↑ to avoid autofocus on other elements -->
  <div class="header">
    <svg-exit class="close-icon" (click)="closeModal()"></svg-exit>
  </div>
  <h4 class="headline" i18n>Liste löschen?</h4>
  <div class="text" i18n>
    Alle Videos dieser Liste, die auch in deiner Favoriten-Liste enthalten sind, kannst du dort weiterhin wiederfinden.
  </div>

  <div class="error" *ngIf="error">{{ error }}</div>
  <app-loading-circle *ngIf="loading" class="loading-bar"></app-loading-circle>
  <app-button *ngIf="!loading" type="primary" color="error" class="delete-button" (onClick)="deleteList()" i18n>Liste löschen</app-button>
  <app-button *ngIf="!loading" type="secondary" class="keep-button" (onClick)="keepList()" i18n>Liste behalten</app-button>
</div>