import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'svg-app-pictogram',
  templateUrl: './app-pictogram.svg.html',
})
export class AppPictogramSvg {
  @Input() color: string;
}
