import { CommonModule, IMAGE_LOADER, NgOptimizedImage } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { BookmarksMenuComponent } from 'src/app/bookmarks/bookmarks-menu/bookmarks-menu.component';
import { CoreActions } from 'src/app/core/core.actions';
import { imageKitLoaderWithFallback } from 'src/app/core/image-kit-loader-with-fallback/image-kit-loader-with-fallback';
import { ClockSvg } from 'src/app/svg/clock/clock.svg';
import { FavoritPlusSvg } from 'src/app/svg/favorit-plus/favorit-plus.svg';
import { HeartFilledSvg } from 'src/app/svg/heart-filled/heart-filled.svg';
import { MoveSvg } from 'src/app/svg/move/move.svg';
import { EffortBarsComponent } from '../effort-bars/effort-bars.component';
import { LevelLabelComponent } from '../level-label/level-label.component';
import { Utility } from '../utility';

@Component({
  selector: 'app-video-teaser',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    EffortBarsComponent,
    LevelLabelComponent,
    HeartFilledSvg,
    FavoritPlusSvg,
    ClockSvg,
    MoveSvg,
  ],
  providers: [
    {
      provide: IMAGE_LOADER,
      useValue: imageKitLoaderWithFallback,
    },
  ],
  templateUrl: './video-teaser.component.html',
  styleUrls: ['./video-teaser.component.sass'],
})
export class VideoTeaserComponent implements OnInit {
  @Input()
  set teaser(teaser: VideoTeaser) {
    // make a copy because teaser is not writable
    this.teaserCopy = Object.assign({}, teaser);
    // FIXME: we should use the original image without BE transformations here, but BE work is needed for it (NAMA-2733)
    this.imagePath = Utility.getRelativePath(
      teaser?.images?.big?.replace('/big_', '/'),
    );
  }
  get teaser(): VideoTeaser {
    return this.teaserCopy;
  }
  private teaserCopy: VideoTeaser;
  @Input() breadcrumb?: Array<string>;
  pathWithBreadcrumb: string;
  @Input() size: 'small' | 'smaller' | 'default' = 'default';
  @Input() draggable = false;
  public imagePath: string;

  constructor(
    protected store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit() {
    this.pathWithBreadcrumb = this.teaserPathWithBreadcrumb(this.teaser?.path);
  }

  teaserPathWithBreadcrumb(path) {
    if (this.breadcrumb) {
      path += `?breadcrumb=${this.breadcrumb.join(',')}`;
    }
    return path;
  }

  openBookmarksMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.store.selectSnapshot(state => state.core.viewport) === 'mobile') {
      this.store.dispatch(
        new CoreActions.SetBottomsheet(BookmarksMenuComponent, false, {
          videoRef: this.teaser,
        }),
      );
    } else {
      this.store.dispatch(
        new CoreActions.OpenModal(
          BookmarksMenuComponent.MODAL_ID,
          BookmarksMenuComponent,
          {},
          { videoRef: this.teaser },
        ),
      );
    }
  }
}
