import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonComponent } from '../shared/button/button.component';
import { CardComponent } from '../shared/card/card.component';
import { ClickableChipComponent } from '../shared/clickable-chip/clickable-chip.component';
import { LoadingCircleComponent } from '../shared/loading-circle/loading-circle.component';
import { VideoTeaserComponent } from '../shared/video-teaser/video-teaser.component';
import { YeLoadingSpinnerComponent } from '../shared/ye-loading-spinner/ye-loading-spinner.component';
import { ArrowBackSvg } from '../svg/arrow-back/arrow-back.svg';
import { ArrowDownSvg } from '../svg/arrow-down/arrow-down.svg';
import { ArrowSvg } from '../svg/arrow/arrow.svg';
import { AsanaDancerPictogramSvg } from '../svg/asana-dancer-pictogram/asana-dancer-pictogram.svg';
import { AsanaWarriorPictogramSvg } from '../svg/asana-warrior-pictogram/asana-warrior-pictogram.svg';
import { CheckmarkSvg } from '../svg/checkmark/checkmark.svg';
import { DurationMobileFilterHandleSvg } from '../svg/duration-mobile-filter-handle/duration-mobile-filter-handle.svg';
import { EffortBarsLargeSvg } from '../svg/effort-bars-large/effort-bars-large.svg';
import { ExitSvg } from '../svg/exit/exit.svg';
import { FocusPictogramSvg } from '../svg/focus-pictogram/focus-pictogram.svg';
import { FunnelSvg } from '../svg/funnel/funnel.svg';
import { HeartBeatPictogramSvg } from '../svg/heart-beat-pictogram/heart-beat-pictogram.svg';
import { MobileFilterWaveSvg } from '../svg/mobile-filter-wave/mobile-filter-wave.svg';
import { NamastePictogramSvg } from '../svg/namaste-pictogram/namaste-pictogram.svg';
import { SearchSvg } from '../svg/search/search.svg';
import { VideoDurationPictogramSvg } from '../svg/video-duration-pictogram/video-duration-pictogram.svg';
import { YogaGroupPictogramSvg } from '../svg/yoga-group-pictogram/yoga-group-pictogram.svg';
import { YogamatPictogramSvg } from '../svg/yogamat-pictogram/yogamat-pictogram.svg';
import { DesktopFilterButtonComponent } from './desktop-filter-button/desktop-filter-button.component';
import { DesktopFilterComponent } from './desktop-filter/desktop-filter.component';
import { DisabledFilterButtonComponent } from './disabled-filter-button/disabled-filter-button.component';
import { DurationFilterComponent } from './duration-filter/duration-filter.component';
import { EffortFilterButtonComponent } from './effort-filter/effort-filter-button/effort-filter-button.component';
import { EffortFilterComponent } from './effort-filter/effort-filter.component';
import { FilterApplyButtonComponent } from './filter-apply-button/filter-apply-button.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FilterFooterComponent } from './filter-footer/filter-footer.component';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { FilterOptionButtonComponent } from './filter-option-button/filter-option-button.component';
import { FilterOptionDividerComponent } from './filter-option-divider/filter-option-divider.component';
import { FilterStickyHeaderComponent } from './filter-sticky-header/filter-sticky-header.component';
import { FilterTooltipComponent } from './filter-tooltip/filter-tooltip.component';
import { FilterComponent } from './filter.component';
import { FilterState } from './filter.state';
import { FilteredListComponent } from './filtered-list/filtered-list.component';
import { SortByButtonComponent } from './filtered-list/sort-by-button/sort-by-button.component';
import { SortByComponent } from './filtered-list/sort-by/sort-by.component';
import { LevelFilterComponent } from './level-filter/level-filter.component';
import { MobileFilterButtonComponent } from './mobile-filter-button/mobile-filter-button.component';
import { MobileFilterListComponent } from './mobile-filter-list/mobile-filter-list.component';
import { MobileFilterOverlayComponent } from './mobile-filter-overlay/mobile-filter-overlay.component';
import { MobileFilterStaticButtonComponent } from './mobile-filter-static-button/mobile-filter-static-button.component';
import { MobileFilterComponent } from './mobile-filter/mobile-filter.component';
import { MoreOptionsButtonComponent } from './more-options-button/more-options-button.component';
import { MoreOptionsFilterComponent } from './more-options-filter/more-options-filter.component';
import { ResetAllButtonComponent } from './reset-all-button/reset-all-button.component';
import { ResetButtonComponent } from './reset-button/reset-button.component';
import { StyleFilterComponent } from './style-filter/style-filter.component';
import { TeacherButtonComponent } from './teacher-filter/teacher-button/teacher-button.component';
import { TeacherFilterComponent } from './teacher-filter/teacher-filter.component';

@NgModule({
  declarations: [
    DesktopFilterComponent,
    DisabledFilterButtonComponent,
    DurationFilterComponent,
    EffortFilterComponent,
    FilterApplyButtonComponent,
    FilterButtonComponent,
    FilterComponent,
    FilterFooterComponent,
    FilterHeaderComponent,
    FilterModalComponent,
    FilterOptionButtonComponent,
    FilterOptionDividerComponent,
    FilterStickyHeaderComponent,
    FilterTooltipComponent,
    FilteredListComponent,
    LevelFilterComponent,
    MobileFilterComponent,
    MobileFilterListComponent,
    MobileFilterOverlayComponent,
    MoreOptionsButtonComponent,
    MoreOptionsFilterComponent,
    ResetAllButtonComponent,
    ResetButtonComponent,
    SortByButtonComponent,
    SortByComponent,
    StyleFilterComponent,
    TeacherButtonComponent,
    TeacherFilterComponent,
    MobileFilterButtonComponent,
    MobileFilterStaticButtonComponent,
    EffortFilterButtonComponent,
    MoreOptionsFilterComponent,
    DesktopFilterButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([FilterState]),
    NgbTooltipModule,
    NgbModalModule,
    ClickableChipComponent,
    ButtonComponent,
    YeLoadingSpinnerComponent,
    VideoTeaserComponent,
    InfiniteScrollModule,
    LoadingCircleComponent,
    SearchSvg,
    NamastePictogramSvg,
    YogaGroupPictogramSvg,
    ExitSvg,
    CheckmarkSvg,
    AsanaWarriorPictogramSvg,
    YogamatPictogramSvg,
    FunnelSvg,
    AsanaDancerPictogramSvg,
    FocusPictogramSvg,
    ArrowSvg,
    ArrowDownSvg,
    ArrowBackSvg,
    MobileFilterWaveSvg,
    EffortBarsLargeSvg,
    HeartBeatPictogramSvg,
    DurationMobileFilterHandleSvg,
    VideoDurationPictogramSvg,
    CardComponent,
  ],
  exports: [FilterComponent, FilteredListComponent],
})
export class FilterModule {}
