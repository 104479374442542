import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CoreActions } from '../core.actions';

@Injectable({
  providedIn: 'root',
})
export class SeoTagService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store, @Inject(DOCUMENT) private document: Document) {}

  startSeoService() {
    this.listenToSeoDataChange();
    this.listenToQueryParamsChange();
  }

  private listenToSeoDataChange() {
    this.store
      .select(state => state.core.seoData)
      .pipe(
        takeUntil(this.destroy$),
        filter(seoData => !!seoData),
      )
      .subscribe(seoData => {
        if (seoData.title) {
          this.store.dispatch(new CoreActions.SetTitle(seoData.title));
        }

        if (seoData.canonical) {
          this.setCanonical(seoData.canonical);
        }

        this.store.dispatch(
          new CoreActions.AddMetaTags(
            Object.keys(seoData)
              .filter(key => key !== 'title')
              .filter(key => key !== 'canonical')
              .filter(key => !!seoData[key])
              .map(key => ({ name: key, content: seoData[key] })),
          ),
        );
      });
  }

  private setCanonical(href: string) {
    let link: HTMLLinkElement = this.document.querySelector('link[rel="canonical"]');
    if (!link) {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
    }
    link.setAttribute('href', href);
  }

  private listenToQueryParamsChange() {
    this.store
      .select(state => state.router.state)
      .pipe(takeUntil(this.destroy$))
      .subscribe(routerState => {
        const queryParamsPresent = !!(routerState && routerState.queryParams && Object.keys(routerState.queryParams).length);

        this.store.dispatch(new CoreActions.SetNoindex(queryParamsPresent));
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
