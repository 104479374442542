import { Component } from '@angular/core';

@Component({
  selector: 'app-evening-videos',
  template: `<app-show-more-page
    type="video"
    subPath="evening_videos"
    categorySlug="Abends"
    headline="Für abends"
    subheadline="Lass den Tag hinter dir!"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class EveningVideosComponent {}
