import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-redirect-to',
  templateUrl: './redirect-to.component.html',
  styleUrls: ['./redirect-to.component.sass'],
})
export class RedirectToComponent implements OnInit {
  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.store
      .select(state => state.router.state.queryParams.to)
      .subscribe(to => {
        if (!to.startsWith('http')) {
          const protocol = this.document.location.protocol || 'https:';
          const host = this.document.location.host || 'www.yogaeasy.de';
          to = `${protocol}//${host}${to}`;
        }
        this.document.location.href = to;
      });
    // no cleanup necessary since the application will be destroyed when routing like this
  }
}
