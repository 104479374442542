import { ArticleTeaser } from './article-teaser';
import { Images } from './images';
import { Level } from './level';
import { TeacherTeaser } from './teacher-teaser';
import { VideoPlayerCta } from './video-player-cta';
import { VideoRatings } from './video-ratings';
import { VideoTeaser } from './video-teaser';
import { VideoVersion } from './video-version';

export class Video {
  accessories: string;
  bookmarks_lists: number[];
  cta: VideoPlayerCta;
  comment_count: number;
  dataid: string;
  description: string;
  duration_in_words: string;
  effort: string;
  file_id: number;
  id: number;
  image_alt: string;
  images: Images;
  is_favorite: boolean;
  is_new: boolean;
  key: string;
  levels: Level[];
  path: string;
  primary_teachers: TeacherTeaser[];
  published_at: string;
  recommended_articles: ArticleTeaser[];
  recommended_videos: VideoTeaser[];
  redirect_status?: string;
  redirected_page?: string;
  short_description: string;
  slug: string;
  style: string;
  support_teachers: TeacherTeaser[];
  tags: string[];
  title: string;
  video_ratings: VideoRatings;
  video_stars?: number;
  video_versions: VideoVersion[];
  user_rating?: number;
  video_still_url: string;

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  constructor(jsonResponse: any) {
    Object.assign(this, jsonResponse);
  }

  getClassName(): string {
    return 'video';
  }

  getSlug(): string {
    return this.slug;
  }

  getTeaserTitle(): string {
    return this.title;
  }

  getTeaserImage(): string {
    return this.images.thumb;
  }

  getTeaserUrl(): string {
    return `/videos/${this.slug}`;
  }

  getTeaserHeadline() {
    return this.title;
  }

  getTeaserHeadlineUrl() {
    return this.getTeaserUrl();
  }

  getTeaserDescription() {
    return this.title;
  }

  getArticleRecommendations(): ArticleTeaser[] {
    return this.recommended_articles.map(res => new ArticleTeaser(res));
  }

  getVideoRecommendations(): VideoTeaser[] {
    return this.recommended_videos.map(res => new VideoTeaser(res));
  }
}
