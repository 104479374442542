import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

const NOINDEX = { name: 'robots', content: 'noindex' };

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private existingTagNames: Array<string> = [];
  private noindexForced = false;

  constructor(private meta: Meta) {}

  addTags(tags: MetaDefinition[]) {
    tags.forEach(tag => {
      if (this.noindexForced && tag.name === 'robots') return;

      if (this.existingTagNames.includes(tag.name)) {
        this.meta.updateTag(tag);
      } else {
        this.meta.addTag(tag);
        this.existingTagNames.push(tag.name);
      }
    });
  }

  removeTags(tagNames: Array<string>) {
    tagNames.forEach(tagName => {
      if (this.noindexForced && tagName === 'robots') return;

      this.meta.removeTag(`name=${tagName}`);
      this.existingTagNames = this.existingTagNames.filter(existingTagName => tagName !== existingTagName);
    });
  }

  setNoindex(noIndex: boolean) {
    if (noIndex) {
      this.addTags([NOINDEX]);
      this.noindexForced = true;
    } else {
      this.noindexForced = false;
      this.removeTags([NOINDEX.name]);
    }
  }
}
