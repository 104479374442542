import { Component } from '@angular/core';

@Component({
  selector: 'app-stress-videos',
  template: `<app-show-more-page
    type="video"
    subPath="stress_videos"
    categorySlug="Entspannen"
    headline="Zum Entspannen"
    subheadline="Schluss mit Stress – gönn dir eine Auszeit!"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class StressVideosComponent {}
