<a href="homepage/videos/meine-favoriten" class="is-block bookmarks-list-favorite-teaser">
  <svg class="background" width="156" height="149" viewBox="0 0 156 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4.09744" cy="8.31399" r="3.21951" fill="#F1A293" />
    <circle cx="19.6583" cy="64.6554" r="5.90244" fill="#FFCE35" />
    <circle cx="40.0485" cy="33.5319" r="4.82927" fill="#9DCA83" />
    <circle cx="113.024" cy="47.4849" r="3.7561" fill="#69B8A4" />
    <circle cx="150.048" cy="45.8744" r="5.36585" fill="#FFCE35" />
    <circle cx="147.902" cy="4.02103" r="3.21951" fill="#EA5E3D" />
    <circle cx="98.5365" cy="20.1195" r="2.14634" fill="#6EA1A5" />
    <circle cx="142.536" cy="146.754" r="2.14634" fill="#6EA1A5" />
    <circle cx="131.805" cy="22.2654" r="3.21951" fill="#F1A293" />
    <circle cx="116.243" cy="92.5572" r="2.68293" fill="#EA5E3D" />
  </svg>
  <svg-heart-filled class="heart"></svg-heart-filled>
  <div class="text-block is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
    <h4 class="teaser-title" i18n>Meine Favoriten</h4>
    <span class="count" *ngIf="itemsCount >= 0">
      @if (itemsCount === 1) {
      <ng-container i18n>1 Video</ng-container>
      } @else {
      <ng-container i18n>{{ itemsCount }} Videos</ng-container>
      }
    </span>
  </div>
</a>