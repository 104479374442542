import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-ye-loading-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ye-loading-spinner.component.html',
  styleUrls: ['./ye-loading-spinner.component.sass'],
})
export class YeLoadingSpinnerComponent {}
