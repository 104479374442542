import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-effort-bars',
  standalone: true,
  imports:  [CommonModule],
  templateUrl: './effort-bars.component.html',
  styleUrls: ['./effort-bars.component.sass'],
})
export class EffortBarsComponent {
  @Input() effort: -1 | 0 | 1 | 2 | 3 | 4 | 5;
  @Input() doubleWidth: boolean = false;
}
