import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CoreActions } from '../core.actions';

@Injectable()
export class RequestCountInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new CoreActions.IncreaseRequestCount());

    return next.handle(request).pipe(finalize(() => this.store.dispatch(new CoreActions.DecreaseRequestCount())));
  }
}
