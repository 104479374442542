import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../api/models/user';
import { UserProfileForm } from '../api/models/user-profile-form';
import { UserService } from '../api/services/user.service';
import { VideoService } from '../api/services/video.service';
import { UserActions } from './user.actions';

export interface UserStateModel {
  error: string;
  message: string;
  user: User;
  profileForm: UserProfileForm;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    error: undefined,
    message: undefined,
    user: undefined,
    profileForm: undefined,
  },
})
@Injectable()
export class UserState {
  constructor(
    private userService: UserService,
    private videoService: VideoService,
  ) {}

  @Action(UserActions.ClearError)
  clearError(ctx: StateContext<UserStateModel>, _) {
    ctx.patchState({ error: undefined });
  }

  @Action(UserActions.ClearMessage)
  clearMessage(ctx: StateContext<UserStateModel>, _) {
    ctx.patchState({ message: undefined });
  }

  @Action(UserActions.FetchCurrentUser)
  fetchCurrentUser(ctx: StateContext<UserStateModel>, _) {
    return this.userService.getCurrentUser().pipe(
      tap((user) => ctx.patchState({ user })),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  @Action(UserActions.FetchProfileForm)
  fetchProfileForm(ctx: StateContext<UserStateModel>, _) {
    return this.userService.getProfileForm().pipe(
      tap((profileForm) => ctx.patchState({ profileForm })),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  @Action(UserActions.RequestNewPassword)
  requestNewPassword(
    ctx: StateContext<UserStateModel>,
    action: UserActions.RequestNewPassword,
  ) {
    return this.userService.requestNewPassword(action.email).pipe(
      tap({
        next: (res) => ctx.patchState({ message: res.message }),
        error: (err) => ctx.patchState({ error: err.error.error }),
      }),
    );
  }

  @Action(UserActions.SetNewPassword)
  setNewPassword(
    ctx: StateContext<UserStateModel>,
    action: UserActions.SetNewPassword,
  ) {
    return this.userService.setNewPassword(action.password, action.token).pipe(
      tap({
        next: (res) => ctx.patchState({ message: res.message }),
        error: (err) => ctx.patchState({ error: err.error.error }),
      }),
    );
  }

  @Action(UserActions.SetVideoFavorite)
  setVideoFavorite(
    ctx: StateContext<UserStateModel>,
    action: UserActions.SetVideoFavorite,
  ) {
    return this.videoService.setVideoFavorite(
      action.videoId,
      action.isFavorite,
    ); // TODO: Add error handling
  }

  @Action(UserActions.SignIn)
  signIn(ctx: StateContext<UserStateModel>, action: UserActions.SignIn) {
    return this.userService
      .signIn(action.email, action.password, action.rememberMe)
      .pipe(
        tap({
          next: (user) => ctx.patchState({ user }),
          error: (err) => ctx.patchState({ error: err.error.error }),
        }),
      );
  }

  @Action(UserActions.SignOut)
  signOut(ctx: StateContext<UserStateModel>, _) {
    return this.userService.signOut().pipe(
      tap({
        next: () => ctx.patchState({ user: null }),
        error: (err) => ctx.patchState({ error: err.error.error }),
      }),
    );
  }

  @Action(UserActions.SignUp)
  signUp(ctx: StateContext<UserStateModel>, action: UserActions.SignUp) {
    this.clearError(ctx, null);
    return this.userService
      .signUp(
        null,
        null,
        action.email,
        action.password,
        null,
        true,
        action.wantsNewsletter,
      )
      .pipe(
        tap({
          next: (user) => ctx.patchState({ user }),
          error: (err) => ctx.patchState({ error: err.error.error }),
        }),
      );
  }

  @Action(UserActions.UpdateFirstName)
  updateFirstName(
    ctx: StateContext<UserStateModel>,
    action: UserActions.UpdateFirstName,
  ) {
    return this.userService.updateFirstName(action.firstName).pipe(
      tap({
        next: (res) => ctx.patchState({ user: res.user }),
        error: (err) => ctx.patchState({ error: err.error.error }),
      }),
    );
  }

  @Action(UserActions.UpdateProfileForm)
  updateProfileForm(
    ctx: StateContext<UserStateModel>,
    action: UserActions.UpdateProfileForm,
  ) {
    return this.userService.updateProfileForm(action.profileForm).pipe(
      tap({
        next: (res) =>
          ctx.patchState({ profileForm: undefined, message: res.message }),
        error: (err) => ctx.patchState({ error: err.error.error }),
      }),
    );
  }

  @Action(UserActions.VideoWatched)
  watchedVideo(_, action: UserActions.VideoWatched) {
    return this.videoService.watchedVideo(action.params);
  }
}
