<!-- max-width here because otherwise this icon in the navbar could be rendered gigantic until CSS is loaded -->
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="max-width: 3rem">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.07141 6.42857C5.07141 2.87817 7.94958 0 11.5 0C15.0504 0 17.9286 2.87817 17.9286 6.42857C17.9286 9.97897 15.0504 12.8571 11.5 12.8571C7.94958 12.8571 5.07141 9.97897 5.07141 6.42857ZM16.3214 6.42857C16.3214 3.76577 14.1628 1.60714 11.5 1.60714C8.8372 1.60714 6.67857 3.76577 6.67857 6.42857C6.67857 9.09137 8.8372 11.25 11.5 11.25C14.1628 11.25 16.3214 9.09137 16.3214 6.42857Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.5 15.75C7.77208 15.75 4.75 18.7721 4.75 22.5C4.75 22.9142 4.41421 23.25 4 23.25C3.58579 23.25 3.25 22.9142 3.25 22.5C3.25 17.9437 6.94365 14.25 11.5 14.25C16.0563 14.25 19.75 17.9437 19.75 22.5C19.75 22.9142 19.4142 23.25 19 23.25C18.5858 23.25 18.25 22.9142 18.25 22.5C18.25 18.7721 15.2279 15.75 11.5 15.75Z"
    fill="currentColor"
  />
</svg>
