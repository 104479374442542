import { Injectable } from '@angular/core';
import {
  DefaultUrlSerializer,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreActions } from '../core.actions';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  constructor(private store: Store) {
    super();
  }

  parse(url: string): UrlTree {
    const urlTree = super.parse(url);

    // Workaround for newsletter (BODHI-1628)
    if (url.includes('liste')) return urlTree;

    const urlChanged = this.lowerCaseSegments(urlTree.root);
    if (urlChanged) {
      this.setPrerenderMetaTags(urlTree.toString());
    }

    return urlTree;
  }

  private lowerCaseSegments(urlSegmentGroup: UrlSegmentGroup): boolean {
    let urlChanged = false;

    if (urlSegmentGroup.hasChildren()) {
      for (const [key, value] of Object.entries(urlSegmentGroup.children)) {
        urlChanged ||= this.lowerCaseSegments(value);
      }
    }

    for (const segment of urlSegmentGroup.segments) {
      const lowerCaseSegment = segment.path.toLowerCase();
      if (segment.path !== lowerCaseSegment) {
        segment.path = lowerCaseSegment;
        urlChanged = true;
      }
    }

    return urlChanged;
  }

  private setPrerenderMetaTags(location: string): void {
    this.store.dispatch(
      new CoreActions.AddMetaTags([
        {
          name: 'prerender-status-code',
          content: '301',
        },
        {
          name: 'prerender-header',
          content: `Location: ${location}`,
        },
      ]),
    );
  }
}
