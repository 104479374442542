<div class="announcement-wrapper" *ngIf="!(hideAnnouncement$ | async) && announcement$ | async as announcement">
  <div class="announcement is-width-viewport">
    <div class="{{ announcement.topic }}">
      <span class="announcement-body content" [innerHtml]="announcement.body | bypassSecurity: 'html'"></span>
      <span class="close-button" (click)="close(announcement.id)">
        <svg-exit-circle></svg-exit-circle>
      </span>
    </div>
  </div>
</div>
