<button *ngIf="!href" class="icon-button-label is-clickable" (click)="checkClick($event)">
  <ng-template *ngTemplateOutlet="content"></ng-template>
</button>

<a *ngIf="href" [href]="href" class="icon-button-label no-link" (click)="checkClick($event)">
  <ng-template *ngTemplateOutlet="content"></ng-template>
</a>

<ng-template #content>
  <span class="button-icon"><ng-content></ng-content></span>
  <span class="button-label">{{ label }}</span>
</ng-template>