import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, LOCALE_ID, OnInit, inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appstore-buttons',
  standalone: true,
  templateUrl: './appstore-buttons.component.html',
  styleUrls: ['./appstore-buttons.component.sass'],
  imports: [CommonModule, NgOptimizedImage],
})
export class AppstoreButtonsComponent implements OnInit {
  @Input() inMobileMenu = false;
  private language = inject(LOCALE_ID);
  public playStoreLink =
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    environment.appStoreUrls['default']['android'][this.language];
  public appStoreLink =
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    environment.appStoreUrls['default']['ios'][this.language];

  ngOnInit() {
    if (this.inMobileMenu) {
      this.playStoreLink =
        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
        environment.appStoreUrls['mobile_menu']['android'][this.language];
      this.appStoreLink =
        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
        environment.appStoreUrls['mobile_menu']['ios'][this.language];
    }
  }
}
