import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookmarksListPageComponent } from '../bookmarks/bookmarks-list-page/bookmarks-list-page.component';
import { BookmarksListsPageComponent } from '../bookmarks/bookmarks-lists-page/bookmarks-lists-page.component';
import { DiscoverComponent } from './tab-navigation/discover/discover.component';
import { MyProgramsPageComponent } from './tab-navigation/my-programs-page/my-programs-page.component';
import { MyVideosPageComponent } from './tab-navigation/my-videos-page/my-videos-page.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';

const routes: Routes = [
  {
    path: '',
    component: TabNavigationComponent,
    children: [
      { path: '', component: DiscoverComponent },
      {
        path: 'meine-programme',
        component: MyProgramsPageComponent,
      },
      {
        path: 'meine-programme/:slug/teilnehmen',
        component: MyProgramsPageComponent,
      },
      {
        path: 'meine-videos',
        component: MyVideosPageComponent,
      },
      {
        path: 'meine-videos/meine-listen',
        component: BookmarksListsPageComponent,
      },
      {
        path: 'meine-videos/meine-listen/:id',
        component: BookmarksListPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
