import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-disrupter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './disrupter.component.html',
  styleUrls: ['./disrupter.component.sass']
})
export class DisrupterComponent {
  @Input() largeText?: string;
  @Input() smallText?: string;
}
