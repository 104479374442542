<div class="filtered-list mb-5">
  <div *ngIf="page === 0 && loading$ | async; else loaded" class="is-flex is-justify-content-center mt-6">
    <app-ye-loading-spinner></app-ye-loading-spinner>
  </div>
  <ng-template #loaded>
    <h3 *ngIf="!displayedTeasers?.length; else results" class="headline" i18n>Keine Suchergebnisse gefunden</h3>
    <ng-template #results>
      <div class="is-flex is-align-items-baseline is-justify-content-space-between mb-4">
        <h3 class="headline" i18n>Deine Treffer</h3>
        <app-sort-by-button></app-sort-by-button>
      </div>
      <ul class="is-grid-272px is-justify-content-space-around-touch is-justify-content-space-between-desktop is-aligned-left-last-row">
        <app-video-teaser *ngFor="let teaser of displayedTeasers" [teaser]="teaser" size="small" [breadcrumb]="['yoga-home', 'filter']"
          class="mb-5">
        </app-video-teaser>
      </ul>
      <div *ngIf="page != 0 && loading$ | async" class="is-flex is-justify-content-center">
        <app-ye-loading-spinner></app-ye-loading-spinner>
      </div>
      <div class="button-wrapper is-flex is-justify-content-center">
        <button *ngIf="showButton" class="show-more-button" (click)="showMoreButton()" i18n>Mehr anzeigen</button>
      </div>

    </ng-template>
  </ng-template>
</div>