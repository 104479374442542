<ul class="bookmarks-menu-list">
  <li>
    <app-bookmarks-menu-list-item-favorite [videoRef]="videoRef"> </app-bookmarks-menu-list-item-favorite>
  </li>
  <li *ngIf="loading$ | async; else showLists" class="is-flex is-justify-content-center is-align-items-center">
    <app-loading-circle></app-loading-circle>
  </li>
  <ng-template #showLists>
    <li *ngFor="let list of listsChecked; trackBy: trackBy">
      <app-bookmarks-menu-list-item [videoRef]="videoRef" [listChecked]="list"></app-bookmarks-menu-list-item>
    </li>
  </ng-template>
</ul>
