import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PodcastTeaser } from 'src/app/api/models/podcast-teaser';
import { PodcastIconSvg } from 'src/app/svg/podcast-icon/podcast-icon.svg';

@Component({
  selector: 'app-podcast-teaser',
  standalone: true,
  imports: [CommonModule, PodcastIconSvg],
  templateUrl: './podcast-teaser.component.html',
  styleUrls: ['./podcast-teaser.component.sass'],
})
export class PodcastTeaserComponent {
  @Input() teaser: PodcastTeaser;
}
