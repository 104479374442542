import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { YeLoadingSpinnerComponent } from '../ye-loading-spinner/ye-loading-spinner.component';

@Component({
  selector: 'app-loading-page',
  standalone: true,
  imports: [CommonModule, YeLoadingSpinnerComponent],
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.sass'],
})
export class LoadingPageComponent {}
