import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BookmarksList } from '../api/models/bookmarks-list';
import { BookmarksListWithItems } from '../api/models/bookmarks-list-with-items';
import { SortByOption } from '../api/models/sort-by-option';
import { BookmarksService } from '../api/services/bookmarks.service';
import { BookmarksActions } from './bookmarks.actions';

type Response<T> = { data?: T; loading?: boolean; error?: string };

export interface BookmarksStateModel {
  createdList: BookmarksList;
  bookmarksList: BookmarksListWithItems;
  bookmarksListError: string;
  bookmarksLists: Array<BookmarksList>;
  error: any;
  isLoading: boolean;
  itemListsResponse: Response<Array<number>>;
  selectedSortOption: SortByOption;
}

@State<BookmarksStateModel>({
  name: 'bookmarks',
  defaults: {
    createdList: undefined,
    bookmarksList: undefined,
    bookmarksListError: undefined,
    bookmarksLists: undefined,
    error: undefined,
    isLoading: false,
    itemListsResponse: undefined,
    selectedSortOption: undefined,
  },
})
@Injectable()
export class BookmarksState {
  constructor(private bookmarksListService: BookmarksService) {}

  @Action(BookmarksActions.CreateList)
  createList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.CreateList) {
    ctx.patchState({ isLoading: true, createdList: null });
    let _bookmarksLists = ctx.getState().bookmarksLists;

    return this.bookmarksListService.createList(action.name).pipe(
      tap(result => {
        ctx.patchState({ isLoading: false, bookmarksLists: [result].concat(_bookmarksLists), createdList: result });
      }),
      catchError(error => {
        ctx.patchState({ error, isLoading: false });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.FetchLists)
  fetchLists(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.FetchLists) {
    return this.bookmarksListService.getLists(action.limit).pipe(tap(bookmarksLists => ctx.patchState({ bookmarksLists })));
  }

  @Action(BookmarksActions.FetchList)
  fetchList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.FetchList) {
    ctx.patchState({ selectedSortOption: action.sortBy });
    return this.bookmarksListService.getList(action.id, action.sortBy?.key, action.limit).pipe(
      tap(
        bookmarksList => ctx.patchState({ bookmarksList, bookmarksListError: undefined }),
        ret =>
          ctx.patchState({
            bookmarksList: undefined,
            bookmarksListError: ret.error.message,
          }),
      ),
    );
  }

  @Action(BookmarksActions.RenameList)
  renameList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.RenameList) {
    ctx.patchState({ isLoading: true });
    return this.bookmarksListService.renameList(action.id, action.newName).pipe(
      tap(_ => ctx.patchState({ isLoading: false, error: null })),
      catchError(error => {
        ctx.patchState({ error, isLoading: false });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.DeleteList)
  deleteList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.DeleteList) {
    ctx.patchState({ isLoading: true });
    return this.bookmarksListService.deleteList(action.id).pipe(
      tap(_ => ctx.patchState({ isLoading: false, error: null })),
      catchError(error => {
        ctx.patchState({ error, isLoading: false });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.FetchItemLists)
  fetchItemLists(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.FetchItemLists) {
    ctx.patchState({ itemListsResponse: { data: null, loading: true, error: null } });
    return this.bookmarksListService.getItemLists(action.itemId).pipe(
      tap(itemLists => ctx.patchState({ itemListsResponse: { data: itemLists, loading: false } })),
      catchError(error => {
        ctx.patchState({ itemListsResponse: { loading: false, error: error } });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.RefetchItemLists, { cancelUncompleted: true })
  refetchItemLists(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.RefetchItemLists) {
    return this.bookmarksListService
      .getItemLists(action.itemId)
      .pipe(tap(itemLists => ctx.patchState({ itemListsResponse: { data: itemLists } })));
  }

  @Action(BookmarksActions.AddItemToList)
  addItemToList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.AddItemToList) {
    ctx.patchState({ isLoading: true, error: null });
    return this.bookmarksListService.addItemToList(action.itemId, action.listId).pipe(
      tap(_ => ctx.patchState({ isLoading: false })),
      catchError(error => {
        ctx.patchState({ error, isLoading: false });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.RemoveItemFromList)
  removeItemFromList(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.RemoveItemFromList) {
    ctx.patchState({ isLoading: true, error: null });
    return this.bookmarksListService.removeItemFromList(action.itemId, action.listId).pipe(
      tap(_ => ctx.patchState({ isLoading: false })),
      catchError(error => {
        ctx.patchState({ error, isLoading: false });
        return throwError(error);
      }),
    );
  }

  @Action(BookmarksActions.UpdateItemPosition, { cancelUncompleted: true })
  updateItemPosition(ctx: StateContext<BookmarksStateModel>, action: BookmarksActions.UpdateItemPosition) {
    return this.bookmarksListService.updateItemPosition(action.listId, action.itemId, action.target).pipe(
      tap({
        next: bookmarksList => ctx.patchState({ bookmarksListError: undefined }),
        error: ret =>
          ctx.patchState({
            bookmarksList: undefined,
            bookmarksListError: ret.error.message,
          }),
      }),
    );
  }
}
