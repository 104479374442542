import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button-label',
  standalone: true,
  templateUrl: './icon-button-label.component.html',
  styleUrls: ['./icon-button-label.component.sass'],
  imports: [CommonModule],
})
export class IconButtonLabelComponent {
  @Input() label!: string;
  @Input() href?: string;
  disabled?: boolean = false;
  // TODO: Implement disabled state and add @Input()

  @Output() onClick = new EventEmitter<MouseEvent>();

  checkClick(event: MouseEvent) {
    if (!this.disabled) this.onClick.emit(event);
  }
}
