<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.1649 2.41421C16.9459 1.63317 18.2123 1.63317 18.9933 2.41421L20.5859 4.00684C21.367 4.78789 21.367 6.05422 20.5859 6.83527L8.21752 19.2037C7.84245 19.5788 7.33374 19.7895 6.8033 19.7895L3.21068 19.7895L3.21068 16.1969C3.21068 15.6664 3.42139 15.1577 3.79647 14.7826L16.1649 2.41421Z"
    stroke="currentColor"
    stroke-width="1.7"
  />
  <path d="M14 4L19 9" stroke="currentColor" stroke-width="1.7" />
</svg>
