<li *ngIf="teaser" class="program-teaser has-border-radius-5">
  <div class="image-wrapper">
    <a class="no-link" [title]="teaser.title" [href]="teaser.path">
      <img class="preview-image has-border-radius-5" [ngSrc]="teaser.new_program_picture | relativeUrl" [alt]="teaser.title" width="342"
        height="321" [loaderParams]="{ fallback: 'production/uploads/components/program-teaser/fallback-image.jpg' }" />
      <div class="info has-border-radius-5-bottom">
        {{ teaser.unit_count }} Einheiten | {{ teaser.video_count }} Videos | Level {{ teaser.level }}
      </div>
    </a>
  </div>
</li>