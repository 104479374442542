<footer class="footer">
  <div class="is-width-viewport has-text-white has-children-links-underline-on-hover">
    <!-- inside the footer, links look different than app-text-link -->
    <div class="is-flex is-flex-direction-column is-align-items-center">
      <a routerLink="/"><svg-ye-logo-dark></svg-ye-logo-dark></a>
      <h2 class="is-size-4 has-text-weight-bold has-text-centered mt-4 mb-2" i18n>Wir halten dich auf dem Laufenden</h2>
      <p class="is-size-6 no-global-styles has-text-centered" i18n>
        Unser Newsletter informiert dich wöchentlich über neue Videos und gibt dir yogische Inspiration.
      </p>
      <form class="email-form" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div class="field mt-4">
          <div class="control has-floating-label">
            <input formControlName="emailAddress"
              class="input with-floating-label is-medium has-text-primary has-outline-none-on-focus no-global-styles"
              [ngClass]="{ 'is-danger has-text-danger': !emailForm.valid && (emailForm.touched || emailForm.dirty) }" type="email"
              placeholder=" " required="required" />
            <label class="label has-text-silver is-floating-label"
              [ngClass]="{ 'has-text-danger': !emailForm.valid && (emailForm.touched || emailForm.dirty) }" i18n>
              E-Mail-Adresse
            </label>
          </div>
          <p *ngIf="!emailForm.valid && (emailForm.touched || emailForm.dirty)" class="help" i18n>Bitte gib eine gültige
            E-Mail-Adresse an.</p>
        </div>
        <div class="field mt-4">
          <div class="control">
            <button class="button has-background-light-teal is-borderless is-medium is-rounded is-fullwidth has-text-kokoda"
              [ngClass]="{ 'is-loading': emailFormSubmitted }" type="submit" i18n>
              Newsletter abonnieren
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="!(contentOnly$ | async)"
      class="is-hidden-tablet has-children-links-bordered-top-bottom my-5 is-flex is-flex-direction-column is-align-items-stretch">
      <a routerLink="/video-kategorien">
        <h5 class="has-text-centered is-size-5 py-4" i18n="footer | video_link">Yoga-Videos</h5>
      </a>
      <a routerLink="/yogastile" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Yogastile</h5>
      </a>
      <a routerLink="/magazin">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Yoga-Magazin</h5>
      </a>
      <a routerLink="/yogalehrer">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Yoga-Lehrer:innen</h5>
      </a>
      <a routerLink="/programme">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Yoga-Programme</h5>
      </a>
      <a [href]="(siteParams$ | async)?.cta_button?.button_url || '/prices'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Preise</h5>
      </a>
      <a routerLink="/studi-rabatt" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Rabatt für Student:innen</h5>
      </a>
      <a routerLink="/firmen-yoga" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Rabatt für Firmen</h5>
      </a>
      <a href="/gutschein" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Gutscheine</h5>
      </a>
      <a href="https://academy.yogaeasy.de/" target="_blank" rel="noopener" i18n-href>
        <h5 class="has-text-centered is-size-5 py-4" i18n>YogaEasy Academy</h5>
      </a>
      <a href="/podcast" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Podcast</h5>
      </a>
      <a href="https://support.yogaeasy.de/hc/de" target="_blank" rel="noopener" i18n-href>
        <h5 class="has-text-centered is-size-5 py-4" i18n>Fragen & Antworten</h5>
      </a>
      <a href="https://support.yogaeasy.de/hc/de/sections/6704072718353-Tipps-f%C3%BCr-YogaEasy-Neulinge" target="_blank" rel="noopener">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Tipps für deinen Start</h5>
      </a>
      <a href="/vertrag-kuendigen" (click)="onCancel()" target="_blank" rel="noopener" *ngIf="locale === 'de'">
        <h5 class="has-text-centered is-size-5 py-4" i18n>Verträge hier kündigen</h5>
      </a>
    </div>

    <div *ngIf="!(contentOnly$ | async)" class="is-hidden-mobile columns mt-6">
      <div class="column" [ngClass]="{'is-3 is-offset-1': locale !== 'de', 'is-4': locale === 'de'}">
        <a routerLink="/video-kategorien">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n="footer | video_link">Yoga-Videos</h5>
        </a>
        <ul *ngIf="(footer$ | async) as footer">
          <li *ngFor="let category of footer.categories">
            <a [routerLink]="category.path">{{ category.title }}</a>
          </li>
        </ul>
      </div>
      <div class="column is-3" *ngIf="locale === 'de'">
        <a routerLink="/yogastile">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold">Yogastile</h5>
        </a>
        <ul *ngIf="(footer$ | async) as footer">
          <li *ngFor="let style of footer.styles">
            <a [routerLink]="style.path">{{ style.title }}</a>
          </li>
        </ul>
      </div>
      <div class="column is-3" [ngClass]="{'is-offset-1': locale !== 'de'}">
        <a routerLink="/magazin">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Alles über Yoga im Yoga-Magazin</h5>
        </a>
        <ul *ngIf="(footer$ | async) as footer" class="ml-0 is-hidden-mobile">
          <li>
            <a routerLink="/magazin/neu-im-yogamag" i18n>Neu im Yoga-Magazin</a>
          </li>
          <li *ngFor="let blog of footer.blogs">
            <a [routerLink]="blog.path">{{ blog.title }}</a>
          </li>
        </ul>
      </div>
      <div class="column is-3" [ngClass]="{'is-offset-1': locale !== 'de'}">
        <a routerLink="/yogalehrer">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Yoga-Lehrer:innen</h5>
        </a>
        <a routerLink="/programme">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Yoga-Programme</h5>
        </a>
        <a [href]="(siteParams$ | async)?.cta_button?.button_url || '/prices'">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Preise</h5>
        </a>
        <a routerLink="/studi-rabatt" *ngIf="locale === 'de'">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold">Rabatt für Student:innen</h5>
        </a>
        <a routerLink="/firmen-yoga" *ngIf="locale === 'de'">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold">Rabatt für Firmen</h5>
        </a>
        <a href="/gutschein" *ngIf="locale === 'de'">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Gutscheine</h5>
        </a>
        <a href="https://academy.yogaeasy.de/" target="_blank" rel="noopener" i18n-href>
          <h5 class="is-size-18px has-text-weight-semibold" [ngClass]="{'mb-2': locale === 'de', 'mb-5': locale !== 'de'}" i18n>
            YogaEasy Academy
          </h5>
        </a>
        <a href="/podcast" *ngIf="locale === 'de'">
          <h5 class="is-size-18px has-text-weight-semibold mb-5">Podcast</h5>
        </a>
        <a href="https://support.yogaeasy.de/hc/de" target="_blank" rel="noopener"
          class="is-inline-block pt-5 has-width-172px has-border-top" i18n-href>
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Fragen & Antworten</h5>
        </a>
        <a href="https://support.yogaeasy.de/hc/de/sections/6704072718353-Tipps-f%C3%BCr-YogaEasy-Neulinge" target="_blank" rel="noopener"
          i18n-href>
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Tipps für deinen Start</h5>
        </a>
        <a href="/vertrag-kuendigen" (click)="onCancel()" target="_blank" rel="noopener" *ngIf="locale === 'de'">
          <h5 class="is-size-18px mb-2 has-text-weight-semibold" i18n>Verträge hier kündigen</h5>
        </a>
      </div>
    </div>

    <ul class="has-text-centered is-size-6-mobile mb-5">
      <li class="is-inline-block"><a title="Presse" routerLink="/presse" i18n i18n-routerLink>Presse</a></li>
      <li class="is-inline-block mx-3">|</li>
      <li class="is-inline-block"><a title="AGB" routerLink="/agb" i18n i18n-routerLink>AGB</a></li>
      <br class="is-hidden-tablet" />
      <li class="is-inline-block mx-3 is-hidden-mobile">|</li>
      <li class="is-inline-block"><a title="Datenschutz" routerLink="/datenschutz" i18n i18n-routerLink>Datenschutz</a></li>
      <li class="is-inline-block mx-3">|</li>
      <li class="is-inline-block"><a title="Impressum" routerLink="/impressum" i18n i18n-routerLink>Impressum</a></li>
      <li class="is-inline-block mx-3">|</li>
      <li class="is-inline-block"><a title="Jobs" routerLink="/jobs" i18n>Jobs</a></li>
      <li class="is-inline-block mx-3">|</li>
      <li class="is-inline-block"><a title="Kontakt" routerLink="/kontakt" i18n i18n-routerLink>Kontakt</a></li>
    </ul>

    <div class="columns is-align-items-center">
      <div class="column has-text-centered-mobile" *ngIf="locale === 'de'">
        <a href="https://www.ausgezeichnet.org/bewertungen-yogaeasy.de-HNUKA" target="_blank" rel="noopener">
          <img ngSrc="production/uploads/components/footer/top_dienstleister_2024.png" width="162" height="100"
            alt="Top Dienstleister 2024" />
        </a>
      </div>
      <div class="column has-text-centered" *ngIf="locale === 'de'">
        <app-social-circles></app-social-circles>
      </div>
      <div class="column has-text-centered">
        <app-appstore-buttons />
      </div>
    </div>
  </div>
</footer>