import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleTeaser } from 'src/app/api/models/article-teaser';

@Component({
  selector: 'app-articles-section',
  templateUrl: './articles-section.component.html',
  styleUrls: ['./articles-section.component.sass'],
})
export class ArticlesSectionComponent implements OnInit {
  public articleTeasers$: Observable<ArticleTeaser[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.articleTeasers$ = this.store
      .select((state) => state.homepage.params)
      .pipe(map((params) => params?.articles));
  }
}
