import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'svg-effort-bars-large',
  templateUrl: './effort-bars-large.svg.html',
})
export class EffortBarsLargeSvg {
  @Input() effort: 1 | 2 | 3 | 4 | 5 | 6;
  readonly COLORS = [
    '#009090',
    '#69B8A4',
    '#9DCA83',
    '#FFCE35',
    '#FFB301',
    '#EA5E3D',
  ];
  readonly DEFAULT_COLOR = '#555547';
}
