export const constants = {
  breakpoints: {
    small: 300,
    medium: 730,
    xlarge: 1170,
    mediumQuery: '(min-width:' + 730 + 'px)',
    xlargeQuery: '(min-width:' + 1170 + 'px)',
  }, // same as in viewports.sass
  tracking: {
    articleViewed: 'Article viewed',
  },
};
