<div class="rename-bookmarks-list-modal">
  <div class="header">
    <svg-exit class="close-icon" (click)="closeModal()"></svg-exit>
  </div>
  <h4 class="headline" i18n>Namen ändern</h4>
  <div class="field" [ngClass]="{ error: !!error }">
    <input type="text" [(ngModel)]="newName" class="input is-medium with-floating-label list-name" placeholder=" "
      (ngModelChange)="setButtonState()" (keyup.enter)="!disabled && submit()" />
    <label class="label is-floating-label" for="listName" i18n>Name der Liste</label>
  </div>

  <div class="error text" *ngIf="error">{{ error }}</div>
  <app-loading-circle *ngIf="loading" class="loading-bar"></app-loading-circle>
  <app-button [disabled]="disabled" *ngIf="!loading" class="submit-button" (onClick)="submit()" i18n>Namen übernehmen</app-button>
</div>