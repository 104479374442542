import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.sass'],
})
export class CarouselSlideComponent {
  @Input() minHeight?: number;
}
