<section class="has-background-white">
  <h1 *ngIf="headline" class="is-width-100 is-size-3 has-text-deep-sea-green has-text-centered has-text-weight-bold mb-5 px-4">
    {{ headline }}
  </h1>
  <owl-carousel-o class="has-dots-inside" [options]="autoplayWithMargin">
    <ng-container *ngFor="let review of reviews[locale]">
      <ng-template carouselSlide>
        <app-rating-tile [minHeight]="308">
          <h3 class="is-size-5 has-text-kokoda has-text-weight-bold has-text-centered is-uppercase">
            {{ review.headline }}
          </h3>
          <p class="no-global-styles is-size-5-and-half has-text-kokoda has-text-centered mt-4">
            {{ review.text }}
          </p>
          <p class="no-global-styles is-size-6 has-text-kokoda has-text-centered mt-4">
            {{ review.author }}
          </p>
        </app-rating-tile>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>
