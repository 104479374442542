<ul *ngIf="sortByList" class="sort-by-options">
  <li
    *ngFor="let option of sortByList"
    class="sort-by-option"
    [ngClass]="{ selected: option.key === selectedSortKey }"
    (click)="select(option)"
  >
    <span>
      <svg-checkmark class="checkmark"></svg-checkmark>
      {{ option.label }}
    </span>
  </li>
</ul>
