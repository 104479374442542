<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.03033 20.0303C4.73744 20.3232 4.26256 20.3232 3.96967 20.0303C3.7034 19.7641 3.6792 19.3474 3.89705 19.0538L3.96967 18.9697L18.9697 3.96967C19.2626 3.67678 19.7374 3.67678 20.0303 3.96967C20.2966 4.23594 20.3208 4.6526 20.1029 4.94621L20.0303 5.03033L5.03033 20.0303Z"
    fill="currentColor"
  />
  <path
    d="M20.0303 20.0303C19.7641 20.2966 19.3474 20.3208 19.0538 20.1029L18.9697 20.0303L3.96967 5.03033C3.67678 4.73744 3.67678 4.26256 3.96967 3.96967C4.23593 3.7034 4.6526 3.6792 4.94621 3.89705L5.03033 3.96967L20.0303 18.9697C20.3232 19.2626 20.3232 19.7374 20.0303 20.0303Z"
    fill="currentColor"
  />
</svg>
