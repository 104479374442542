<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M17.51 34.52C20.8723 34.52 24.1591 33.523 26.9547 31.655C29.7503 29.787 31.9293 27.132 33.216 24.0256C34.5027 20.9193 34.8393 17.5012 34.1834 14.2035C33.5274 10.9058 31.9083 7.8767 29.5308 5.49921C27.1533 3.12172 24.1242 1.50263 20.8265 0.846677C17.5289 0.190729 14.1107 0.527385 11.0044 1.81408C7.89805 3.10076 5.24301 5.2797 3.37503 8.07533C1.50704 10.871 0.51001 14.1577 0.51001 17.52C0.51001 22.0287 2.30108 26.3527 5.48919 29.5408C8.67731 32.729 13.0013 34.52 17.51 34.52Z"
    fill="white"
  />
  <path
    d="M22.73 9H12.29C11.4174 9 10.5806 9.34662 9.96362 9.96362C9.34662 10.5806 9 11.4174 9 12.29V22.73C9.00131 23.1607 9.08745 23.587 9.2535 23.9844C9.41955 24.3819 9.66225 24.7427 9.96776 25.0464C10.2733 25.35 10.6356 25.5905 11.034 25.7542C11.4325 25.9178 11.8593 26.0013 12.29 26H22.73C23.5964 25.9974 24.4267 25.652 25.0393 25.0393C25.652 24.4267 25.9974 23.5964 26 22.73V12.29C26 11.4209 25.6561 10.5871 25.0435 9.97066C24.4308 9.35424 23.5991 9.00528 22.73 9ZM23.67 11H24.05V13.89H21.17V11H23.67ZM15.08 15.8C15.496 15.2184 16.1061 14.8046 16.8003 14.633C17.4945 14.4615 18.2271 14.5435 18.8661 14.8644C19.5052 15.1852 20.0086 15.7238 20.2856 16.383C20.5627 17.0422 20.5952 17.7787 20.3773 18.4597C20.1593 19.1408 19.7053 19.7216 19.097 20.0975C18.4887 20.4734 17.7662 20.6196 17.0596 20.5099C16.353 20.4001 15.7088 20.0416 15.2432 19.4989C14.7776 18.9562 14.5211 18.2651 14.52 17.55C14.5106 16.9088 14.7068 16.2815 15.08 15.76V15.8ZM24.36 22.8C24.3574 23.2306 24.1845 23.6426 23.8791 23.9461C23.5737 24.2497 23.1606 24.42 22.73 24.42H12.29C11.8594 24.42 11.4463 24.2497 11.1409 23.9461C10.8355 23.6426 10.6626 23.2306 10.66 22.8V15.8H13.2C12.9141 16.5059 12.806 17.2712 12.8852 18.0287C12.9643 18.7861 13.2283 19.5125 13.6539 20.1441C14.0795 20.7756 14.6538 21.293 15.3261 21.6507C15.9985 22.0083 16.7484 22.1954 17.51 22.1954C18.2716 22.1954 19.0215 22.0083 19.6939 21.6507C20.3662 21.293 20.9405 20.7756 21.3661 20.1441C21.7917 19.5125 22.0557 18.7861 22.1348 18.0287C22.214 17.2712 22.1059 16.5059 21.82 15.8H24.36V22.8Z"
    fill="#0E5C63"
  />
</svg>
