import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookmarksListWithItems } from 'src/app/api/models/bookmarks-list-with-items';
import { SortByOption } from 'src/app/api/models/sort-by-option';
import { CoreActions } from 'src/app/core/core.actions';
import { BookmarksActions } from '../bookmarks.actions';

@Component({
  selector: 'app-sort-bookmarks-list',
  templateUrl: './sort-bookmarks-list.component.html',
  styleUrls: ['./sort-bookmarks-list.component.sass'],
})
export class SortBookmarksListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Select(state => state.bookmarks?.bookmarksList)
  bookmarksList$: Observable<BookmarksListWithItems>;
  @Select(state => state.bookmarks?.selectedSortOption)
  selectedSortOption$: Observable<SortByOption>;
  sortByList: Array<SortByOption>;
  selectedSortKey = 'position';
  bookmarksListId: number;
  public static readonly MODAL_ID = 'SortBookmarksList';
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.bookmarksList$
      .pipe(takeUntil(this.destroy$))
      .subscribe(bookmarksList => {
        this.sortByList = bookmarksList.sort_by_list;
        this.bookmarksListId = bookmarksList.id;
      });
    this.selectedSortOption$
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedSortOption => {
        this.selectedSortKey = selectedSortOption?.key || this.selectedSortKey;
      });
  }

  select(option: SortByOption) {
    if (option.key !== this.selectedSortKey) {
      this.selectedSortKey = option.key;
      this.store.dispatch(
        new BookmarksActions.FetchList(this.bookmarksListId, option),
      );
      this.store.dispatch(
        new CoreActions.CloseModal(SortBookmarksListComponent.MODAL_ID),
      );
      this.store.dispatch(new CoreActions.SetBottomsheet(null));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
