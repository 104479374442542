<li *ngIf="teaser" class="teacher-teaser">
  <a [href]="teaser.path" [title]="teaser.name">
    <div class="profile-image">
      <picture>
        <source *ngIf="teaser.round_images.big_thumb_webp" [srcset]="teaser.round_images.big_thumb_webp" type="image/webp" />
        <source *ngIf="teaser.round_images.big_thumb" [srcset]="teaser.round_images.big_thumb" type="image/jpeg" />
        <img loading="lazy" [alt]="teaser.name" [src]="teaser.round_images.big_thumb"
          fallbackSrc="https://cdn.yogaeasy.de/production/uploads/components/magazine-article-teaser/teaser_fallback_image.jpg"
          class="preview-image" />
      </picture>
    </div>
    <h1 class="medium-headline">{{ teaser.name }}</h1>
    <div class="subheadline">{{ teaser.studios.join(',') }}</div>
    <div class="description">
      <p>
        {{ teaser.short_description }}
      </p>
    </div>
  </a>
</li>