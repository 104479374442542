<ul class="ml-0 px-5 has-white-space-nowrap has-bordered-children">
  <li>
    <a href="/mein-konto" class="py-4 is-flex is-align-items-center is-justify-content-space-between has-text-primary">
      <span class="is-uppercase is-size-5-and-half has-text-weight-semibold mr-3" i18n>Mein Konto</span>
      <svg-arrow class="icon has-text-kokoda"></svg-arrow>
    </a>
  </li>
  <li>
    <a class="py-4 is-flex is-align-items-center is-justify-content-space-between has-text-primary" (click)="signOut()">
      <span class="is-uppercase is-size-5-and-half has-text-weight-semibold mr-3" i18n>Abmelden</span>
      <svg-arrow class="icon has-text-kokoda"></svg-arrow>
    </a>
  </li>
</ul>