import { Component, Inject, LOCALE_ID } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ArrowDownSvg } from 'src/app/svg/arrow-down/arrow-down.svg';
import { CheckmarkSvg } from '../../svg/checkmark/checkmark.svg';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [NgbPopoverModule, CheckmarkSvg, ButtonComponent, ArrowDownSvg],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.sass',
})
export class LanguageSwitcherComponent {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  public getPlacement(): string {
    const MIN_DESKTOP_WIDTH = 1024;

    return window.innerWidth <= MIN_DESKTOP_WIDTH ? 'bottom-left' : 'bottom';
  }

  getEnglishDomain(): string {
    // as we care only about *.yogaeasy.com and *.yogaeasy.de, we're pretty
    // confident that nothing else except TLD will be replaced
    // origin will include protocol, host and port
    return window.location.origin.replace('.de', '.com');
  }

  getGermanDomain(): string {
    // as we care only about *.yogaeasy.com and *.yogaeasy.de, we're pretty
    // confident that nothing else except TLD will be replaced
    // origin will include protocol, host and port
    return window.location.origin.replace('.com', '.de');
  }
}
