import { Component, Inject, LOCALE_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-live-section',
  templateUrl: './live-section.component.html',
  styleUrl: './live-section.component.sass',
})
export class LiveSectionComponent {
  public BREAKPOINTS = constants.breakpoints;

  public autoplay: OwlOptions = {
    autoplay: true,
    loop: true,
    dots: true,
    dotsSpeed: 1200,
    autoplaySpeed: 1200,
    autoplayTimeout: 5000,
    nav: false,
    items: 1,
    responsive: {
      [this.BREAKPOINTS.medium]: {
        items: 1,
        margin: 16,
      },
    },
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
