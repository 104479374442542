import { Component } from '@angular/core';

@Component({
  selector: 'app-esther-videos',
  template: `<app-show-more-page
    type="video"
    subPath="search?teachers=Esther Ekhart"
    categorySlug="Esther"
    headline="Classes with Esther"
    subheadline="Move, breathe and meditate with Esther Ekhart"
  ></app-show-more-page>`,
})
export class EstherVideosComponent {}
