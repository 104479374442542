import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Utility } from 'src/app/shared/utility';
import { environment } from 'src/environments/environment';
import { ArticleTeaser } from '../models/article-teaser';
import { Dashboard } from '../models/dashboard';
import { NewsItem } from '../models/news-item';
import { PodcastTeaser } from '../models/podcast-teaser';
import { ProgramTeaser } from '../models/program-teaser';
import { TeacherTeaser } from '../models/teacher-teaser';
import { VideoTeaser } from '../models/video-teaser';
import { VideoTeasersSlider } from '../models/video-teasers-slider';
import { DASHBOARD_DE, DASHBOARD_EN } from './dashboard.data';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) public locale: string,
  ) {}
  path = `${environment.apiPath}/v1/`;

  getArticleTeasers(limit?: number): Observable<Array<ArticleTeaser>> {
    const params = limit ? { limit } : undefined;
    return this.httpClient
      .get<Array<ArticleTeaser>>(`${this.path}articles`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getPodcastTeasers(limit?: number): Observable<Array<PodcastTeaser>> {
    const params = limit ? { limit } : undefined;
    return this.httpClient
      .get<Array<PodcastTeaser>>(`${this.path}podcasts/new_category`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getProgramTeasers(limit?: number): Observable<Array<ProgramTeaser>> {
    const params = limit ? { limit } : undefined;
    return this.httpClient
      .get<Array<ProgramTeaser>>(`${this.path}programs`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getTeacherTeasers(limit?: number): Observable<Array<TeacherTeaser>> {
    const params = limit ? { limit } : undefined;
    return this.httpClient
      .get<Array<TeacherTeaser>>(`${this.path}teachers/new_category`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getVideoTeasers(
    subPath: string,
    limit?: number,
    page?: number,
  ): Observable<VideoTeasersSlider> {
    const queryParams = {};
    let apiPath = `${this.path}videos/`;

    // TODO:
    if (subPath === 'favorites') {
      apiPath = `${this.path}favorites/`;
      subPath = '';
    }

    return this.httpClient
      .get<VideoTeasersSlider>(apiPath + subPath, {
        params: Utility.removeFalsyPropertiesFromHttpParams({
          ...queryParams,
          limit,
          page,
        }),
      })
      .pipe(catchError(this.handleError));
  }

  getDashboard(): Observable<Dashboard> {
    let dashboard: Dashboard;

    if (this.locale === 'de') {
      dashboard = structuredClone(DASHBOARD_DE);
    } else {
      dashboard = structuredClone(DASHBOARD_EN);
    }

    const requests = [];
    for (const slider of dashboard.sliders) {
      const paramsDivider = slider.api.includes('?') ? '&' : '?';
      const path = `${this.path}${slider.api}${paramsDivider}limit=7`;

      requests.push(this.httpClient.get(path).pipe(catchError(e => of([]))));
    }
    return forkJoin(requests).pipe(
      map(results => {
        for (let i = 0; i < results.length; i++) {
          const slider = dashboard.sliders[i];
          switch (slider.type) {
            case 'article':
              slider.teasers = results[i] as Array<ArticleTeaser>;
              break;
            case 'podcast':
              slider.teasers = results[i] as Array<PodcastTeaser>;
              break;
            case 'program':
              slider.teasers = results[i] as Array<ProgramTeaser>;
              break;
            case 'teacher':
              slider.teasers = results[i] as Array<TeacherTeaser>;
              break;
            case 'video':
              slider.teasers = results[i]?.videos as Array<VideoTeaser>;
              break;
          }
        }
        return dashboard;
      }),
    );
  }

  getNewsItem(): Observable<NewsItem> {
    return this.httpClient
      .get<NewsItem>(`${this.path}news_items/content_banner`)
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.error.message}`;
    }

    return throwError(errorMessage);
  }
}
