import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewsletterPageParams } from '../models/newsletter-page-params';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  baseUrl = environment.apiPath + '/v1/';

  register(email: string): Observable<any> {
    const url = this.baseUrl + 'newsletter/subscribe';

    let params = Object.assign(
      {},
      this.store.selectSnapshot(state => state.router.state.queryParams),
      { email: email },
    );

    return this.httpClient.post(url, params);
  }

  optIn(hash: string) {
    const url = this.baseUrl + 'newsletter/optin';
    return this.httpClient.post(url, { encrypted_hash: hash });
  }

  optOut(uuid: string) {
    const url = this.baseUrl + 'newsletter/optout';
    return this.httpClient.post(url, { uuid: uuid });
  }

  getNewsletterForm() {
    const url = this.baseUrl + 'site_config/page_params?page=newsletter-form';
    return this.httpClient.get<NewsletterPageParams>(url);
  }

  getNewsletterOptIn() {
    const url = this.baseUrl + 'site_config/page_params?page=newsletter-opt-in';
    return this.httpClient.get<NewsletterPageParams>(url);
  }

  getNewsletterConfirm() {
    const url = this.baseUrl + 'site_config/page_params?page=newsletter-opt-in-confirm';
    return this.httpClient.get<NewsletterPageParams>(url);
  }

  getNewsletterOptOut() {
    const url = this.baseUrl + 'site_config/page_params?page=newsletter-opt-out';
    return this.httpClient.get<NewsletterPageParams>(url);
  }
}
