<div class="my-videos-page">
  <app-teaser-slider *ngIf="bookmarksLists?.length" i18n-headline headline="Favoritenlisten" headlineLink="/meine-videos/meine-listen">
    <app-bookmarks-list-favorite-teaser [itemsCount]="favoritesVideoCount" class="teaser"></app-bookmarks-list-favorite-teaser>
    <app-add-bookmarks-list-teaser class="teaser"></app-add-bookmarks-list-teaser>
    <app-bookmarks-list-teaser *ngFor="let list of bookmarksLists" [teaser]="list" class="teaser"></app-bookmarks-list-teaser>
    <app-more-teaser *ngIf="bookmarksLists?.length >= 10" type="bookmarks" href="/meine-videos/meine-listen" [hideText]="true"
      [customCss]="'width: 176px; border: none'" />
  </app-teaser-slider>

  <app-teaser-slider i18n-headline headline="Zuletzt gesehen" headlineLink="/homepage/videos/zuletzt-gesehen">
    <ng-container *ngIf="lastViewed?.length">
      <app-video-teaser *ngFor="let teaser of lastViewed" [teaser]="teaser" size="small" [breadcrumb]="[page, 'Zuletzt-gesehen']"
        class="teaser"></app-video-teaser>
      <app-more-teaser type="video" href="/homepage/videos/zuletzt-gesehen" />
    </ng-container>
    <app-teaser-slider-empty-message *ngIf="lastViewed === undefined" slot="empty" [displayHeadline]="false">
      <a class="light-text" routerLink="/video-kategorien" i18n> Starte jetzt mit deiner ersten Yoga-Session </a>
    </app-teaser-slider-empty-message>
  </app-teaser-slider>

  <app-teaser-slider i18n-headline headline="Meine Favoriten" headlineLink="/homepage/videos/meine-favoriten"
    *ngIf="!bookmarksLists?.length">
    <ng-container *ngIf="favorites?.length">
      <app-video-teaser *ngFor="let teaser of favorites" [teaser]="teaser" size="small" [breadcrumb]="[page, 'Favoriten']"
        class="teaser"></app-video-teaser>
      <app-more-teaser type="video" href="/homepage/videos/meine-favoriten" />
    </ng-container>
    <app-teaser-slider-empty-message slot="empty">
      <svg-heart-filled class="is-block" style="width: 56px; color: #ea5e3d"></svg-heart-filled>
      <span class="light-text" i18n> Fülle diesen Platz mit deinen liebsten Yogavideos. </span>
    </app-teaser-slider-empty-message>
  </app-teaser-slider>

  <app-teaser-slider *ngIf="ratedGood?.length" i18n-headline headline="Von mir gut bewertet" headlineLink="/homepage/videos/gut-bewertet">
    <app-video-teaser *ngFor="let teaser of ratedGood" [teaser]="teaser" size="small" [breadcrumb]="[page, 'Gut-bewertet']"
      class="teaser"></app-video-teaser>
    <app-more-teaser type="video" href="/homepage/videos/gut-bewertet" />
  </app-teaser-slider>
</div>