import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  path = environment.apiPath + '/v1/seo/';

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) {}

  getSeoData(modelName?: string, id?: any): Observable<Data> {
    const currentPath = this.route.snapshot['_routerState'].url.split('?')[0];
    const apiRequestUrl = `${this.path}${modelName}/${id}?path=${currentPath}`;
    return this.httpClient.get<Data>(apiRequestUrl).pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = 'Error occurred ' + err.error.message;
    } else {
      errorMessage = 'System Error occurred ' + err.error.message;
    }

    return throwError(errorMessage);
  }
}
