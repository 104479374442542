<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line
    x1="3.56436"
    y1="4.15"
    x2="21.1501"
    y2="4.15"
    stroke="currentcolor"
    stroke-width="1.7"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <line
    x1="3.56436"
    y1="19.15"
    x2="21.1501"
    y2="19.15"
    stroke="currentcolor"
    stroke-width="1.7"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <line
    x1="3.56436"
    y1="11.65"
    x2="14.7215"
    y2="11.65"
    stroke="currentcolor"
    stroke-width="1.7"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
