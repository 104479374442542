import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/api/models/user';
import { ProfilSvg } from 'src/app/svg/profil/profil.svg';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-user-button',
  standalone: true,
  imports: [
    CommonModule,
    UserMenuComponent,
    NgbTooltipModule,
    RouterModule,
    ProfilSvg,
  ],
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.sass'],
})
export class UserButtonComponent implements OnInit {
  public active = false;
  public user$: Observable<User>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.user$ = this.store.select(state => state.user.user);
  }
}
