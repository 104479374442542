import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Utility } from 'src/app/shared/utility';
import { CoreActions } from '../core.actions';

@Injectable()
export class RedirectionInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {}

  intercept(
    // rome-ignore lint/suspicious/noExplicitAny: <explanation>
    request: HttpRequest<any>,
    next: HttpHandler,
    // rome-ignore lint/suspicious/noExplicitAny: <explanation>
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (
            response.status === 200 &&
            response.headers.has('redirect-path')
          ) {
            this.store.dispatch(
              new CoreActions.AddMetaTags([
                {
                  name: 'prerender-status-code',
                  content: response.headers.get('redirect-status-code'),
                },
                {
                  name: 'prerender-header',
                  content: `Location: ${response.headers.get('redirect-path')}`,
                },
              ]),
            );
            this.store.dispatch(new CoreActions.SetRedirected(true));
            if (this.router.url !== response.headers.get('redirect-path'))
              this.redirectTo(response.headers.get('redirect-path'));
            return;
          }
        }
      }),
    );
  }

  redirectTo(url: string): void {
    if (!Utility.isPrerender()) {
      document.location.href = url;
    }
  }
}
