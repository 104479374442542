<div class="mb-2-desktop">
  <button class="language-switcher-button" [ngbPopover]="listMenu" [placement]="getPlacement()">
    <ng-container i18n>DE</ng-container>
    <svg-arrow-down class="icon is-small ml-1"></svg-arrow-down>
  </button>
  <ng-template #listMenu>
    @if(locale === 'de') {
    <ul class="language-list">
      <li class="language-item"><a [href]="getEnglishDomain()">
          <button class="language-button">Englisch</button>
        </a>
      </li>
      <li class="language-item mt-3">
        <button class="language-button">Deutsch<svg-checkmark class="icon is-small ml-1"></svg-checkmark>
        </button>
      </li>
    </ul>
    } @else {
    <ul>
      <li class="language-item">
        <button class="language-button">English<svg-checkmark class="icon is-small ml-1"></svg-checkmark>
        </button>
      </li>
      <li class="language-item mt-3"><a [href]="getGermanDomain()">
          <button class="language-button">German</button></a>
      </li>
    </ul>
    }
  </ng-template>
</div>