import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { FilterOptionKeys } from '../api/models/filter-options';

export type QueryParams = {
  [key: string]: number | string | Array<string> | boolean;
};

export type RoutesWithPathMatch = Array<
  Required<Pick<Route, 'path' | 'pathMatch'>>
>;

@Injectable({
  providedIn: 'root',
})
export class Utility {
  static hashToCamelCase(hash: { [key: string]: unknown }) {
    if (!hash) return undefined;
    const result = {};
    for (const key of Object.keys(hash)) {
      result[Utility.snakeCaseToCamelCase(key)] = hash[key];
    }
    return result;
  }

  static snakeCaseToCamelCase(name: string) {
    if (name === '') return name;
    if (!name) return undefined;
    return name.replace(/_(\w)/g, match => {
      return match[1].toUpperCase();
    });
  }

  /**  ['a', 'b', 'c'] -> 'a, b und c' */
  static toSentence(array: Array<unknown>): string {
    if (!array) return undefined;
    if (!array.length) return '';
    return (
      array.slice(0, -1).join(', ') +
      (array.length > 1 ? ` und ${array.slice(-1)}` : array.slice(0))
    );
  }

  /** removes undefined, null, '' and [] properties from HttpParams */
  static removeFalsyPropertiesFromHttpParams(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    params: any,
  ): HttpParams | undefined {
    if (!params) return undefined;

    let httpParams: HttpParams = new HttpParams();
    for (const key of Object.keys(params)) {
      const value = params[key];
      if ((value && value.length !== 0) || value === false) {
        httpParams = httpParams.set(key, value);
      }
    }

    return httpParams;
  }

  /** removes undefined, null, '' and [] properties from QueryParams */
  static removeFalsyPropertiesFromQueryParams(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    params: any,
  ): QueryParams | undefined {
    if (!params) return undefined;

    const queryParams: QueryParams = {};
    for (const key of Object.keys(params)) {
      const value = params[key];
      if ((value && value.length !== 0) || value === false) {
        queryParams[key] = value;
      }
    }

    return queryParams;
  }

  /** 'lorem' -> ['lorem'] | ['ipsum'] -> ['ipsum'] | '' -> undefined */
  static toStringArray(obj: string | Array<string>): Array<string> {
    if (!obj) return undefined;
    if (Array.isArray(obj)) return obj;
    return [obj];
  }

  /** { a: ['b', 'c'], d: 'e'} -> { a: ['b', 'c'], d: ['e']} */
  static queryParamsToArrayObject(params: {
    [key: string]: string | Array<string>;
  }): { [key: string]: Array<string> } {
    if (!params) return null;
    const obj = {};
    for (const key of Object.keys(params)) {
      obj[key] = Utility.toStringArray(params[key]);
    }
    return obj;
  }

  /** ['', false, undefined, null] -> true */
  static containsOnlyFalsyValues(arr: Array<unknown>): boolean {
    if (!arr) return true;
    if (!Array.isArray(arr)) return true;
    for (const el of arr) {
      if (el) return false;
    }
    return true;
  }

  /** https://stackoverflow.com/a/46151626/2725515 */
  static colorIsDark(hexcolor: string): boolean {
    if (!hexcolor?.length || hexcolor.length !== 7) return undefined;

    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 190;
  }

  /** {} -> true, { a: false, b: '', c: { d: '' }, e: [0] } -> true, { z: 0 } -> false */
  static objectIsEmpty(obj: unknown): boolean {
    if (!obj || obj.constructor !== Object) return true;
    const entries = Object.entries(obj);
    if (!entries?.length) return true;
    for (const entry of entries) {
      const entryValue = entry[1];

      if (
        Array.isArray(entryValue) &&
        !Utility.containsOnlyFalsyValues(entryValue)
      )
        return false;

      if (typeof entryValue === 'object' && !Utility.objectIsEmpty(entryValue))
        return false;

      if (entryValue === 0 || !!entryValue) return false;
    }
    return true;
  }

  static isPrerender(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
  }

  static hash(input: string): string {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash;
    }
    return Math.abs(hash).toString(16);
  }

  static plural(str: string): string {
    return `${str}s`;
  }

  static isString(s: unknown): boolean {
    return typeof s === 'string' || s instanceof String;
  }

  static isEmpty(o: unknown): boolean {
    return (
      o &&
      Object.keys(o).length === 0 &&
      Object.getPrototypeOf(o) === Object.prototype
    );
  }

  static containsFilterOptionKeys(obj: unknown): boolean {
    if (!obj) return false;
    return Object.keys(obj).some(key => FilterOptionKeys.includes(key));
  }

  static isExternalLink(link: string): boolean {
    return link.includes('http');
  }

  static routeMatches(url: string, routes: RoutesWithPathMatch): boolean {
    for (const route of routes) {
      if (route.pathMatch === 'full' && url === route.path) return true;
      if (route.pathMatch === 'prefix' && url.startsWith(route.path))
        return true;
    }
    return false;
  }

  /**  https://yogaeasy.de/artikel/lorem -> artikel/lorem
   *   /artikel/ipsum -> /artikel/ipsum
   */
  static getRelativePath(absoluteUrl: string): string {
    try {
      const url = new URL(absoluteUrl);
      return url?.pathname?.replace(/^\//g, '');
    } catch (e) {
      console.error(`${e}: ${absoluteUrl}`);
      return absoluteUrl;
    }
  }

  /**  { lorem: ['123', '456'], ipsum: '789' } -> { lorem: [123, 456], ipsum: [789] }
   */
  static convertToNumberArrays(obj: { [key: string]: string | string[] }): {
    [key: string]: number[];
  } {
    if (!obj) return null;
    const result = {};
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (Array.isArray(value)) {
        result[key] = value.map(v => parseInt(v, 10));
      } else {
        result[key] = [parseInt(value, 10)];
      }
    }
    return result;
  }

  /**  { lorem: [123, 456], ipsum: [789] } -> { lorem: "123, 456", ipsum: "789" }
   */
  static convertToCommaSeparatedStrings(obj: {
    [key: string]: number[] | string;
  }): {
    [key: string]: string;
  } {
    if (!obj) return null;
    const result = {};
    for (const key of Object.keys(obj)) {
      if (typeof obj[key] === 'string') {
        result[key] = [obj[key]];
      } else {
        result[key] = (obj[key] as number[]).join(',');
      }
    }
    return result;
  }

  static arraysEqual(A: Array<unknown>, B: Array<unknown>) {
    if (!A || !B) return false;
    return A.length === B.length && A.every(a => B.some(b => a === b));
  }

  static moveElementInArray(
    arr: Array<unknown>,
    fromIndex: number,
    toIndex: number,
  ) {
    if (!arr || !arr.length) return;
    if (fromIndex < 0 || fromIndex >= arr.length) return;
    if (toIndex < 0 || toIndex >= arr.length) return;
    if (fromIndex === toIndex) return;
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  static parseFormattedDate(formattedDate: string): Date | null {
    const [_, day, monthName] = formattedDate.split(' ');

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const month = monthNames.indexOf(monthName);

    if (month === -1) return null;

    const year = new Date().getFullYear();
    return new Date(year, month, parseInt(day, 10));
  }

  static areDatesSame(scheduled_at: string, formattedDate: string): boolean {
    const scheduledDate = new Date(scheduled_at);
    const scheduledMonth = scheduledDate.getMonth();
    const scheduledDay = scheduledDate.getDate();

    const formattedDateObj = Utility.parseFormattedDate(formattedDate);
    const formattedMonth = formattedDateObj.getMonth();
    const formattedDay = formattedDateObj.getDate();

    return scheduledMonth === formattedMonth && scheduledDay === formattedDay;
  }

  static getFormattedTime(scheduled_at: string): string {
    const date = new Date(scheduled_at);
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  static calculateEndTime(scheduled_at: string, duration: string): string {
    const startDate = new Date(scheduled_at);
    const durationInMinutes = parseInt(duration, 10);

    const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);
    const endTime = endDate.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return endTime;
  }
}
