<div class="bookmarks-list-page-header is-flex">
  <a routerLink="/meine-videos/meine-listen" class="caret-left">
    <svg-arrow-back></svg-arrow-back>
  </a>
  <h3 *ngIf="bookmarksList?.title" class="headline">{{ bookmarksList?.title }} ({{ bookmarksList?.items?.length }})</h3>
  <app-icon-button
    [activated]="buttonActivated$ | async"
    [ngbPopover]="listMenu"
    placement="bottom-right bottom-start"
    triggers="manual"
    #popover="ngbPopover"
    (onClick)="openListMenu(popover)"
    (shown)="popoverShown()"
    (hidden)="popoverHidden()"
  >
    <svg-edit></svg-edit>
  </app-icon-button>
</div>

<!-- TODO: refactor in generic component -->
<ng-template #listMenu>
  <app-edit-bookmarks-list-menu [listId]="bookmarksList?.id"></app-edit-bookmarks-list-menu>
</ng-template>
