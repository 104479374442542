<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14.9644 7.10718H20.7144V8.75003H14.9644V7.10718ZM14.9644 11.2143H20.7144V12.8572H14.9644V11.2143ZM14.9644 15.3215H20.7144V16.9643H14.9644V15.3215Z"
    fill="currentColor"
  />
  <path
    d="M22.3571 3H2.64286C2.20728 3.00043 1.78966 3.17366 1.48166 3.48166C1.17366 3.78966 1.00043 4.20728 1 4.64286V19.4286C1.00043 19.8642 1.17366 20.2818 1.48166 20.5898C1.78966 20.8978 2.20728 21.071 2.64286 21.0714H22.3571C22.7927 21.0708 23.2101 20.8975 23.5181 20.5895C23.8261 20.2816 23.9993 19.8641 24 19.4286V4.64286C23.9996 4.20728 23.8263 3.78966 23.5183 3.48166C23.2103 3.17366 22.7927 3.00043 22.3571 3ZM2.64286 4.64286H11.6786V19.4286H2.64286V4.64286ZM13.3214 19.4286V4.64286H22.3571L22.3588 19.4286H13.3214Z"
    fill="currentColor"
  />
</svg>
