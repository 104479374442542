import { Images } from './images';

// FIXME: this is a mess!!
export class ArticleTeaser {
  id: string;
  slug: string;
  images: Images;
  title: string;
  long_teaser: string;
  authors: [
    {
      name: string;
      avatar_thumb_url: string;
      short_bio: string;
    },
  ];
  comment_count: number;
  tags: [
    {
      name: string;
      slug: string;
    },
  ];
  path: string;
  image_url?: string;

  // biome-ignore lint/suspicious/noExplicitAny: FIXME: refactor this class, the constructor is BS
  constructor(jsonResponse: any) {
    Object.assign(this, jsonResponse);
  }

  getTeaserTitle(): string {
    return this.title;
  }

  getTeaserImage(): string {
    // biome-ignore lint/complexity/useLiteralKeys: FIXME: property is not defined in Images Class
    return this.images['i370_208'];
  }

  getTeaserUrl(): string {
    return `/artikel/${this.slug}`;
  }

  getTeaserHeadline(): string {
    if (this.tags?.length) {
      return this.tags[0].name;
    } else {
      return null;
    }
  }

  getTeaserHeadlineUrl(): string {
    if (this.tags?.length) {
      return `/artikel-tags/${this.tags[0].slug}`;
    } else {
      return null;
    }
  }

  getTeaserDescription(): string {
    return this.title;
  }

  getClassName(): string {
    return 'article-teaser';
  }
}
