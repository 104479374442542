export namespace LiveClassesActions {
  export class FetchAll {
    static readonly type = '[Live classes] Fetch all live classes';
  }

  export class RequestLiveClassLink {
    static readonly type = '[Live classes] Request live class link';
    constructor(public id: number) {}
  }
}
