<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M19.5 22.5H18V20.25C17.9988 19.2558 17.6033 18.3027 16.9003 17.5997C16.1973 16.8967 15.2442 16.5012 14.25 16.5H9.75C8.7558 16.5012 7.80267 16.8967 7.09966 17.5997C6.39666 18.3027 6.00119 19.2558 6 20.25V22.5H4.5V20.25C4.50159 18.8581 5.05522 17.5237 6.03944 16.5394C7.02367 15.5552 8.3581 15.0016 9.75 15H14.25C15.6419 15.0016 16.9763 15.5552 17.9606 16.5394C18.9448 17.5237 19.4984 18.8581 19.5 20.25V22.5ZM3.75 4.5C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V12H4.5V5.25C4.5 5.05109 4.42098 4.86032 4.28033 4.71967C4.13968 4.57902 3.94891 4.5 3.75 4.5Z"
    fill="currentColor"
  />
  <path
    d="M3 1.5V3H6.75V8.25C6.75 9.64239 7.30312 10.9777 8.28769 11.9623C9.27226 12.9469 10.6076 13.5 12 13.5C13.3924 13.5 14.7277 12.9469 15.7123 11.9623C16.6969 10.9777 17.25 9.64239 17.25 8.25V3H21V1.5H3ZM8.25 3H15.75V5.25H8.25V3ZM12 12C11.0054 12 10.0516 11.6049 9.34835 10.9017C8.64509 10.1984 8.25 9.24456 8.25 8.25V6.75H15.75V8.25C15.75 9.24456 15.3549 10.1984 14.6517 10.9017C13.9484 11.6049 12.9946 12 12 12Z"
    fill="currentColor"
  />
</svg>
