import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/api/models/user';
import { CoreActions } from 'src/app/core/core.actions';

interface Tab {
  id: string;
  link: string;
  label: string;
  exact?: boolean;
}

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.sass'],
})
export class TabNavigationComponent implements AfterViewInit, OnDestroy {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.router.state.url) url$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private ulEl: HTMLElement;
  private activeBarEl: HTMLElement;
  private tabEls: NodeList;
  public tabList: Array<Tab> = [
    { id: 'discover-tab', link: '/', label: 'Entdecken', exact: true },
    {
      id: 'my-programs-tab',
      link: '/meine-programme',
      label: 'Meine Programme',
    },
    { id: 'my-videos-tab', link: '/meine-videos', label: 'Meine Videos' },
  ];

  constructor(
    private store: Store,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    const nativeEl = this.elRef.nativeElement;
    this.ulEl = nativeEl.querySelector('.ye-tabs');
    this.activeBarEl = nativeEl.querySelector('#active-bar');
    this.tabEls = nativeEl.querySelectorAll('.ye-tab');
    this.url$.pipe(takeUntil(this.destroy$)).subscribe(url => {
      setTimeout(() => this.selectActiveTab(url), 1000); // wait for DOM to rendering
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.selectActiveTab();
  }

  selectActiveTab(url?: string) {
    if (!url) {
      url = this.store.selectSnapshot(state => state.router.state.url);
    }

    const activeTabId = this.getActiveTabId(url);
    const activeTabEl = this.getActiveTabEl(activeTabId);
    this.setActiveClassOnActiveTab(activeTabEl);

    const offsetLeft = this.moveActiveBarToActiveTab(activeTabEl);
    this.scrollToActiveTab(offsetLeft);
  }

  private getActiveTabId(url: string): string {
    if (!url) return undefined;
    const activeTab = this.tabList.find(tab => {
      if (tab.exact) {
        return url.split('?')[0] === tab.link;
        // biome-ignore lint/style/noUselessElse: <explanation>
      } else {
        return url.startsWith(tab.link);
      }
    });
    return activeTab?.id;
  }

  private getActiveTabEl(activeTabId: string): HTMLElement {
    if (!activeTabId) return undefined;

    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let activeTabEl;
    // biome-ignore lint/complexity/noForEach: <explanation>
    this.tabEls.forEach(tabEl => {
      if ((tabEl as HTMLElement).id === activeTabId) {
        activeTabEl = tabEl;
      }
    });
    return activeTabEl;
  }

  private setActiveClassOnActiveTab(activeTabEl: HTMLElement) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    this.tabEls.forEach(tabEl => this.renderer.removeClass(tabEl, 'active'));

    if (!activeTabEl) return;
    this.renderer.addClass(activeTabEl, 'active');
  }

  private moveActiveBarToActiveTab(activeTabEl: HTMLElement): number {
    if (!activeTabEl) return;

    let width = 60;
    const offsetLeft = activeTabEl.offsetLeft;
    if (document.documentElement.offsetWidth < 730) {
      width = 50;
    }
    const left = offsetLeft + (activeTabEl.offsetWidth - width) / 2;
    const leftPx = `${left}px`;

    this.renderer.setStyle(this.activeBarEl, 'left', leftPx);
    this.renderer.setStyle(this.activeBarEl, 'width', `${width}px`);

    return offsetLeft;
  }

  private scrollToActiveTab(offsetLeft: number) {
    if (!offsetLeft && offsetLeft !== 0) return;
    this.ulEl.scrollTo({
      left: offsetLeft,
      behavior: 'smooth',
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
