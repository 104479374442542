<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="12" r="10.25" stroke="currentColor" stroke-width="1.5" />
  <path
    d="M15.9831 7.41141C16.1505 7.24405 16.4218 7.24405 16.5892 7.41141C16.7413 7.56357 16.7552 7.80166 16.6307 7.96944L16.5892 8.01751L8.01775 16.5889C7.85038 16.7563 7.57903 16.7563 7.41166 16.5889C7.25951 16.4368 7.24567 16.1987 7.37016 16.0309L7.41166 15.9828L15.9831 7.41141Z"
    fill="currentColor"
    stroke="currentColor"
    stroke-width="0.4"
  />
  <path
    d="M7.41166 7.41141C7.56381 7.25926 7.8019 7.24543 7.96968 7.36992L8.01775 7.41141L16.5892 15.9828C16.7565 16.1502 16.7565 16.4216 16.5892 16.5889C16.437 16.7411 16.1989 16.7549 16.0312 16.6304L15.9831 16.5889L7.41166 8.01751C7.24429 7.85014 7.24429 7.57878 7.41166 7.41141Z"
    fill="currentColor"
    stroke="currentColor"
    stroke-width="0.4"
  />
</svg>
