<div class="join-program-success-modal-content">
  <img src="https://cdn.yogaeasy.de/production/uploads/components/join-program-success-modal-content/hp_corner_left.png"
    class="upper-left-corner" />
  <img src="https://cdn.yogaeasy.de/production/uploads/components/join-program-success-modal-content/hp_corner_right.png"
    class="lower-right-corner" />
  <div class="content">
    <h3 class="section-title" i18n>Programm-Anmeldung erfolgreich!</h3>
    <div class="raw content" [innerHTML]="text"></div>
    <a class="attend-button" title="Zur Tagesübersicht gehen" [href]="link" i18n>Zur Tagesübersicht gehen</a>
  </div>
</div>