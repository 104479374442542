import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { CoreActions } from 'src/app/core/core.actions';
import { UserActions } from 'src/app/user/user.actions';

@Component({
  selector: 'app-bookmarks-menu-list-item-favorite',
  templateUrl: './bookmarks-menu-list-item-favorite.component.html',
  styleUrls: ['./bookmarks-menu-list-item-favorite.component.sass'],
})
export class BookmarksMenuListItemFavoriteComponent {
  @Input() videoRef: VideoTeaser;

  constructor(private store: Store) {}

  toggleFavorite() {
    // since passed by Reference, this will update the teaser's icon too
    this.videoRef.is_favorite = !this.videoRef.is_favorite;
    this.store
      .dispatch(
        new UserActions.SetVideoFavorite(
          this.videoRef.id,
          this.videoRef.is_favorite,
        ),
      )
      .subscribe({
        next: () => this.showSuccessMessage(this.videoRef.is_favorite),
        error: () => {
          this.showErrorMessage();
          this.videoRef.is_favorite = !this.videoRef.is_favorite;
        },
      });
  }

  showSuccessMessage(isFavorite: boolean) {
    if (isFavorite) {
      this.store.dispatch(
        new CoreActions.ShowToast({
          text: $localize`Video wurde in Favoriten gespeichert`,
          icon: 'heart',
          color: 'success',
          autohide: true,
        }),
      );
    } else {
      this.store.dispatch(
        new CoreActions.ShowToast({
          text: $localize`Änderungen wurden gespeichert`,
          icon: 'checkmark',
          color: 'success',
          autohide: true,
        }),
      );
    }
  }

  showErrorMessage() {
    this.store.dispatch(
      new CoreActions.ShowToast({
        text: $localize`Speichern fehlgeschlagen.<br>Versuche es später noch einmal.`,
        icon: 'warning',
        color: 'error',
        autohide: true,
      }),
    );
  }
}
