import { Component, Inject, LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-live-section',
  templateUrl: './live-section.component.html',
  styleUrls: ['./live-section.component.sass'],
})
export class LiveSectionComponent {
  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
