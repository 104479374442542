<section>
  <owl-carousel-o class="has-dots-inside has-small-dots" [options]="autoplay">
    <ng-template carouselSlide>
      <app-carousel-slide [minHeight]="240">
        <svg-asana-warrior-pictogram></svg-asana-warrior-pictogram>
        <h2 class="is-size-4 has-text-deep-sea-green has-text-weight-bold has-text-centered">
          <ng-container i18n>Die beliebtesten Yogastile</ng-container>
        </h2>
        <span class="is-size-5-and-half has-text-kokoda has-text-centered carousel-slide-text">
          <ng-container i18n>Wähle aus mehr als zehn Yogastilen das Passende für dich.</ng-container>
        </span>
      </app-carousel-slide>
    </ng-template>
    <ng-template carouselSlide>
      <app-carousel-slide [minHeight]="240">
        <svg-video-updates-pictogram></svg-video-updates-pictogram>
        <h2 class="is-size-4 has-text-deep-sea-green has-text-weight-bold has-text-centered">
          <ng-container i18n>Wöchentlich neue Videos</ng-container>
        </h2>
        <span class="is-size-5-and-half has-text-kokoda has-text-centered carousel-slide-text">
          <ng-container i18n>Jede Woche neue Videos und nie aus der Übung kommen.</ng-container>
        </span>
      </app-carousel-slide>
    </ng-template>
    <ng-template carouselSlide>
      <app-carousel-slide [minHeight]="240">
        <svg-om-pictogram></svg-om-pictogram>
        <h2 class="is-size-4 has-text-deep-sea-green has-text-weight-bold has-text-centered">
          <ng-container i18n>Unverbindlich testen</ng-container>
        </h2>
        <div class="is-flex is-align-items-start">

          <!-- ausgezeichnet badge is visible only in German version -->
          <img *ngIf="locale === 'de'" src="https://cdn.yogaeasy.de/production/uploads/components/free-trial-head-section/ausgezeichnet-badge.png"
            alt="Ausgezeichnet Badge" class="is-hidden-touch is-width-99px is-height-90px mr-4" width="90" height="99" />

          <span class="is-size-5-and-half has-text-kokoda has-text-centered carousel-slide-text">
            <ng-container i18n>Keine Kündigung notwendig. Dein Testzeitraum endet automatisch.</ng-container>
          </span>
        </div>
      </app-carousel-slide>
    </ng-template>
  </owl-carousel-o>
</section>
