import { FilterId } from '../api/models/filter-id';
import { FilterParams } from '../api/models/filter-params';
import { MobileFilterScreenState } from './filter.state';

export namespace FilterActions {
  export class FetchFilteredList {
    static readonly type = '[Filter] Fetch filtered list';
    constructor(public limit?: number, public page?: number) {}
  }

  export class FetchOptions {
    static readonly type = '[Filter] Fetch options';
  }

  export class NavigateToFilterUrl {
    static readonly type = '[Filter] Navigate to filter URL';
  }

  export class Reset {
    static readonly type = '[Filter] Reset';
    constructor(public filterId: FilterId) {}
  }

  export class ResetAll {
    static readonly type = '[Filter] Reset all';
  }

  export class SetAllParams {
    static readonly type = '[Filter] Set all params';
    constructor(public params: FilterParams) {}
  }

  export class SetParam {
    static readonly type = '[Filter] Set param';
    constructor(
      public filterId: FilterId,
      public optionId: number,
      public selected: boolean,
    ) {}
  }

  export class SetSortBy {
    static readonly type = '[Filter] Set SortBy';
    constructor(public key: string) {}
  }

  export class SetTooltipOpen {
    static readonly type = '[Filter] Set tooltip open';
    constructor(public open: boolean) {}
  }

  export class SetMobileFilterState {
    static readonly type = '[Filter] Set mobile filter state';
    constructor(public mobileFilterState: MobileFilterScreenState) {}
  }
}
