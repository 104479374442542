import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Utility } from 'src/app/shared/utility';
import { environment } from 'src/environments/environment';
import { FilterOptionsResponse } from '../models/filter-options-response';
import { FilterParams } from '../models/filter-params';
import { VideoTeasersSlider } from '../models/video-teasers-slider';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private httpClient: HttpClient) {}
  path = `${environment.apiPath}/v1/`;

  getVideos(
    filterParams: FilterParams,
    sortBy: string,
    limit?: number,
    page?: number,
  ): Observable<VideoTeasersSlider> {
    const commaSeperated = Utility.convertToCommaSeparatedStrings(filterParams);
    const params = Utility.removeFalsyPropertiesFromHttpParams({
      ...commaSeperated,
      sort_by: sortBy,
      limit,
      page,
    });
    return this.httpClient
      .get<VideoTeasersSlider>(`${this.path}videos`, {
        params,
        observe: 'response',
      })
      .pipe(
        catchError(this.handleError),
        map(response => {
          if (response.status === 204) {
            return { videos_count: 0, videos: [] };
          }
          return response.body;
        }),
      );
  }

  getOptions(filterParams?: FilterParams): Observable<FilterOptionsResponse> {
    const commaSeperated = Utility.convertToCommaSeparatedStrings(filterParams);
    const params = new HttpParams({ fromObject: commaSeperated });
    return this.httpClient
      .get<FilterOptionsResponse>(`${this.path}filter_options`, { params })
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.error.message}`;
    }

    return throwError(errorMessage);
  }
}
