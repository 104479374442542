import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreActions } from 'src/app/core/core.actions';
import { CreateBookmarksListModalComponent } from '../create-bookmarks-list-modal/create-bookmarks-list-modal.component';

@Component({
  selector: 'app-add-bookmarks-list-teaser',
  templateUrl: './add-bookmarks-list-teaser.component.html',
  styleUrls: ['./add-bookmarks-list-teaser.component.sass'],
})
export class AddBookmarksListTeaserComponent {
  constructor(private store: Store) {}

  openModal() {
    this.store.dispatch(
      new CoreActions.OpenModal(CreateBookmarksListModalComponent.MODAL_ID, CreateBookmarksListModalComponent, {
        windowClass: 'create-bookmarks-list-modal-window',
      }),
    );
  }
}
