import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreActions } from '../core.actions';

@Injectable({
  providedIn: 'root',
})
export class SectionIdService implements OnDestroy {
  private urlPrefixToSectionIdMap = [
    { urlPrefix: '/video-kategorien', sectionId: 'videos' },
    { urlPrefix: '/video-kategorie', sectionId: 'videos' },
    { urlPrefix: '/programme', sectionId: 'programs' },
    { urlPrefix: '/yogalehrer', sectionId: 'teachers' },
    { urlPrefix: '/yogastile', sectionId: 'styles' },
    { urlPrefix: '/events', sectionId: 'events' },
    { urlPrefix: '/magazin', sectionId: 'blogs' },
    { urlPrefix: '/artikel', sectionId: 'blogs' },
  ];

  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private store: Store) {}

  subscribeToUrlChanges(): void {
    this.store
      .select((state) => state.router)
      .pipe(takeUntil(this.destroy$))
      .subscribe((router) => {
        const url = router?.state?.url;

        if (url === undefined) return undefined;

        var rootPaths: Array<string> = [
          '',
          '/',
          '/meine-programme',
          '/meine-videos',
        ];

        if (rootPaths.indexOf(url) > -1) {
          this.store.dispatch(new CoreActions.SetSectionId('root'));
        } else {
          const element = this.urlPrefixToSectionIdMap.find((el) =>
            url.startsWith(el.urlPrefix),
          );
          this.store.dispatch(new CoreActions.SetSectionId(element?.sectionId));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
