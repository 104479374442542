import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Announcement } from '../models/announcement';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  path = environment.apiPath + '/v1/announcement/';

  constructor(private httpClient: HttpClient) {}

  getAnnouncement(): Observable<Announcement> {
    return this.httpClient.get<Announcement>(this.path).pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = 'Error occurred ' + err.error.message;
    } else {
      errorMessage = 'System Error occurred ' + err.error.message;
    }
    return throwError(errorMessage);
  }
}
