import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreActions } from '../core.actions';
import { BottomsheetService } from './bottomsheet.service';

@Component({
  selector: 'app-bottomsheet',
  templateUrl: './bottomsheet.component.html',
  styleUrls: ['./bottomsheet.component.sass'],
})
export class BottomsheetComponent implements OnInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('content', { read: ViewContainerRef })
  container!: ViewContainerRef;
  shown = true;
  showCloseIcon: boolean;

  constructor(
    private store: Store,
    private componentFactoryResolver: ComponentFactoryResolver,
    private bottomsheetService: BottomsheetService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(state => state.core?.bottomsheetOpen)
      .pipe(takeUntil(this.destroy$))
      .subscribe(bottomsheetOpen => {
        if (bottomsheetOpen) {
          this.container.clear();
          const dynamicComponentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
              this.bottomsheetService.component,
            );

          const componentRef = this.container.createComponent(
            dynamicComponentFactory,
          );
          const inputs = this.bottomsheetService.inputs;
          if (inputs) {
            // biome-ignore lint/complexity/noForEach: <explanation>
            Object.keys(inputs).forEach(
              key => (componentRef.instance[key] = inputs[key]),
            );
          }

          this.showCloseIcon = this.bottomsheetService.showCloseIcon;
          this.shown = true;
        } else {
          this.shown = false;
        }
      });
  }

  close() {
    this.store.dispatch(new CoreActions.SetBottomsheet(null));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
