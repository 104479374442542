import { Component } from '@angular/core';

@Component({
  selector: 'app-new-videos',
  template: `<app-show-more-page
    type="video"
    subPath="new_videos"
    categorySlug="Neue"
    headline="Neue Videos"
    subheadline="Schau dir das Neuste vom Neuen an"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class NewVideosComponent {}
