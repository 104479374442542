import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HeartFilledSvg } from 'src/app/svg/heart-filled/heart-filled.svg';

@Component({
  selector: 'app-bookmarks-list-favorite-teaser',
  standalone: true,
  imports:  [CommonModule, HeartFilledSvg],
  templateUrl: './bookmarks-list-favorite-teaser.component.html',
  styleUrls: ['./bookmarks-list-favorite-teaser.component.sass'],
})
export class BookmarksListFavoriteTeaserComponent {
  @Input() itemsCount: number;
}
