<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    opacity="0.4"
    d="M14.7996 19.2198L13.2031 20.8406L12.3742 21.7286L12.3513 21.7053L12.3302 21.7268L11.4454 20.7857L3.61059 12.8316C2.57935 11.7847 2 10.3647 2 8.88411C2 7.4035 2.57935 5.98353 3.61059 4.93658L3.90745 4.6352C4.41807 4.1168 5.02427 3.70558 5.69143 3.42503C6.3586 3.14447 7.07366 3.00007 7.79579 3.00007C8.51792 3.00007 9.23298 3.14447 9.90015 3.42503C10.5673 3.70558 11.1735 4.1168 11.6841 4.6352L11.6884 4.63957L12.3492 5.32283L13.0156 4.63808C13.5261 4.11905 14.1323 3.70723 14.7996 3.42616C15.467 3.14509 16.1823 3.00028 16.9048 3C17.6273 2.99972 18.3427 3.14399 19.0102 3.42455C19.6778 3.70511 20.2843 4.11647 20.7952 4.63512L21.092 4.93645C22.1217 5.98423 22.7 7.40389 22.7 8.88398C22.7 10.3641 22.1217 11.7837 21.092 12.8315L20.1692 13.7684"
    stroke="#555547"
    stroke-width="2.4"
  />
  <path
    opacity="0.4"
    d="M19.7498 3.44301C20.4464 3.73577 21.0793 4.16501 21.6123 4.70621L21.9221 5.02064C22.9966 6.11398 23.6 7.59536 23.6 9.13981C23.6 10.6842 22.9966 12.1656 21.9221 13.259L20.9592 14.2366L15.3561 19.925L13.6902 21.6163L12.8252 22.5429L12.8013 22.5186L12.7793 22.541L11.8561 21.559L3.68062 13.2591C2.60454 12.1666 2 10.6849 2 9.13994C2 7.59496 2.60454 6.11325 3.68062 5.02078L3.99038 4.7063C4.5232 4.16536 5.15576 3.73626 5.85193 3.4435C6.5481 3.15075 7.29425 3.00007 8.04778 3.00007C8.80131 3.00007 9.54746 3.15075 10.2436 3.4435C10.9398 3.73626 11.5724 4.16536 12.1052 4.7063L12.1097 4.71085L12.7992 5.42382L13.4945 4.7093C14.0272 4.1677 14.6598 3.73798 15.3561 3.44469C16.0525 3.15139 16.7989 3.00029 17.5528 3C18.3067 2.99971 19.0533 3.15025 19.7498 3.44301Z"
    fill="white"
  />
  <path
    d="M14.7996 19.2198L13.2031 20.8406L12.3742 21.7286L12.3513 21.7053L12.3302 21.7268L11.4454 20.7857L3.61059 12.8316C2.57935 11.7847 2 10.3647 2 8.88411C2 7.4035 2.57935 5.98353 3.61059 4.93658L3.90745 4.6352C4.41807 4.1168 5.02427 3.70558 5.69143 3.42503C6.3586 3.14447 7.07366 3.00007 7.79579 3.00007C8.51792 3.00007 9.23298 3.14447 9.90015 3.42503C10.5673 3.70558 11.1735 4.1168 11.6841 4.6352L11.6884 4.63957L12.3492 5.32283L13.0156 4.63808C13.5261 4.11905 14.1323 3.70723 14.7996 3.42616C15.467 3.14509 16.1823 3.00028 16.9048 3C17.6273 2.99972 18.3427 3.14399 19.0102 3.42455C19.6778 3.70511 20.2843 4.11647 20.7952 4.63512L21.092 4.93645C22.1217 5.98423 22.7 7.40389 22.7 8.88398C22.7 10.3641 22.1217 11.7837 21.092 12.8315L20.1692 13.7684"
    stroke="currentColor"
    stroke-width="1.9"
  />
  <path d="M21.3334 17H14.6667" stroke="#989892" stroke-width="2" stroke-linecap="round" />
  <path d="M18.0002 20.3334V13.6667" stroke="#989892" stroke-width="2" stroke-linecap="round" />
  <path d="M21.3334 17H14.6667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
  <path d="M18.0002 20.3334V13.6667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
</svg>
