import { Component } from '@angular/core';

@Component({
  selector: 'app-meditation-videos',
  template: `<app-show-more-page
    type="video"
    subPath="popular_meditation_videos"
    categorySlug="Meditationen"
    headline="Die beliebtesten Meditationen"
    subheadline="Finde die Ruhe in dir selbst"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class MeditationVideosComponent {}
