import { Injectable, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CoreActions } from '../core.actions';

@Injectable({
  providedIn: 'root',
})
export class ContentOnlyService implements OnDestroy {
  private readonly CONTENT_ONLY_PAGES: Array<RegExp> = [
    /(\/liste)?\/newsletter\/[A-Za-z0-9-_=]*\/anmelden[/]?$/,
    /(\/liste)?\/newsletter\/[A-Za-z0-9-_=]*\/abmelden[/]?$/,
    /(\/liste)?\/newsletter\/[A-Za-z0-9-_=]*[/]?$/,
    /(\/liste)?\/newsletter[/]?$/,
  ];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store) {}

  subscribeToUrlChanges() {
    this.store
      .select(state => state.router.state)
      .pipe(
        takeUntil(this.destroy$),
        filter(routerState => !!routerState),
      )
      .subscribe(({ url, queryParams }) => {
        this.store.dispatch(new CoreActions.SetContentOnly(this.hasInApp(queryParams) || this.isContentOnlyPage(url)));
      });
  }

  private hasInApp(queryParams: Params): boolean {
    return queryParams && queryParams['in_app'] !== undefined; // can be ''
  }

  private isContentOnlyPage(url: string): boolean {
    const trimmedUrl = url.split('?')[0].split('#')[0];
    for (const regex of this.CONTENT_ONLY_PAGES) {
      if (regex.test(trimmedUrl)) return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
