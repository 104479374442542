import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { CoreActions } from 'src/app/core/core.actions';
import { DashboardActions } from '../../dashboard.actions';

@Component({
  selector: 'app-my-videos-page',
  templateUrl: './my-videos-page.component.html',
  styleUrls: ['./my-videos-page.component.sass'],
})
export class MyVideosPageComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  page = 'My-Videos';

  bookmarksLists: Array<BookmarksList>;
  lastViewed: Array<VideoTeaser>;
  favorites: Array<VideoTeaser>;
  favoritesVideoCount: number;
  ratedGood: Array<VideoTeaser>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(state => state.bookmarks.bookmarksLists)
      .pipe(takeUntil(this.destroy$))
      .subscribe(bookmarksLists => {
        if (bookmarksLists?.length) {
          // FIXME: would be nice to only get the number here, not all teasers
          this.store.dispatch(
            new DashboardActions.FetchVideoTeasers('favorites'),
          );
        } else {
          this.store.dispatch(
            new DashboardActions.FetchVideoTeasers('favorites', 7),
          );
        }
        this.bookmarksLists = bookmarksLists;
      });

    this.store
      .select(state => state.dashboard.favoritesVideoCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        favoritesVideoCount => (this.favoritesVideoCount = favoritesVideoCount),
      );

    this.store
      .select(state => state.dashboard.favoritesVideoTeasers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(videoTeasers => (this.favorites = videoTeasers));

    this.store.dispatch(
      new DashboardActions.FetchVideoTeasers('last_viewed_videos', 7),
    );
    this.store
      .select(state => state.dashboard.lastViewedVideoTeasers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(videoTeasers => (this.lastViewed = videoTeasers));

    this.store.dispatch(
      new DashboardActions.FetchVideoTeasers('rated_good_videos', 7),
    );
    this.store
      .select(state => state.dashboard.ratedGoodVideoTeasers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(videoTeasers => (this.ratedGood = videoTeasers));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
