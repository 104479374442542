<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>Finde Verbindung in Live-Klassen und -Workshops</ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
    <ng-container i18n>
      Jede Woche kannst du in bis zu 7 Live-Klassen gemeinsam mit deinen Lieblingslehrer:innen und der YogaEasy-Community die Matte
      ausrollen.
      In Premium-Live-Workshops kannst du in besondere Themen zusätzlich tiefer einsteigen.
    </ng-container>
  </div>
  @if(locale==='de'){
  <div class="is-position-relative is-flex is-justify-content-center">
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-mobile-DE.jpg" ngSrcset="343w, 686w" width="343"
      height="480" alt="Live Classes Banner" class="img-mobile has-border-radius-5" />
    <!-- without width: 100% on tablet and up Safari won't show the full resolution -->
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-tablet-DE.jpg" ngSrcset="730w, 1460w" width="730"
      height="320" alt="Live Classes Banner" class="img-tablet-only is-width-100 has-border-radius-5" />
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-desktop-DE.jpg" ngSrcset="1170w, 2340w" width="1170"
      height="234" alt="Live Classes Banner" class="img-desktop has-border-radius-5" />
    <a href="/live-klassen"
      class="button is-primary is-outlined is-rounded is-medium has-text-weight-semibold is-position-absolute is-centered-horizontally-mobile is-bottom-32px-mobile is-bottom-36px-tablet is-right-72px-tablet is-right-142px-desktop px-6">Zur
      Anmeldung</a>
  </div>
  } @else if(locale==='en'){
  <div class="is-position-relative is-flex is-justify-content-center">
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-mobile-EN.jpg" ngSrcset="343w, 686w" width="343"
      height="480" alt="Live Classes Banner" class="img-mobile has-border-radius-5" />
    <!-- without width: 100% on tablet and up Safari won't show the full resolution -->
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-tablet-EN.jpg" ngSrcset="730w, 1460w" width="730"
      height="320" alt="Live Classes Banner" class="img-tablet-only is-width-100 has-border-radius-5" />
    <img ngSrc="production/uploads/components/homepage/live-section/Live-classes-banner-desktop-EN.jpg" ngSrcset="1170w, 2340w" width="1170"
      height="234" alt="Live Classes Banner" class="img-desktop has-border-radius-5" />
    <a href="/live-classes"
      class="button is-primary is-outlined is-rounded is-medium has-text-weight-semibold is-position-absolute is-centered-horizontally-mobile is-bottom-32px-mobile is-bottom-36px-tablet is-right-72px-tablet is-right-142px-desktop px-6">Live
      classes schedule</a>
  </div>
  }
</section>