import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArticleTeaser } from '../api/models/article-teaser';
import { Dashboard } from '../api/models/dashboard';
import { NewsItem } from '../api/models/news-item';
import { PodcastTeaser } from '../api/models/podcast-teaser';
import { ProgramTeaser } from '../api/models/program-teaser';
import { TeacherTeaser } from '../api/models/teacher-teaser';
import { VideoTeaser } from '../api/models/video-teaser';
import { VideoTeasersSlider } from '../api/models/video-teasers-slider';
import { DashboardService } from '../api/services/dashboard.service';
import { DashboardActions } from './dashboard.actions';

export type TeaserList =
  | Array<ArticleTeaser>
  | Array<PodcastTeaser>
  | Array<ProgramTeaser>
  | Array<TeacherTeaser>
  | VideoTeasersSlider;

export interface DashboardStateModel {
  favoritesVideoCount: number;
  favoritesVideoTeasers: Array<VideoTeaser>;
  fullTeaserList: TeaserList;
  lastViewedVideoTeasers: Array<VideoTeaser>;
  ratedGoodVideoTeasers: Array<VideoTeaser>;
  newsItem: NewsItem;
  dashboard: Dashboard;
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    favoritesVideoCount: undefined,
    favoritesVideoTeasers: undefined,
    fullTeaserList: undefined,
    lastViewedVideoTeasers: undefined,
    newsItem: undefined,
    ratedGoodVideoTeasers: undefined,
    dashboard: undefined,
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Action(DashboardActions.FetchFullTeaserList)
  fetchFullTeaserList(
    ctx: StateContext<DashboardStateModel>,
    action: DashboardActions.FetchFullTeaserList,
  ) {
    ctx.patchState({ fullTeaserList: undefined });
    let teaserList$: Observable<TeaserList>;
    switch (action.type) {
      case 'article':
        teaserList$ = this.dashboardService.getArticleTeasers(20);
        break;
      case 'podcast':
        teaserList$ = this.dashboardService.getPodcastTeasers(20);
        break;
      case 'program':
        teaserList$ = this.dashboardService.getProgramTeasers(20);
        break;
      case 'teacher':
        teaserList$ = this.dashboardService.getTeacherTeasers(20);
        break;
      case 'video':
        teaserList$ = this.dashboardService.getVideoTeasers(
          action.subPath,
          action.limit || 20,
          action.page || 0,
        );
        break;
    }
    return teaserList$.pipe(
      tap(teasers => ctx.patchState({ fullTeaserList: teasers })),
    );
  }

  @Action(DashboardActions.FetchVideoTeasers)
  fetchVideoTeasers(
    ctx: StateContext<DashboardStateModel>,
    action: DashboardActions.FetchVideoTeasers,
  ) {
    return this.dashboardService
      .getVideoTeasers(action.category, action.limit)
      .pipe(
        tap(videoTeasersSlider => {
          if (!videoTeasersSlider?.videos) return;

          if (action.category === 'last_viewed_videos') {
            ctx.patchState({
              lastViewedVideoTeasers: videoTeasersSlider.videos,
            });
          } else if (action.category === 'favorites') {
            ctx.patchState({
              favoritesVideoTeasers: videoTeasersSlider.videos,
              favoritesVideoCount: videoTeasersSlider.videos_count,
            });
          } else if (action.category === 'rated_good_videos') {
            ctx.patchState({
              ratedGoodVideoTeasers: videoTeasersSlider.videos,
            });
          }
        }),
      );
  }

  @Action(DashboardActions.FetchDashboard)
  fetchDashboard(ctx: StateContext<DashboardStateModel>, _) {
    return this.dashboardService
      .getDashboard()
      .pipe(tap(dashboard => ctx.patchState({ dashboard })));
  }

  @Action(DashboardActions.FetchNewsItem)
  fetchNewsItem(ctx: StateContext<DashboardStateModel>, _) {
    return this.dashboardService
      .getNewsItem()
      .pipe(tap(newsItem => ctx.patchState({ newsItem })));
  }
}
