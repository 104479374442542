import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Video } from '../models/video';
import { VideoWatchedParams } from '../models/video-watched-params';

export type VideoFeedback = 1 | 2 | 3;

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  path = `${environment.apiPath}/v1/videos/`;

  constructor(private httpClient: HttpClient) {}

  getVideo(slug: string): Observable<Video> {
    return this.httpClient
      .get<Video>(this.path + slug)
      .pipe(map(res => new Video(res)));
  }

  setVideoFavorite(videoId: number, isFavorite: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    if (isFavorite) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return this.httpClient.post<any>(
        `${environment.apiPath}/v1/favorites`,
        { id: videoId },
        httpOptions,
      );
    } else {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return this.httpClient.delete<any>(
        `${environment.apiPath}/v1/favorites/${videoId}`,
      );
    }
  }

  watchedVideo(videoWatchParams: VideoWatchedParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient.post(
      `${environment.apiPath}/v1/watched_videos`,
      videoWatchParams,
      httpOptions,
    );
  }

  sendVideoFeedback(id: number, feedback: VideoFeedback) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient.post(
      `${environment.apiPath}/v1/videos/${id}/feedbacks`,
      { video_level: feedback },
      httpOptions,
    );
  }
}
