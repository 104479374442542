<section class="has-background-cararra pt-5-and-half">
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <svg-online-yoga-pictogram class="mb-4"></svg-online-yoga-pictogram>
    <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
      <ng-container i18n>
        Übe mit den besten Lehrer:innen Deutschlands
      </ng-container>
    </h2>
    <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
      <ng-container i18n>
        Lass dich von unseren erfahrenen und renommierten Yogalehrer:innen auf deiner Reise begleiten. Tauche in deinem Tempo in die Praxis
        und Philosophie des Yoga ein.
      </ng-container>
    </div>
  </div>
  <owl-carousel-o class="has-dots-inside" [options]="noAutoplay">
    <ng-container *ngFor="let teacher of teachers[locale]">
      <ng-template carouselSlide>
        <app-carousel-slide [minHeight]="360">
          <a routerLink="{{ teacher.url }}">
            <img ngSrc="{{ teacher.photo }}" alt="{{ teacher.name }}"
              class="mt-8 is-rounded-img is-width-200px has-border-on-hover has-border-on-pressed" width="200" height="200" />
          </a>
          <a routerLink="{{ teacher.url }}" class="is-underline-on-hover">
            <h3 class="is-size-5 has-text-deep-sea-green has-text-weight-bold has-text-centered mt-4">
              {{ teacher.name }}
            </h3>
          </a>
          <span class="is-size-5-and-half has-text-kokoda has-text-centered mt-4">
            {{ teacher.description }}
          </span>
        </app-carousel-slide>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>