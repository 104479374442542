<a
  class="ml-0 has-opacity-on-hover"
  title="YogaEasy bei Facebook"
  target="_blank"
  rel="noopener"
  rel="noopener"
  href="https://www.facebook.com/yogaeasy"
>
  <svg-facebook-white-circle></svg-facebook-white-circle>
</a>
<a
  class="ml-2 has-opacity-on-hover"
  title="YogaEasy auf Instagram"
  target="_blank"
  rel="noopener"
  href="https://instagram.com/yogaeasygermany/"
>
  <svg-instagram-white-circle></svg-instagram-white-circle>
</a>
<a
  class="ml-2 has-opacity-on-hover"
  title="YogaEasy auf Pinterest"
  target="_blank"
  rel="noopener"
  href="https://www.pinterest.com/yogaeasy/"
>
  <svg-pinterest-white-circle></svg-pinterest-white-circle>
</a>
<a
  class="ml-2 has-opacity-on-hover"
  title="YogaEasy auf YouTube"
  target="_blank"
  rel="noopener"
  href="http://www.youtube.com/user/yogaeasygmbh"
>
  <svg-youtube-white-circle></svg-youtube-white-circle>
</a>
