import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Action, State, StateContext } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Announcement } from '../api/models/announcement';
import { Footer } from '../api/models/footer';
import { SiteParams } from '../api/models/site-params';
import { Unit } from '../api/models/unit';
import { AnnouncementService } from '../api/services/announcement.service';
import { FooterService } from '../api/services/footer.service';
import { ProgramService } from '../api/services/program.service';
import { SeoService } from '../api/services/seo.service';
import { SiteParamsService } from '../api/services/site-params.service';
import { TrackingService } from '../api/services/tracking.service';
import { UnitService } from '../api/services/unit.service';
import { BottomsheetService } from './bottomsheet/bottomsheet.service';
import { CoreActions } from './core.actions';
import { MetaService } from './meta/meta.service';
import { JoinProgramSuccessModalContentComponent } from './modal/join-program-success-modal-content/join-program-success-modal-content.component';
import { ModalService } from './modal/modal.service';
import { ScriptService } from './script/script.service';
import { Toast } from './toast/toast.model';

export type Viewport = 'desktop' | 'tablet' | 'mobile';

export interface CoreStateModel {
  announcement: Announcement;
  bottomsheetOpen: boolean;
  contentOnly: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  leanplum_events: Array<any>;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  tracking_events: Array<any>;
  mobileNavigationOpen: boolean;
  modalsOpen: Array<string>;
  nextUrl: string;
  redirected: boolean;
  requestCount: number;
  scriptsLoaded: Array<string>;
  sectionId: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  seoData: any;
  siteParams: SiteParams;
  toasts: Array<Toast>;
  unit: Unit;
  viewport: Viewport;
  footer: Footer;
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    announcement: undefined,
    bottomsheetOpen: false,
    contentOnly: undefined,
    leanplum_events: [],
    mobileNavigationOpen: false,
    modalsOpen: [],
    nextUrl: '',
    redirected: false,
    requestCount: 0,
    scriptsLoaded: [],
    sectionId: 'root',
    seoData: undefined,
    siteParams: undefined,
    toasts: [],
    tracking_events: [],
    unit: undefined,
    viewport: undefined,
    footer: undefined,
  },
})
@Injectable()
export class CoreState {
  constructor(
    private announcementService: AnnouncementService,
    private bottomsheetService: BottomsheetService,
    private cookieService: CookieService,
    private metaService: MetaService,
    private modalService: ModalService,
    private programService: ProgramService,
    private scriptService: ScriptService,
    private seoService: SeoService,
    private siteParamsService: SiteParamsService,
    private titleService: Title,
    private trackingService: TrackingService,
    private unitService: UnitService,
    private gtmService: GoogleTagManagerService,
    private footerService: FooterService,
  ) {}

  @Action(CoreActions.AddMetaTags)
  addMetaTags(_, action: CoreActions.AddMetaTags) {
    this.metaService.addTags(action.tags);
  }

  @Action(CoreActions.CloseAnnouncement)
  closeAnnouncement(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CloseAnnouncement,
  ) {
    this.cookieService.set(`announcement_${action.id}`, 'hidden', 45);
    ctx.patchState({ announcement: undefined });
  }

  @Action(CoreActions.CloseMobileNavigation)
  closeMobileNavigation(ctx: StateContext<CoreStateModel>, _) {
    ctx.patchState({ mobileNavigationOpen: false });
  }

  @Action(CoreActions.CloseAllModals)
  closeAllModals(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CloseAllModals,
  ) {
    const removed = this.modalService.removeAll();
    ctx.patchState({ modalsOpen: [] });
  }

  @Action(CoreActions.CloseModal)
  closeModal(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CloseModal,
  ) {
    const removed = this.modalService.remove(action.id);
    if (removed) {
      const modalsOpen = ctx.getState().modalsOpen.filter(m => m !== action.id);
      ctx.patchState({ modalsOpen });
    }
  }

  @Action(CoreActions.DecreaseRequestCount)
  decreaseRequestCount(ctx: StateContext<CoreStateModel>, _) {
    const requestCount = ctx.getState().requestCount - 1;
    ctx.patchState({ requestCount });
  }

  @Action(CoreActions.FetchAnnouncement)
  fetchAnnouncement(ctx: StateContext<CoreStateModel>, _) {
    return this.announcementService.getAnnouncement().pipe(
      tap(announcement => {
        if (!announcement) return;
        if (this.cookieService.get(`announcement_${announcement.id}`)) return;
        ctx.patchState({ announcement });
      }),
    );
  }

  @Action(CoreActions.FetchSeoData)
  fetchSeoData(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchSeoData,
  ) {
    return this.seoService.getSeoData(action.modelName, action.slug).pipe(
      tap(seoData => ctx.patchState({ seoData })),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  @Action(CoreActions.FetchSiteParams)
  fetchSiteParams(ctx: StateContext<CoreStateModel>, _) {
    return this.siteParamsService
      .getSiteParams()
      .pipe(tap((siteParams: SiteParams) => ctx.patchState({ siteParams })));
  }

  @Action(CoreActions.FetchUnit)
  fetchUnit(ctx: StateContext<CoreStateModel>, action: CoreActions.FetchUnit) {
    return this.unitService
      .getUnit(action.id)
      .pipe(tap(response => ctx.patchState({ unit: response })));
  }

  @Action(CoreActions.HideToast)
  hideToast(ctx: StateContext<CoreStateModel>, action: CoreActions.HideToast) {
    ctx.patchState({
      toasts: [...ctx.getState().toasts.filter(t => t !== action.toast)],
    });
  }

  @Action(CoreActions.IncreaseRequestCount)
  increaseRequestCount(ctx: StateContext<CoreStateModel>, _) {
    const requestCount = ctx.getState().requestCount + 1;
    ctx.patchState({ requestCount });
  }

  @Action(CoreActions.JoinProgram)
  joinProgram(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.JoinProgram,
  ) {
    return this.programService.joinProgram(action.slug, action.delta).pipe(
      tap(response =>
        ctx.dispatch(
          new CoreActions.OpenModal(
            JoinProgramSuccessModalContentComponent.MODAL_ID,
            JoinProgramSuccessModalContentComponent,
            { size: 'xl' },
            {
              text: response.program.attend_confirmation,
              link: response.unit_path,
            },
          ),
        ),
      ),
    );
  }

  @Action(CoreActions.LoadScript)
  loadScript(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.LoadScript,
  ) {
    return (
      this.scriptService
        .loadScript(action.id, action.path, action.options)
        // TODO: catch error
        .pipe(
          tap(id => {
            ctx.patchState({
              scriptsLoaded: [...ctx.getState().scriptsLoaded, id],
            });
          }),
        )
    );
  }

  @Action(CoreActions.OpenMobileNavigation)
  openMobileNavigation(ctx: StateContext<CoreStateModel>, _) {
    ctx.patchState({ mobileNavigationOpen: true });
  }

  @Action(CoreActions.OpenModal)
  openModal(ctx: StateContext<CoreStateModel>, action: CoreActions.OpenModal) {
    const added = this.modalService.add(
      action.id,
      action.component,
      action.options,
      action.inputs,
    );
    if (added) {
      const modalsOpen = [...ctx.getState().modalsOpen, action.id];
      ctx.patchState({ modalsOpen });
    }
  }

  @Action(CoreActions.SetBottomsheet)
  setBottomsheet(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.SetBottomsheet,
  ) {
    this.bottomsheetService.component = action.component;
    this.bottomsheetService.showCloseIcon = action.showCloseIcon;
    this.bottomsheetService.inputs = action.inputs;
    ctx.patchState({ bottomsheetOpen: !!action.component });
  }

  @Action(CoreActions.SetRedirected)
  setRedirected(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.SetRedirected,
  ) {
    ctx.patchState({ redirected: action.redirected });
  }

  @Action(CoreActions.ReinsertScripts)
  reinsertScripts(_, action: CoreActions.ReinsertScripts) {
    this.scriptService.reinsertScripts(action.scripts);
  }

  @Action(CoreActions.RemoveMetaTags)
  removeMetaTags(_, action: CoreActions.RemoveMetaTags) {
    this.metaService.removeTags(action.tagNames);
  }

  @Action(CoreActions.RemoveScript)
  removeScript(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.RemoveScript,
  ) {
    if (this.scriptService.removeScript(action.id)) {
      const scriptsLoaded = ctx
        .getState()
        .scriptsLoaded.filter(script => script !== action.id);
      ctx.patchState({ scriptsLoaded });
    }
  }

  @Action(CoreActions.SendCtaClickedEvent)
  sendCtaClickedEvent(_, action: CoreActions.SendCtaClickedEvent) {
    return this.trackingService.sendCtaClickedEvent(action.ctaClickedEvent);
  }

  @Action(CoreActions.SendToGTM)
  sendToGTM(_, action: CoreActions.SendToGTM) {
    return this.gtmService.pushTag(action.gtmEvents);
  }

  @Action(CoreActions.GetTrackingEvents)
  getTrackingEvents(ctx: StateContext<CoreStateModel>, _) {
    return this.trackingService.getTrackingEvents().pipe(
      tap(response =>
        ctx.patchState({
          tracking_events: response.tracking_events,
          leanplum_events: response.leanplum,
        }),
      ),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  @Action(CoreActions.SetContentOnly)
  setContentOnly(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.SetContentOnly,
  ) {
    ctx.patchState({ contentOnly: action.contentOnly });
  }

  @Action(CoreActions.SetNextUrl)
  setNextUrl(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.SetNextUrl,
  ) {
    ctx.patchState({ nextUrl: action.nextUrl });
  }

  @Action(CoreActions.SetNoindex)
  setNoindex(_, action: CoreActions.SetNoindex) {
    this.metaService.setNoindex(action.noindex);
  }

  @Action(CoreActions.SetSectionId)
  setSectionId(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.SetSectionId,
  ) {
    ctx.patchState({ sectionId: action.sectionId });
  }

  @Action(CoreActions.SetTitle)
  setTitle(_, action: CoreActions.SetTitle) {
    this.titleService.setTitle(action.title);
  }

  @Action(CoreActions.ShowToast)
  showToast(ctx: StateContext<CoreStateModel>, action: CoreActions.ShowToast) {
    ctx.patchState({ toasts: [...ctx.getState().toasts, action.toast] });
  }

  @Action(CoreActions.ViewportChanged)
  viewportChanged(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ViewportChanged,
  ) {
    ctx.patchState({ viewport: action.viewport });
  }

  @Action(CoreActions.FetchFooter)
  fetchFooter(ctx: StateContext<CoreStateModel>, _) {
    return this.footerService
      .getFooter()
      .pipe(tap(footer => ctx.patchState({ footer: footer })));
  }
}
