import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video-teaser-placeholder',
  standalone: true,
  templateUrl: './video-teaser-placeholder.component.html',
  styleUrls: ['./video-teaser-placeholder.component.sass'],
  imports: [CommonModule],
})
export class VideoTeaserPlaceholderComponent {
  @Input() size: 'small' | 'smaller' | 'default' = 'default';
}
