import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LiveClass, LiveClassLink } from '../api/models/live-class';
import { LiveClassesService } from '../api/services/live-classes.service';
import { LiveClassesActions } from './live-classes.actions';

export interface LiveClassesStateModel {
  liveClasses: LiveClass[];
  liveClassLink: LiveClassLink;
}

@State<LiveClassesStateModel>({
  name: 'liveClasses',
  defaults: {
    liveClasses: undefined,
    liveClassLink: undefined,
  },
})
@Injectable()
export class LiveClassesState {
  constructor(private liveClassesService: LiveClassesService) {}

  @Action(LiveClassesActions.FetchAll)
  fetchAll(ctx: StateContext<LiveClassesStateModel>) {
    return this.liveClassesService.fetchAll().pipe(
      tap(liveClasses => ctx.patchState({ liveClasses })),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  @Action(LiveClassesActions.RequestLiveClassLink)
  requestLiveClassLink(
    ctx: StateContext<LiveClassesStateModel>,
    action: LiveClassesActions.RequestLiveClassLink,
  ) {
    return this.liveClassesService.requestLiveClassLink(action.id).pipe(
      tap(liveClassLink => ctx.patchState({ liveClassLink })),
      catchError(error => {
        return throwError(error);
      }),
    );
  }
}
