import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BookmarksList } from '../models/bookmarks-list';
import { BookmarksListWithItems } from '../models/bookmarks-list-with-items';

@Injectable({
  providedIn: 'root',
})
export class BookmarksService {
  constructor(private httpClient: HttpClient) {}

  baseUrl = environment.apiPath + '/v1/bookmarks_lists';

  createList(listName: string): Observable<BookmarksList> {
    return this.httpClient.post<BookmarksList>(this.baseUrl, { title: listName }).pipe(catchError(this.handleError));
  }

  getLists(limit?: number): Observable<Array<BookmarksList>> {
    const params = limit ? { limit } : undefined;
    return this.httpClient
      .get<Array<BookmarksList>>(this.baseUrl, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  getList(id: number, sortBy?: string, limit?: number): Observable<BookmarksListWithItems> {
    const params = {};
    if (limit) params['limit'] = limit;
    if (sortBy) params['sort_by'] = sortBy;
    return this.httpClient.get<BookmarksListWithItems>(this.baseUrl + '/' + id, { params });
  }

  // FIXME: any is not good here
  renameList(id: number, newName: string): Observable<any> {
    return this.httpClient.patch<any>(this.baseUrl + '/' + id, { title: newName }).pipe(catchError(this.handleError));
  }

  deleteList(id: number): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/' + id).pipe(catchError(this.handleError));
  }

  getItemLists(videoId: number): Observable<Array<number>> {
    return this.httpClient
      .get<Array<number>>(environment.apiPath + '/v1/bookmarks_list_items/bookmarks_lists', {
        params: { item_type: 'Video', item_id: videoId },
      })
      .pipe(catchError(this.handleError));
  }

  addItemToList(videoId: number, listId: number): Observable<any> {
    return this.httpClient
      .post<any>(environment.apiPath + '/v1/bookmarks_list_items', { item_type: 'Video', item_id: videoId, bookmarks_list_id: listId })
      .pipe(catchError(this.handleError));
  }

  removeItemFromList(videoId: number, listId: number): Observable<any> {
    return this.httpClient
      .delete<any>(environment.apiPath + '/v1/bookmarks_list_items', {
        params: { item_type: 'Video', item_id: videoId, bookmarks_list_id: listId },
      })
      .pipe(catchError(this.handleError));
  }

  updateItemPosition(listId: number, videoId: number, target: number): Observable<BookmarksListWithItems> {
    return this.httpClient
      .patch<BookmarksListWithItems>(environment.apiPath + '/v1/bookmarks_list_items/update_position', {
        item_type: 'Video',
        item_id: videoId,
        bookmarks_list_id: listId,
        target_position: target,
      })
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Object && err.error.message) {
      errorMessage = err.error.message;
    } else {
      errorMessage = err.message;
    }
    return throwError(errorMessage);
  }
}
