import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PartialService } from 'src/app/api/services/partial.service';
import { RouterStateParams } from 'src/app/app-routing.serializer';
import { CoreActions } from 'src/app/core/core.actions';

@Component({
  selector: 'app-my-programs-page',
  templateUrl: './my-programs-page.component.html',
  styleUrls: ['./my-programs-page.component.sass'],
})
export class MyProgramsPageComponent implements OnInit, OnDestroy {
  content = '';
  myProgramsPagePath = '/partial/my_programs';
  @Select(state => state.router.state) routerState$: Observable<RouterStateParams>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private store: Store, private partialService: PartialService) {}

  ngOnInit(): void {
    this.fetchPartial();

    this.routerState$
      .pipe(
        takeUntil(this.destroy$),
        filter((routerState) => !!routerState),
        filter((routerState) => !!routerState.params.slug),
        filter((routerState) => !!routerState.url.includes('teilnehmen')),
      )
      .subscribe((routerState) => {
        this.store
          .dispatch(
            new CoreActions.JoinProgram(
              routerState.params.slug,
              routerState.queryParams.delta,
            ),
          )
          .pipe(take(1))
          .subscribe(() => {
            this.fetchPartial();
          });
        const programVariables = {
          event: 'FE Program joined',
          program: routerState.params.slug,
        };
        this.store.dispatch(
          new CoreActions.SendToGTM({
            DL_leanplum_events: [JSON.stringify(programVariables)],
            event: 'FE_leanplum',
          }),
        );
      });
  }

  private fetchPartial() {
    this.partialService
      .getPartial(this.myProgramsPagePath)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.content = data;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
