import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { NewsletterPageParams } from '../api/models/newsletter-page-params';
import { NewsletterService } from '../api/services/newsletter.service';
import { NewsletterActions } from './newsletter.actions';

export interface NewsletterStateModel {
  hash: string;
  page: NewsletterPageParams;
}

@State<NewsletterStateModel>({
  name: 'newsletter',
  defaults: {
    hash: undefined,
    page: undefined,
  },
})
@Injectable()
export class NewsletterState {
  constructor(private newsletterService: NewsletterService) {}

  @Action(NewsletterActions.Register)
  register(ctx: StateContext<NewsletterStateModel>, action: NewsletterActions.Register) {
    return this.newsletterService.register(action.email).pipe(tap(response => ctx.patchState({ hash: response.encrypted_hash })));
  }

  @Action(NewsletterActions.SetMockHash)
  setMockHash(ctx: StateContext<NewsletterStateModel>, _) {
    ctx.patchState({ hash: 'mockhash' });
  }

  @Action(NewsletterActions.OptIn)
  optIn(_, action: NewsletterActions.OptIn) {
    return this.newsletterService.optIn(action.hash);
  }

  @Action(NewsletterActions.OptOut)
  optOut(_, action: NewsletterActions.OptOut) {
    return this.newsletterService.optOut(action.uuid);
  }

  @Action(NewsletterActions.FetchForm)
  fetchForm(ctx: StateContext<NewsletterStateModel>, _) {
    return this.newsletterService.getNewsletterForm().pipe(tap(response => ctx.patchState({ page: response })));
  }

  @Action(NewsletterActions.FetchOptIn)
  fetchOptIn(ctx: StateContext<NewsletterStateModel>, _) {
    return this.newsletterService.getNewsletterOptIn().pipe(tap(response => ctx.patchState({ page: response })));
  }

  @Action(NewsletterActions.FetchConfirm)
  fetchConfirm(ctx: StateContext<NewsletterStateModel>, _) {
    return this.newsletterService.getNewsletterConfirm().pipe(tap(response => ctx.patchState({ page: response })));
  }

  @Action(NewsletterActions.FetchOptOut)
  fetchOptOut(ctx: StateContext<NewsletterStateModel>, _) {
    return this.newsletterService.getNewsletterOptOut().pipe(tap(response => ctx.patchState({ page: response })));
  }

  @Action(NewsletterActions.ClearPage)
  clearPage(ctx: StateContext<NewsletterStateModel>, _) {
    ctx.patchState({ page: undefined });
  }
}
