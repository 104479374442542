import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { Utility } from 'src/app/shared/utility';

@Injectable({
  providedIn: 'root',
})
export class PageRenderedService {
  private DEBOUNCE_TIME = 1000;
  private setReady = (timeout: number) => {
    const prerenderDiv = document.createElement('div');
    prerenderDiv.setAttribute('id', 'prerender-hidden-div');
    prerenderDiv.style.cssText = 'display:none;';
    document.body.appendChild(prerenderDiv);
    // leave some time to update the DOM
    setTimeout(() => ((window as any).prerenderReady = true), timeout);
  };

  constructor(private store: Store, private zone: NgZone) {}

  init() {
    if (Utility.isPrerender) {
      this.waitForRenderComplete();
    } else {
      this.setReady(0);
    }
  }

  private waitForRenderComplete() {
    const requestCount$ = this.store.select(state => state.core.requestCount);
    const noRequestsForDebounceTime$ = requestCount$.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      filter(count => count === 0),
    );

    const windowLoaded$ = fromEvent(window, 'load');

    combineLatest([noRequestsForDebounceTime$, windowLoaded$])
      .pipe(take(1))
      .subscribe(() => this.setReady(300));
  }
}
