import { Component, Inject, LOCALE_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-benefits-section',
  templateUrl: './benefits-section.component.html',
  styleUrls: ['./benefits-section.component.sass'],
})
export class BenefitsSectionComponent {
  private BREAKPOINTS = constants.breakpoints;

  public autoplay: OwlOptions = {
    autoplay: true,
    loop: true,
    dots: true,
    dotsSpeed: 1200,
    autoplaySpeed: 1200,
    autoplayTimeout: 5000,
    nav: false,
    items: 1,
    responsive: {
      [this.BREAKPOINTS.medium]: {
        items: 2,
      },
      [this.BREAKPOINTS.xlarge]: {
        autoplay: false,
        loop: false,
        items: 3,
      },
    },
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
