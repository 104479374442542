import { Component } from '@angular/core';

@Component({
  selector: 'app-morning-videos',
  template: `<app-show-more-page
    type="video"
    subPath="morning_videos"
    categorySlug="Morgens"
    headline="Für morgens"
    subheadline="Starte aktiv und achtsam in den Tag!"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class MorningVideosComponent {}
