import {
  CommonModule,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-recommended-by',
  standalone: true,
  templateUrl: './recommended-by.component.html',
  styleUrls: ['./recommended-by.component.sass'],
  imports: [CommonModule, NgOptimizedImage],
  providers: [provideImageKitLoader('https://ik.imagekit.io/ye')],
})
export class RecommendedByComponent {}
