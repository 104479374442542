import { Component } from '@angular/core';

@Component({
  selector: 'app-yin-videos',
  template: `<app-show-more-page
    type="video"
    subPath="search?style=yin yoga"
    categorySlug="Yin"
    headline="Yin Yoga"
    subheadline="Geh mit Yin nach innen"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class YinVideosComponent {}
