import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SortByOption } from 'src/app/api/models/sort-by-option';
import { CoreActions } from 'src/app/core/core.actions';
import { Viewport } from 'src/app/core/core.state';
import { SortByComponent } from '../sort-by/sort-by.component';

@Component({
  selector: 'app-sort-by-button',
  templateUrl: './sort-by-button.component.html',
  styleUrls: ['./sort-by-button.component.sass'],
})
export class SortByButtonComponent implements OnInit {
  public viewport$: Observable<Viewport>;
  public label$: Observable<string>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.viewport$ = this.store.select(state => state.core.viewport);
    this.label$ = combineLatest([
      this.store.select(state => state.filter.options),
      this.store.select(state => state.filter.sortBy),
    ]).pipe(
      map(([options, selectedSortBy]) => [options?.sort_by, selectedSortBy]),
      filter(([sortByOptions, _]) => !!sortByOptions),
      map(
        ([sortByOptions, selectedSortBy]) =>
          sortByOptions.find(
            (option: SortByOption) => option.key === selectedSortBy,
          )?.label || $localize`Sortieren`,
      ),
    );
  }

  showSlideIn() {
    this.store.dispatch(new CoreActions.SetBottomsheet(SortByComponent, true));
  }
}
