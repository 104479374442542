import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TeacherTeaser } from 'src/app/api/models/teacher-teaser';

@Component({
  selector: 'app-teacher-teaser',
  standalone: true,
  templateUrl: './teacher-teaser.component.html',
  styleUrls: ['./teacher-teaser.component.sass'],
  imports: [CommonModule]
})
export class TeacherTeaserComponent {
  @Input() teaser: TeacherTeaser;
}
