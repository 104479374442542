import { Component } from '@angular/core';

@Component({
  selector: 'app-well-rated-videos',
  template: `<app-show-more-page
    type="video"
    subPath="rated_good_videos"
    categorySlug="Von mir gut bewertet"
    headline="Von mir gut bewertet"
    backLink="/meine-videos"
  ></app-show-more-page>`,
})
export class WellRatedVideosComponent {}
