import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreActions } from 'src/app/core/core.actions';
import { CreateBookmarksListModalComponent } from '../../create-bookmarks-list-modal/create-bookmarks-list-modal.component';

@Component({
  selector: 'app-bookmarks-menu-add-list-item',
  templateUrl: './bookmarks-menu-add-list-item.component.html',
  styleUrls: ['./bookmarks-menu-add-list-item.component.sass'],
})
export class BookmarksMenuAddListItemComponent {
  @Input() showDescription: boolean;
  @Input() videoId: number;

  constructor(private store: Store) {}

  createNewList() {
    this.store.dispatch(
      new CoreActions.OpenModal(
        CreateBookmarksListModalComponent.MODAL_ID,
        CreateBookmarksListModalComponent,
        { windowClass: 'create-bookmarks-list-modal-window' },
        { videoId: this.videoId },
      ),
    );
  }
}
