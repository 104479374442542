import { Component } from '@angular/core';

@Component({
  selector: 'app-last-viewed-videos',
  template: `<app-show-more-page
    type="video"
    subPath="last_viewed_videos"
    categorySlug="Zuletzt gesehen"
    headline="Zuletzt gesehen"
    backLink="/meine-videos"
  i18n-headline></app-show-more-page>`,
})
export class LastViewedVideosComponent {}
