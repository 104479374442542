<svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
      d="M20.84 2.88492C21.6281 3.1864 22.3441 3.62843 22.9472 4.18574L23.2977 4.50955C24.5133 5.63545 25.196 7.16096 25.196 8.75141C25.196 10.3419 24.5133 11.8674 23.2977 12.9933L13.9844 21.5996L13.0058 22.5537L12.9787 22.5287L12.9538 22.5518L11.9093 21.5405L2.65988 12.9934C1.44243 11.8684 0.758484 10.3426 0.758484 8.75155C0.758484 7.16054 1.44243 5.6347 2.65988 4.50969L3.01033 4.18584C3.61315 3.62878 4.3288 3.1869 5.11642 2.88543C5.90405 2.58395 6.74822 2.42878 7.60073 2.42878C8.45325 2.42878 9.29742 2.58395 10.085 2.88543C10.8727 3.1869 11.5883 3.62878 12.1911 4.18584L12.1962 4.19052L12.9763 4.92474L13.763 4.18893C14.3657 3.6312 15.0814 3.18867 15.8692 2.88664C16.657 2.58462 17.5015 2.42901 18.3544 2.42871C19.2073 2.42842 20.052 2.58344 20.84 2.88492Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="25.5" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
