import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BookmarksListWithItems } from 'src/app/api/models/bookmarks-list-with-items';
import { CoreActions } from 'src/app/core/core.actions';
import { Viewport } from 'src/app/core/core.state';
import { DeleteBookmarksListModalComponent } from '../../delete-bookmarks-list-modal/delete-bookmarks-list-modal.component';
import { EditBookmarksListMenuComponent } from '../../edit-bookmarks-list-menu/edit-bookmarks-list-menu.component';
import { RenameBookmarksListModalComponent } from '../../rename-bookmarks-list-modal/rename-bookmarks-list-modal.component';

@Component({
  selector: 'app-bookmarks-list-page-header',
  templateUrl: './bookmarks-list-page-header.component.html',
  styleUrls: ['./bookmarks-list-page-header.component.sass'],
})
export class BookmarksListPageHeaderComponent implements OnInit, OnDestroy {
  @Input() bookmarksList: BookmarksListWithItems;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private popoverOpen$: Subject<boolean> = new Subject<boolean>();
  public buttonActivated$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.buttonActivated$ = combineLatest([
      this.store.select(state => state.core.bottomsheetOpen),
      this.store.select(state => state.core.modalsOpen),
      this.popoverOpen$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(
        ([bottomsheetOpen, openModals, popoverOpen]) =>
          bottomsheetOpen ||
          openModals.includes(EditBookmarksListMenuComponent.MODAL_ID) ||
          openModals.includes(RenameBookmarksListModalComponent.MODAL_ID) ||
          openModals.includes(DeleteBookmarksListModalComponent.MODAL_ID) ||
          popoverOpen,
      ),
    );
  }

  openListMenu(popover: NgbPopover) {
    const viewport: Viewport = this.store.selectSnapshot(state => state.core.viewport);
    const listId: number = this.store.selectSnapshot(state => state.bookmarks.bookmarksList)?.id;
    switch (viewport) {
      case 'desktop':
        popover.open();
        break;
      case 'tablet':
        this.store.dispatch(
          new CoreActions.OpenModal(
            EditBookmarksListMenuComponent.MODAL_ID,
            EditBookmarksListMenuComponent,
            { windowClass: 'edit-bookmarks-list-modal-window' },
            { listId },
          ),
        );
        break;
      default:
        this.store.dispatch(new CoreActions.SetBottomsheet(EditBookmarksListMenuComponent, true, { listId }));
    }
  }

  popoverShown() {
    this.popoverOpen$.next(true);
  }

  popoverHidden() {
    this.popoverOpen$.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
