<div class="bookmarks-menu-list-item" (click)="toggleList()">
  <div *ngIf="listChecked?.image_url; else logoIcon" class="image is-flex is-align-items-center is-justify-content-center">
    <img height="48" class="is-height-100 is-width-auto" [src]="listChecked?.image_url" />
  </div>
  <ng-template #logoIcon>
    <div class="image is-flex is-align-items-center is-justify-content-center logo-icon">
      <svg-logo-icon></svg-logo-icon>
    </div>
  </ng-template>
  <div class="item-title">
    {{ listChecked?.title }}
  </div>
  <div class="checkmark-box is-flex is-align-items-center is-justify-content-center">
    <svg-checkmark *ngIf="listChecked?.checked" class="checkmark"></svg-checkmark>
  </div>
</div>
