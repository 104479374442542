import { Component, Inject, LOCALE_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-teachers-section',
  templateUrl: './teachers-section.component.html',
  styleUrls: ['./teachers-section.component.sass'],
})
export class TeachersSectionComponent {
  private BREAKPOINTS = constants.breakpoints;

  public noAutoplay: OwlOptions = {
    autoplay: false,
    loop: true,
    dots: true,
    dotsSpeed: 1200,
    nav: false,
    items: 1,
    responsive: {
      [this.BREAKPOINTS.medium]: {
        items: 2,
      },
      [this.BREAKPOINTS.xlarge]: {
        autoplay: false,
        loop: false,
        items: 4,
      },
    },
  };

  // TODO: this is far from great, but it's a start, especially since this data
  // was hardcoded in the template before
  //
  teachers = {
    de: [
      {
        name: 'Nicole Bongartz',
        description: 'Verzaubert durch ihre kreativen Flows',
        photo:
          'production/uploads/components/homepage/teachers-section/nicole-bongartz.jpg',
        url: '/yogalehrer/nicole-bongartz',
      },
      {
        name: 'Valentin Alex',
        description:
          'Kraftvoll inspirierende Vinyasa Flows mit viel Lebensfreude',
        photo:
          'production/uploads/components/homepage/teachers-section/valentin-alex.jpg',
        url: '/yogalehrer/valentin-alex',
      },
      {
        name: 'Annika Isterling',
        description: 'Einfühlsame Yogastunden mit präziser Anleitung',
        photo:
          'production/uploads/components/homepage/teachers-section/annika-isterling.jpg',
        url: '/yogalehrer/annika-isterling',
      },
      {
        name: 'Christina Lobe',
        description:
          'Klar und sanft angeleitete Anusara Stunden, die unter die Haut gehen',
        photo:
          'production/uploads/components/homepage/teachers-section/christina-lobe.jpg',
        url: '/yogalehrer/christina-lobe',
      },
    ],
    en: [
      {
        name: 'Esther Ekhart',
        description: 'Hatha, Meditation, Yin',
        photo:
          'production/uploads/teacher/round_image/337/webp_content_Esther_Ekhart_hires.png.webp',
        url: '/yogalehrer/esther-ekhart',
      },
      {
        name: 'Laia Bové',
        description: 'Vinyasa flow',
        photo:
          'production/uploads/teacher/round_image/239/webp_0dbb0726-9510-40ba-86b8-4015dee2060b.jpg.webp',
        url: '/yogalehrer/laia-bove',
      },
      {
        name: 'Gemma Vassallo',
        description: 'Yin, Vinyasa flow',
        photo:
          'production/uploads/teacher/round_image/335/webp_c9824198-c75e-4840-8224-3b01458e5312.jpg.webp',
        url: '/yogalehrer/gemma-vassallo',
      },
      {
        name: 'David Lurey',
        description: 'Mixed movement, Vinyasa flow',
        photo:
          'production/uploads/teacher/round_image/164/webp_3b87d760-aa4f-4600-9182-fa44eabfb95e.jpg.webp',
        url: '/yogalehrer/david-lurey',
      },
    ],
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
