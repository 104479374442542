<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_1852_255240" fill="white">
    <path d="M9 9H10.5V18H9V9Z" />
  </mask>
  <path d="M9 9H10.5V18H9V9Z" fill="currentColor" />
  <path
    d="M9 9V7.3H7.3V9H9ZM10.5 9H12.2V7.3H10.5V9ZM10.5 18V19.7H12.2V18H10.5ZM9 18H7.3V19.7H9V18ZM9 10.7H10.5V7.3H9V10.7ZM8.8 9V18H12.2V9H8.8ZM10.5 16.3H9V19.7H10.5V16.3ZM10.7 18V9H7.3V18H10.7Z"
    fill="currentColor"
    mask="url(#path-1-inside-1_1852_255240)"
  />
  <mask id="path-3-inside-2_1852_255240" fill="white">
    <path d="M13.5 9H15V18H13.5V9Z" />
  </mask>
  <path d="M13.5 9H15V18H13.5V9Z" fill="currentColor" />
  <path
    d="M13.5 9V7.3H11.8V9H13.5ZM15 9H16.7V7.3H15V9ZM15 18V19.7H16.7V18H15ZM13.5 18H11.8V19.7H13.5V18ZM13.5 10.7H15V7.3H13.5V10.7ZM13.3 9V18H16.7V9H13.3ZM15 16.3H13.5V19.7H15V16.3ZM15.2 18V9H11.8V18H15.2Z"
    fill="currentColor"
    mask="url(#path-3-inside-2_1852_255240)"
  />
  <mask id="path-5-inside-3_1852_255240" fill="white">
    <path
      d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
    />
  </mask>
  <path
    d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
    fill="currentColor"
  />
  <path
    d="M3 4.5V2.8H1.3V4.5H3ZM3 6H1.3V7.7H3V6ZM4.5 6H6.2V4.3H4.5V6ZM4.5 21H2.8H4.5ZM19.5 6V4.3H17.8V6H19.5ZM21 6V7.7H22.7V6H21ZM21 4.5H22.7V2.8H21V4.5ZM6 21H4.3V22.7H6V21ZM6 6V4.3H4.3V6H6ZM18 6H19.7V4.3H18V6ZM18 21V22.7H19.7V21H18ZM1.3 4.5V6H4.7V4.5H1.3ZM3 7.7H4.5V4.3H3V7.7ZM2.8 6V21H6.2V6H2.8ZM2.8 21C2.8 21.8487 3.13714 22.6626 3.73726 23.2627L6.14142 20.8586C6.17893 20.8961 6.2 20.947 6.2 21H2.8ZM3.73726 23.2627C4.33737 23.8629 5.15131 24.2 6 24.2V20.8C6.05304 20.8 6.10391 20.8211 6.14142 20.8586L3.73726 23.2627ZM6 24.2H18V20.8H6V24.2ZM18 24.2C18.8487 24.2 19.6626 23.8629 20.2627 23.2627L17.8586 20.8586C17.8961 20.8211 17.947 20.8 18 20.8V24.2ZM20.2627 23.2627C20.8629 22.6626 21.2 21.8487 21.2 21H17.8C17.8 20.947 17.8211 20.8961 17.8586 20.8586L20.2627 23.2627ZM21.2 21V6H17.8V21H21.2ZM19.5 7.7H21V4.3H19.5V7.7ZM22.7 6V4.5H19.3V6H22.7ZM21 2.8H3V6.2H21V2.8ZM7.7 21V6H4.3V21H7.7ZM6 7.7H18V4.3H6V7.7ZM16.3 6V21H19.7V6H16.3ZM18 19.3H6V22.7H18V19.3Z"
    fill="currentColor"
    mask="url(#path-5-inside-3_1852_255240)"
  />
  <mask id="path-7-inside-4_1852_255240" fill="white">
    <path d="M9 1.5H15V3H9V1.5Z" />
  </mask>
  <path d="M9 1.5H15V3H9V1.5Z" fill="currentColor" />
  <path
    d="M9 1.5V-0.2H7.3V1.5H9ZM15 1.5H16.7V-0.2H15V1.5ZM15 3V4.7H16.7V3H15ZM9 3H7.3V4.7H9V3ZM9 3.2H15V-0.2H9V3.2ZM13.3 1.5V3H16.7V1.5H13.3ZM15 1.3H9V4.7H15V1.3ZM10.7 3V1.5H7.3V3H10.7Z"
    fill="currentColor"
    mask="url(#path-7-inside-4_1852_255240)"
  />
</svg>
