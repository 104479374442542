import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PartialService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  getPartial(path: string, redirectOnErrorUrl?: string): Observable<string> {
    return this.httpClient
      .get(path, {
        responseType: 'text',
      })
      .pipe(
        catchError((err: any) => {
          if (redirectOnErrorUrl) {
            this.store.dispatch(new Navigate([redirectOnErrorUrl]));
            return EMPTY;
          }
          let errorMessage = '';
          if (err.error instanceof Error) {
            errorMessage = 'Error occurred ' + err.error.message;
          } else {
            errorMessage = 'System Error occurred ' + err.error.message;
          }
          return throwError(errorMessage);
        }),
      );
  }
}
