import { DurationFilterOption } from './duration-filter-option';
import { EffortFilterOption } from './effort-filter-option';
import { LevelFilterOption } from './level-filter-option';
import { MoreFilterOption } from './more-filter-option';
import { SortByOption } from './sort-by-option';
import { StyleFilterOption } from './style-filter-option';
import { TeacherFilterOption } from './teacher-filter-option';

export type FilterOptions = {
  duration_options: DurationFilterOption[];
  effort_options: EffortFilterOption[];
  level_options: LevelFilterOption[];
  more_filter_options: MoreFilterOption[];
  sort_by: SortByOption[];
  style_options: {
    all: StyleFilterOption[];
    popular: StyleFilterOption[];
  };
  teacher_options: {
    all: TeacherFilterOption[];
    popular: TeacherFilterOption[];
  };
};

// since FilterOptions is a type, we need to export the keys separately
export const FilterOptionKeys = [
  'duration_options',
  'effort_options',
  'level_options',
  'more_filter_options',
  'sort_by',
  'style_options',
  'teacher_options',
];
