import { Dashboard } from '../models/dashboard';

export const DASHBOARD_DE: Dashboard = {
  sliders: [
    {
      type: 'video',
      headline: 'Die beliebtesten Videos',
      subheadline: 'Yoga-Videos, die unsere Community begeistern',
      teasers: [],
      link: '/kategorie/videos/popular',
      api: 'videos/popular_videos',
    },
    {
      type: 'video',
      headline: 'Für morgens',
      subheadline: 'Starte aktiv und achtsam in den Tag!',
      teasers: [],
      link: '/kategorie/videos/morning',
      api: 'videos/morning_videos',
    },
    {
      type: 'video',
      headline: 'Neue Videos',
      subheadline: 'Schau dir das Neuste vom Neuen an',
      teasers: [],
      link: '/kategorie/videos/new',
      api: 'videos/new_videos',
    },
    {
      type: 'video',
      headline: 'Zum entspannen',
      subheadline: 'Schluss mit Stress – gönn dir eine Auszeit!',
      teasers: [],
      link: '/kategorie/videos/stress',
      api: 'videos/stress_videos',
    },
    {
      type: 'video',
      headline: 'Für deinen Rücken',
      subheadline: 'Zum Stärken und Entspannen',
      teasers: [],
      link: '/kategorie/videos/back',
      api: 'videos/back_pain_health_videos',
    },
    {
      type: 'video',
      headline: 'Fit werden',
      subheadline: 'Finde Sequenzen, die dich herausfordern',
      teasers: [],
      link: '/kategorie/videos/fitness',
      api: 'videos/fitness_videos',
    },
    {
      type: 'video',
      headline: 'Für Anfänger:innen',
      subheadline: 'Einfache Sequenzen für Yoga-Neulinge',
      teasers: [],
      link: '/kategorie/videos/beginner',
      api: 'videos/beginner_videos',
    },
    {
      type: 'video',
      headline: 'Für Fortgeschrittene',
      subheadline: 'Herausfordernde Sequenzen für erfahrene Yogis',
      teasers: [],
      link: '/kategorie/videos/advanced',
      api: 'videos/advanced_videos',
    },
    {
      type: 'program',
      headline: 'Die beliebtesten Programme',
      subheadline:
        'Erreiche mit unseren Yoga-Programmen und -Kursen deine Ziele',
      teasers: [],
      link: '/programme',
      api: 'programs/popular',
    },
    {
      type: 'video',
      headline: 'Die beliebtesten Meditationen',
      subheadline: 'Finde die Ruhe in dir selbst',
      teasers: [],
      link: '/kategorie/videos/meditation',
      api: 'videos/popular_meditation_videos',
    },
    {
      type: 'video',
      headline: 'Für abends',
      subheadline: 'Lass den Tag hinter dir!',
      teasers: [],
      link: '/kategorie/videos/evening',
      api: 'videos/evening_videos',
    },
    {
      type: 'video',
      headline: 'Live',
      teasers: [],
      link: '/kategorie/videos/live-aufzeichnungen',
      api: 'videos/live_classes_videos',
    },
    {
      type: 'article',
      headline: 'Neues aus dem Magazin',
      subheadline:
        'Lass dich von unseren News und Tipps rund um Yoga inspirieren!',
      teasers: [],
      link: '/magazin',
      api: 'articles',
    },
    {
      type: 'podcast',
      headline: 'Besser leben mit Yoga',
      subheadline: 'Unser Yoga-Podcast zum Anhören',
      teasers: [],
      link: '/podcast',
      api: 'podcasts/new_category',
    },
  ],
};

export const DASHBOARD_EN: Dashboard = {
  sliders: [
    {
      type: 'video',
      headline: 'Newest classes',
      subheadline: 'Find your perfect match with our latest practices',
      teasers: [],
      link: '/kategorie/videos/new',
      api: 'videos/new_videos',
    },
    {
      type: 'video',
      headline: '20 - 30 minutes',
      subheadline: 'Short classes for your daily dose of yoga',
      teasers: [],
      link: '/kategorie/videos/short',
      api: 'videos/search?duration_min=20&duration_max=30',
    },
    {
      type: 'video',
      headline: 'Classes with Esther',
      subheadline: 'Move, breathe and meditate with Esther Ekhart',
      teasers: [],
      link: '/kategorie/videos/esther',
      api: 'videos/search?teachers=Esther Ekhart',
    },
    {
      type: 'video',
      headline: 'Live online classes',
      subheadline: 'Practice live with your virtual community',
      teasers: [],
      link: '/kategorie/videos/live-aufzeichnungen',
      api: 'videos/live_classes_videos',
    },
    {
      type: 'video',
      headline: 'Morning yoga',
      subheadline: 'Start your day feeling fresh and focused',
      teasers: [],
      link: '/kategorie/videos/morning',
      api: 'videos/morning_videos',
    },
    {
      type: 'video',
      headline: 'Classes for neck & shoulders',
      subheadline: 'Tease out tension with these targeted practices',
      teasers: [],
      link: '/kategorie/videos/neckshoulder',
      api: 'videos/neck_and_shoulder_videos',
    },
    {
      type: 'video',
      headline: 'Evening yoga',
      subheadline: 'Relax and unwind at the end of your day',
      teasers: [],
      link: '/kategorie/videos/evening',
      api: 'videos/evening_videos',
    },
    {
      type: 'video',
      headline: 'Classes for strength',
      subheadline: 'Boost your energy, strength and fitness',
      teasers: [],
      link: '/kategorie/videos/fitness',
      api: 'videos/fitness_videos',
    },
    {
      type: 'video',
      headline: 'Meditations',
      subheadline: 'Cutivate calm, presence and resilience',
      teasers: [],
      link: '/kategorie/videos/meditation',
      api: 'videos/popular_meditation_videos',
    },
    {
      type: 'video',
      headline: 'Classes for back health',
      subheadline:
        'Strengthen and mobilize your back with these supportive practices',
      teasers: [],
      link: '/kategorie/videos/back',
      api: 'videos/back_pain_health_videos',
    },
    {
      type: 'video',
      headline: 'Most popular classes',
      subheadline: "What's trending now?",
      teasers: [],
      link: '/kategorie/videos/popular',
      api: 'videos/popular_videos',
    },
    {
      type: 'video',
      headline: 'Yin Yoga',
      subheadline: 'Go inward with Yin',
      teasers: [],
      link: '/kategorie/videos/yin',
      api: 'videos/search?style=yin yoga',
    },
    {
      type: 'video',
      headline: "Beginners' classes",
      subheadline: 'Build a strong foundation for your practice',
      teasers: [],
      link: '/kategorie/videos/beginner',
      api: 'videos/beginner_videos',
    },
    {
      type: 'article',
      headline: 'Articles',
      subheadline: 'Take a deeper dive into all things yoga',
      teasers: [],
      link: '/magazin',
      api: 'articles',
    },
  ],
};
