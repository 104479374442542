<svg width="60" height="35" viewBox="0 0 60 35" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect *ngIf="effort > 0; else empty0" x="0" y="25.5498" width="7.72722" height="8.51678" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty0>
    <rect x="0" y="25.5498" width="7.72722" height="8.51678" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>

  <rect *ngIf="effort > 1; else empty1" x="10.4551" y="19.8721" width="7.72722" height="14.1946" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty1>
    <rect x="10.7051" y="20.1221" width="7.22722" height="13.6946" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>

  <rect *ngIf="effort > 2; else empty2" x="20.9089" y="14.1943" width="7.72721" height="19.8725" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty2>
    <rect x="21.1589" y="14.4443" width="7.22721" height="19.3725" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>

  <rect *ngIf="effort > 3; else empty3" x="31.364" y="11.3555" width="7.72722" height="22.7114" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty3>
    <rect x="31.614" y="11.6055" width="7.22722" height="22.2114" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>

  <rect *ngIf="effort > 4; else empty4" x="41.8179" y="5.67773" width="7.72722" height="28.3893" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty4>
    <rect x="42.0679" y="5.92773" width="7.22722" height="27.8893" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>

  <rect *ngIf="effort > 5; else empty5" x="52.2729" width="7.72722" height="34.0671" attr.fill="{{ COLORS[effort - 1] }}" />
  <ng-template #empty5>
    <rect x="52.5229" y="0.25" width="7.22722" height="33.5671" stroke="#555547" stroke-opacity="0.5" stroke-width="0.5" />
  </ng-template>
</svg>