import { Component } from '@angular/core';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-academy-section',
  templateUrl: './academy-section.component.html',
  styleUrls: ['./academy-section.component.sass'],
})
export class AcademySectionComponent {
  public BREAKPOINTS = constants.breakpoints;
}
