<button *ngIf="!href" [ngClass]="{ 'icon-button': true, activated: this.activated }" (click)="checkClick($event)">
  <ng-template *ngTemplateOutlet="content"></ng-template>
</button>

<a *ngIf="href" [href]="href" [ngClass]="{ 'icon-button': true, 'no-link': true, activated: this.activated }" (click)="checkClick($event)">
  <ng-template *ngTemplateOutlet="content"></ng-template>
</a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
