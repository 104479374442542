import { Component } from '@angular/core';

@Component({
  selector: 'app-popular-videos',
  template: `<app-show-more-page
    type="video"
    subPath="popular_videos"
    categorySlug="Beliebtesten"
    headline="Die beliebtesten Videos"
    subheadline="Yoga-Videos, die unsere Community begeistern"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class PopularVideosComponent {}
