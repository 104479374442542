import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BypassSecurityPipe } from '../bypass-security.pipe';
import { H1DecoComponent } from '../h1-deco/h1-deco.component';

@Component({
  selector: 'app-page-header',
  standalone: true,
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.sass'],
  imports: [CommonModule, H1DecoComponent, BypassSecurityPipe],
})
export class PageHeaderComponent {
  @Input() title;
}
