<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_245_15593)">
    <path
      d="M17.6933 12.0001C17.6906 11.1036 17.5945 10.2099 17.4067 9.3334H19.8067C19.6831 8.96777 19.5338 8.61133 19.36 8.26673H17.1267C16.7068 6.8641 16.0557 5.54146 15.2 4.3534C14.6533 4.12353 14.0833 3.95365 13.5 3.84673C14.6722 5.10622 15.557 6.60519 16.0933 8.24007H12.5V3.7334H11.5V8.24673H7.90667C8.44405 6.6089 9.33123 5.10753 10.5067 3.84673C9.92585 3.95187 9.35811 4.11951 8.81334 4.34673C7.95431 5.52981 7.29865 6.8479 6.87334 8.24673H4.62667C4.44972 8.59762 4.29822 8.96078 4.17334 9.3334H6.59334C6.40553 10.2099 6.30946 11.1036 6.30667 12.0001C6.30852 12.9806 6.42033 13.9578 6.64001 14.9134H4.28001C4.41793 15.2871 4.5828 15.6503 4.77334 16.0001H6.92667C7.33481 17.2773 7.93988 18.4829 8.72001 19.5734C9.27865 19.813 9.86225 19.9897 10.46 20.1001C9.37195 18.9132 8.53518 17.5186 8.00001 16.0001H11.5067V20.2267H12.5067V16.0001H16C15.463 17.5192 14.6239 18.9139 13.5333 20.1001C14.1339 19.9858 14.7198 19.8047 15.28 19.5601C16.0589 18.4737 16.6639 17.2726 17.0733 16.0001H19.2067C19.3963 15.6567 19.5612 15.3003 19.7 14.9334H17.3333C17.5635 13.9723 17.6843 12.9883 17.6933 12.0001ZM11.5 14.9134H7.67334C7.20399 13.0786 7.18341 11.1578 7.61334 9.3134H11.5V14.9134ZM16.3267 14.9134H12.5V9.3334H16.3867C16.5782 10.2092 16.6721 11.1036 16.6667 12.0001C16.6721 12.9814 16.5579 13.9597 16.3267 14.9134Z"
      fill="#555547" />
    <path
      d="M12.0002 1.33325C9.8905 1.33325 7.82821 1.95884 6.07408 3.13091C4.31996 4.30298 2.95279 5.96888 2.14545 7.91796C1.33812 9.86704 1.12688 12.0118 1.53846 14.0809C1.95003 16.15 2.96594 18.0506 4.4577 19.5424C5.94946 21.0342 7.85007 22.0501 9.9192 22.4616C11.9883 22.8732 14.133 22.662 16.0821 21.8546C18.0312 21.0473 19.6971 19.6801 20.8692 17.926C22.0412 16.1719 22.6668 14.1096 22.6668 11.9999C22.6668 9.17094 21.543 6.45783 19.5426 4.45745C17.5423 2.45706 14.8291 1.33325 12.0002 1.33325ZM12.0002 21.3333C10.1542 21.3333 8.3497 20.7859 6.81485 19.7603C5.27999 18.7347 4.08371 17.2771 3.37729 15.5716C2.67087 13.8662 2.48604 11.9896 2.84617 10.1791C3.2063 8.36859 4.09521 6.70554 5.4005 5.40025C6.70579 4.09496 8.36884 3.20605 10.1793 2.84592C11.9898 2.48579 13.8664 2.67063 15.5719 3.37704C17.2773 4.08346 18.735 5.27974 19.7605 6.8146C20.7861 8.34945 21.3335 10.154 21.3335 11.9999C21.3335 14.4753 20.3502 16.8492 18.5998 18.5996C16.8495 20.3499 14.4755 21.3333 12.0002 21.3333Z"
      fill="#555547" />
  </g>
  <defs>
    <clipPath id="clip0_245_15593">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>