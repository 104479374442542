import { Component, Inject, LOCALE_ID } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { constants } from 'src/app/constants';

@Component({
  selector: 'app-programs-section',
  templateUrl: './programs-section.component.html',
  styleUrls: ['./programs-section.component.sass'],
})
export class ProgramsSectionComponent {
  public BREAKPOINTS = constants.breakpoints;

  public autoplay: OwlOptions = {
    autoplay: true,
    loop: true,
    dots: true,
    dotsSpeed: 1200,
    autoplaySpeed: 1200,
    autoplayTimeout: 5000,
    nav: false,
    items: 1,
    responsive: {
      [this.BREAKPOINTS.medium]: {
        items: 1,
        margin: 16,
      },
    },
  };

  public programTeasers = [
    {
      path: '/programme/yoga-am-morgen-programm',
      mobile:
        'production/uploads/components/homepage/programs-section/yoga-am-morgen-mobile.jpg',
      tablet:
        'production/uploads/components/homepage/programs-section/yoga-am-morgen-tablet.jpg',
      desktop:
        'production/uploads/components/homepage/programs-section/yoga-am-morgen-desktop.jpg',
      title: 'Yoga am Morgen',
    },
    {
      path: '/programme/herz-ueber-kopf-umkehrhaltungs-programm-mit-lucie-beyer',
      mobile:
        'production/uploads/components/homepage/programs-section/herz-ueber-kopf-mobile.jpg',
      tablet:
        'production/uploads/components/homepage/programs-section/herz-ueber-kopf-tablet.jpg',
      desktop:
        'production/uploads/components/homepage/programs-section/herz-ueber-kopf-desktop.jpg',
      title: 'Herz über Kopf',
    },
    {
      path: '/programme/chakra-power-komm-in-deine-kraft',
      mobile:
        'production/uploads/components/homepage/programs-section/chakra-power-mobile.jpg',
      tablet:
        'production/uploads/components/homepage/programs-section/chakra-power-tablet.jpg',
      desktop:
        'production/uploads/components/homepage/programs-section/chakra-power-desktop.jpg',
      title: 'Chakra Power',
    },
    {
      path: '/programme/sweet-dreams-meditationen-zum-einschlafen',
      mobile:
        'production/uploads/components/homepage/programs-section/sweet-dreams-mobile.jpg',
      tablet:
        'production/uploads/components/homepage/programs-section/sweet-dreams-tablet.jpg',
      desktop:
        'production/uploads/components/homepage/programs-section/sweet-dreams-desktop.jpg',
      title: 'Sweet Dreams',
    },
  ];

  constructor(@Inject(LOCALE_ID) public locale: string) {}
}
