<li *ngIf="teaser" class="podcast-teaser has-border-radius-5">
  <a [href]="teaser.path" [title]="teaser.title + ' (' + teaser.author.name + ')'"
    class="no-link is-flex is-flex-direction-column is-align-items-center">
    <div class="podcast-icon">
      <svg-podcast-icon></svg-podcast-icon>
    </div>
    <div class="episode">#{{ teaser.episode_number }} Besser leben mit Yoga</div>
    <div class="teaser-title">{{ teaser.title }}</div>
    <img *ngIf="teaser.author" class="author-img" loading="lazy" [alt]="teaser.author.name" [src]="teaser.author.avatar_thumb_url"
      fallbackSrc="https://cdn.yogaeasy.de/production/uploads/components/magazine-article-teaser/teaser_fallback_image.jpg" />
    <div *ngIf="teaser.author" class="author-name">
      {{ teaser.author.name }}
    </div>
    <div *ngIf="teaser.published_at" class="date">vom {{ teaser.published_at | date: 'dd.MM.yyyy' }}</div>
  </a>
</li>