import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SiteParams } from 'src/app/api/models/site-params';
import { RoutesWithPathMatch, Utility } from 'src/app/shared/utility';

type CtaButton = SiteParams['cta_button'];

// TODO: use route.data for this: https://angular.io/api/router/Route#data
const HIDE_ON_ROUTES: RoutesWithPathMatch = [
  { path: '/user/sign_in', pathMatch: 'prefix' },
  { path: '/free', pathMatch: 'prefix' },
];

@Component({
  selector: 'app-sticky-cta-button',
  templateUrl: './sticky-cta-button.component.html',
  styleUrls: ['./sticky-cta-button.component.sass'],
})
export class StickyCtaButtonComponent implements OnInit {
  public ctaButton$: Observable<CtaButton>;
  public hideCtaButton$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.ctaButton$ = this.store
      .select((state) => state.core.siteParams)
      .pipe(map((siteParams) => siteParams?.cta_button));

    this.hideCtaButton$ = combineLatest([
      this.store.select((state) => state.user.user),
      this.store.select((state) => state.router.state),
    ]).pipe(
      map(([user, routerState]) => {
        if (user) return true;
        return Utility.routeMatches(routerState?.url, HIDE_ON_ROUTES);
      }),
      startWith(true),
    );
  }
}
