import { ImageLoaderConfig } from '@angular/common';

export const imageKitLoaderWithFallback = (config: ImageLoaderConfig) => {
  const transformations = ['q-auto'];

  if (config.width) {
    transformations.push(`w-${config.width}`);
  }

  // https://docs.imagekit.io/features/default-images
  if (config.loaderParams?.fallback) {
    transformations.push(
      `di-${config.loaderParams.fallback.replaceAll('/', '@@')}`,
    );
  }

  return `https://ik.imagekit.io/ye/tr:${transformations.join(',')}/${
    config.src
  }`;
};
