import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { LiveClass, LiveClassLink } from '../api/models/live-class';
import { ButtonComponent } from '../shared/button/button.component';
import { H1DecoComponent } from '../shared/h1-deco/h1-deco.component';
import { H2Component } from '../shared/h2/h2.component';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { Utility } from '../shared/utility';
import { LiveClassesActions } from './live-classes.actions';

@Component({
  selector: 'app-live-classes',
  standalone: true,
  imports: [
    ButtonComponent,
    PageHeaderComponent,
    H2Component,
    NgOptimizedImage,
    ButtonComponent,
    AsyncPipe,
    H1DecoComponent,
  ],
  templateUrl: './live-classes.component.html',
  styleUrl: './live-classes.component.sass',
})
export class LiveClassesComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public liveClasses$: Observable<LiveClass[]>;
  public liveClassLink$: Observable<LiveClassLink>;
  public isLoggedIn$: Observable<boolean>;
  public startDates: Date[];
  public utility = Utility;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LiveClassesActions.FetchAll());
    this.liveClasses$ = this.store.select(
      state => state.liveClasses.liveClasses,
    );
    this.isLoggedIn$ = this.store
      .select(state => state.user.user)
      .pipe(map(user => user !== null));
  }

  joinClass(id: number): void {
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.store.dispatch(new LiveClassesActions.RequestLiveClassLink(id));
        this.liveClassLink$ = this.store.select(
          state => state.liveClasses.liveClassLink,
        );
        this.liveClassLink$.subscribe(liveClassLink => {
          window.location.href = liveClassLink.url;
        });
      } else {
        window.location.href = '/user/sign_in?next=%10live-classes';
      }
    });
  }

  getFormattedDates(liveClasses$: Observable<LiveClass[]>): string[] {
    const formattedDates: string[] = [];

    liveClasses$.pipe(takeUntil(this.destroy$)).subscribe(liveClasses => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      liveClasses.forEach(liveClass => {
        const date = new Date(liveClass.scheduled_at);
        const formattedDate = date.toLocaleDateString('en-GB', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        });
        formattedDates.push(formattedDate);
      });
    });

    return formattedDates;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
