export namespace DashboardActions {
  export class FetchFullTeaserList {
    static readonly type = '[Dashboard] Fetch full teaser list';
    constructor(
      public type: 'article' | 'podcast' | 'program' | 'teacher' | 'video',
      public subPath: string,
      public limit?: number,
      public page?: number,
    ) {}
  }

  export class FetchVideoTeasers {
    static readonly type = '[Dashboard] Fetch video teasers';
    constructor(
      public category: 'favorites' | 'last_viewed_videos' | 'rated_good_videos',
      public limit?: number,
    ) {}
  }

  export class FetchDashboard {
    static readonly type = '[Dashboard] Fetch dashboard';
  }

  export class FetchNewsItem {
    static readonly type = '[Dashboard] Fetch news item';
  }
}
