import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BottomsheetService {
  // since the core.state won't accept Type<unknown>, we have to use this as workaround
  public component: Type<unknown>;
  public showCloseIcon: boolean;
  public inputs: { [key: string]: any };
}
