<div class="discover">
  <ng-template #filterComponent />
  <ng-container [ngSwitch]="childPage">
    <app-filtered-list *ngSwitchCase="'filtered'" />
    <ng-container *ngSwitchCase="'curated'">
      <!-- TODO: this can be simplified by having one component as soon as we use only one API -->
      <app-curated-lists />
    </ng-container>
    <app-ye-loading-spinner *ngSwitchDefault />
  </ng-container>
</div>