<div class="tab-navigation mb-5">
  <div class="welcome is-flex is-justify-content-center is-align-items-center">
    <p class="welcome-text">
      <ng-container *ngIf="(user$ | async)?.firstname as firstname; else namaste"> Namasté {{ firstname }}! </ng-container>
      <ng-template #namaste> Namasté! </ng-template>
    </p>
  </div>

  <ul class="ye-tabs is-flex is-flex-wrap-nowrap" role="tablist">
    <li id="active-bar"></li>
    <li *ngFor="let tab of tabList" [id]="tab.id" class="ye-tab is-flex-fill" role="presentation">
      <a class="no-link is-block" [routerLink]="[tab.link]" i18n="@@tabNav">{ tab.label, select, Entdecken {Entdecken} Meine Programme
        {Meine Programme} Meine Videos {Meine Videos}}</a>
    </li>
  </ul>
</div>
<router-outlet></router-outlet>