import { Component } from '@angular/core';

@Component({
  selector: 'app-advanced-videos',
  template: `<app-show-more-page
    type="video"
    subPath="advanced_videos"
    categorySlug="Fortgeschrittene"
    headline="Für Fortgeschrittene"
    subheadline="Herausfordernde Sequenzen für erfahrene Yogis"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class AdvancedVideosComponent {}
