import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LoadingCircleComponent } from '../../loading-circle/loading-circle.component';

@Component({
  selector: 'app-video-teaser-ghost',
  standalone: true,
  imports: [CommonModule, LoadingCircleComponent],
  templateUrl: './video-teaser-ghost.component.html',
  styleUrls: ['./video-teaser-ghost.component.sass'],
})
export class VideoTeaserGhostComponent {}
