import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SortByOption } from 'src/app/api/models/sort-by-option';
import { CoreActions } from 'src/app/core/core.actions';
import { FilterActions } from 'src/app/filter/filter.actions';

type SelectableButton = SortByOption & { selected: boolean };

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.sass'],
})
export class SortByComponent implements OnInit {
  public buttons$: Observable<SelectableButton[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.buttons$ = combineLatest([
      this.store.select(state => state.filter.options),
      this.store.select(state => state.filter.sortBy),
    ]).pipe(
      map(([options, sortBy]) => [options?.sort_by, sortBy]),
      filter(([sortByOptions, _]) => !!sortByOptions),
      map(([sortByOptions, selectedSortByOption]) =>
        sortByOptions.map((option: SortByOption) => ({
          ...option,
          selected: selectedSortByOption === option.key,
        })),
      ),
    );
  }

  trackByKey(_, sortByOption: SortByOption): string {
    return sortByOption.key;
  }

  selectSortBy(key: string) {
    this.store
      .dispatch([
        new FilterActions.SetSortBy(key),
        new CoreActions.SetBottomsheet(null),
      ])
      .subscribe(() =>
        this.store.dispatch(new FilterActions.NavigateToFilterUrl()),
      );
  }
}
