export namespace NewsletterActions {
  export class Register {
    static readonly type = '[Newsletter] Register';
    constructor(public email: string) {}
  }

  export class OptIn {
    static readonly type = '[Newsletter] OptIn';
    constructor(public hash: string) {}
  }

  export class OptOut {
    static readonly type = '[Newsletter] OptOut';
    constructor(public uuid: string) {}
  }

  export class SetMockHash {
    static readonly type = '[Newsletter] Set mock hash';
  }

  export class FetchForm {
    static readonly type = '[Newsletter] Fetch form';
  }

  export class FetchOptIn {
    static readonly type = '[Newsletter] Fetch opt in';
  }

  export class FetchConfirm {
    static readonly type = '[Newsletter] Fetch confirm';
  }

  export class FetchOptOut {
    static readonly type = '[Newsletter] Fetch opt out';
  }

  export class ClearPage {
    static readonly type = '[Newsletter] Clear page';
  }
}
