<div class="lds-ring">
  <div
    [ngStyle]="{
      'border-color': color + ' transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-color': color + ' transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-color': color + ' transparent transparent transparent'
    }"
  ></div>
  <div
    [ngStyle]="{
      'border-color': color + ' transparent transparent transparent'
    }"
  ></div>
</div>
