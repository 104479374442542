import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CoreActions } from '../core.actions';

type Modal = {
  id: string;
  modalRef: NgbModalRef;
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // since the core.state won't accept Type<unknown>, we have to use this as workaround
  private modals: Array<Modal> = [];

  constructor(private store: Store, private ngbModalService: NgbModal, config: NgbModalConfig) {
    config.centered = true;
    config.scrollable = true;
  }

  // TODO: would be nice if we could leave out the ID and identify Modals by their class name but didn't work yet...
  public add(id: string, component: Type<unknown>, options: NgbModalOptions, inputs: { [key: string]: any }): boolean {
    if (this.modals.find(m => m.id === id)) {
      console.error(`Modal ${id} is already open!`);
      return false;
    }
    const modalRef = this.ngbModalService.open(component, options);
    if (inputs) {
      Object.keys(inputs).forEach(key => (modalRef.componentInstance[key] = inputs[key]));
    }
    modalRef.dismissed.subscribe(() => this.store.dispatch(new CoreActions.CloseModal(id)));
    this.modals.push({ id, modalRef });
    return true;
  }

  public remove(id: string): boolean {
    const index = this.modals.findIndex(m => m.id === id);
    if (index > -1) {
      this.modals[index].modalRef?.close();
      this.modals.splice(index, 1);
      return true;
    }
    return false;
  }

  public removeAll(): void {
    // the last in the array is the one on the foreground - so close this first
    this.modals.reverse();
    this.modals.forEach(m => m?.modalRef.close());
    this.modals = [];
  }
}
