import { Component } from '@angular/core';

@Component({
  selector: 'app-favoriten-videos',
  template: `<app-show-more-page
    type="video"
    subPath="bookmarked_videos"
    categorySlug="Meine Favoriten"
    headline="Meine Favoriten"
    backLink="/meine-videos"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
  ></app-show-more-page>`,
})
export class FavoritedVideosComponent {}
