import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgramTeaser } from 'src/app/api/models/program-teaser';
import { RelativeUrlPipe } from '../relative-url/relative-url.pipe';

@Component({
  selector: 'app-program-teaser',
  standalone: true,
  templateUrl: './program-teaser.component.html',
  styleUrls: ['./program-teaser.component.sass'],
  imports: [CommonModule, NgOptimizedImage, RelativeUrlPipe]
})
export class ProgramTeaserComponent {
  @Input() teaser: ProgramTeaser;
}
