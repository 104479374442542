import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-join-program-success-modal-content',
  templateUrl: './join-program-success-modal-content.component.html',
  styleUrls: ['./join-program-success-modal-content.component.sass'],
})
export class JoinProgramSuccessModalContentComponent {
  public static readonly MODAL_ID = 'JoinProgramSuccessModalContent';
  @Input() text;
  @Input() link;
}
