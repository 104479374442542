import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
  NgxsRouterPluginModule,
  RouterStateSerializer,
} from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppRoutingSerializer } from './app-routing.serializer';
import { AppComponent } from './app.component';
import { BookmarksModule } from './bookmarks/bookmarks.module';
import { CoreModule } from './core/core.module';
import { CurrentPathInterceptor } from './core/current-path/current-path.interceptor';
import { LowerCaseUrlSerializer } from './core/lower-case-url-serializer/lower-case-url.serializer';
import { RedirectionInterceptor } from './core/redirection-interceptor/redirection.interceptor';
import { DashboardModule } from './dashboard/dashboard.module';
import { HomepageModule } from './homepage/homepage.module';
import { NewsletterState } from './newsletter/newsletter.state';
import { FullscreenOverlayComponent } from './shared/fullscreen-overlay/fullscreen-overlay.component';
import { UserState } from './user/user.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    /** NewsletterState has to be loaded here because of the registration form in the footer  */
    NgxsModule.forRoot([NewsletterState, UserState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    MatFormFieldModule,
    MatInputModule,
    CoreModule,
    DashboardModule,
    HomepageModule,
    BookmarksModule,
    FullscreenOverlayComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectionInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: environment.tagManagerId },
    { provide: RouterStateSerializer, useClass: AppRoutingSerializer },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrentPathInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        /** do we want to show a dialog (that something failed) to the user? */
        /** NOTE: it's not pleasant feeling seeing tens of dialogs! */
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
