<div class="is-hidden-mobile">
  <app-page-header title="Live classes">
    Practice live with your favourite teachers and enjoy an in-person class from the comfort of your own home. Work on strength and mobility
    with Vinyasa or Hatha, relax deeply with a Yin or Restorative yoga class, or gain clarity and focus through meditation and breath work.
  </app-page-header>
  <div class="is-position-relative is-flex is-justify-content-center">
    <img ngSrc="production/uploads/components/live-classes-page/online-live-classes-banner-mobile.jpg" alt="Yogaeasy Academy" width="343"
      height="336" class="img-mobile has-border-radius-5" />
    <!-- without width: 100% on tablet and up, Safari won't show the full resolution -->
    <img ngSrc="production/uploads/components/live-classes-page/online-live-classes-banner-tablet.jpg" alt="Yogaeasy Academy" width="730"
      height="383" class="img-tablet-only is-width-100 has-border-radius-5" />
    <img ngSrc="production/uploads/components/live-classes-page/online-live-classes-banner.jpg" alt="Yogaeasy Academy" width="1170"
      height="300" class="img-desktop has-border-radius-5" />
  </div>
</div>
<div class="is-hidden-tablet">
  <div class="is-width-100 has-background-image">
    <app-h1-deco class="has-text-centered has-text-deep-sea-green" title="Live classes"></app-h1-deco>
  </div>
  <p class="is-size-18px m-4-mobile has-text-kokoda">
    Practice live with your favourite teachers and enjoy an in-person class from the comfort of your own home. Work on strength and mobility
    with Vinyasa or Hatha, relax deeply with a Yin or Restorative yoga class, or gain clarity and focus through meditation and breath work.
  </p>
</div>
<div class="mx-5">
  <div class="is-size-1-6rem has-text-deep-sea-green has-text-weight-bold pt-4-mobile mt-56px-tablet">Timetable for your Live
    classes
  </div>
  <p class="mt-4-mobile has-text-kokoda is-size-18px"><b>Please note:</b> All class times appear in CET.</p>

  <div class="has-text-kokoda pb-6-tablet">
    @if(liveClasses$ | async){
    <ul>
      @for(startDate of getFormattedDates(liveClasses$); track startDate){
      <div class="pb-4-tablet pb-5-mobile">
        <div class="mb-4 is-size-5"><b>{{startDate}}</b></div>
        @for(class of liveClasses$ | async; track class.id){
        @if(utility.areDatesSame(class.scheduled_at, startDate)){
        <li
          class="is-flex has-background-pearl-bush is-width-100-tablet is-height-160px-tablet is-justify-content-space-between mb-4 has-border-radius-5 is-align-items-center is-flex-direction-column-mobile my-4-mobile py-4-tablet">
          <div class="is-flex is-flex-direction-column-mobile is-width-100-mobile  p-4-mobile">
            <div
              class="is-flex is-flex-direction-column is-justify-content-center ml-40px-tablet is-align-self-center-mobile is-align-items-center  mb-2-mobile is-min-width-130px">
              <img height="96" width="96" [src]="class?.teacher_image?.big_thumb" class="has-border-radius-6" />
              <p class="is-line-height-26px  mt-2">{{class.teacher_name}}</p>
            </div>
            <div class="ml-4-tablet is-line-height-26px">
              <div class="is-size-18px has-text-weight-bold ">{{class.title}}</div>
              <div>{{utility.getFormattedTime(class.scheduled_at)}} - {{utility.calculateEndTime(class.scheduled_at, class.duration)}}
              </div>
              <div>Level: {{class.level}}</div>
              <div>Style: {{class.style}}</div>
              @if(class.specific_uses.length > 0){
              <div>Specific use: {{class.specific_uses}}</div>
              }
            </div>
          </div>
          <app-button type="secondary" (onClick)="joinClass(class.id)" class="mr-40px-tablet mb-4-mobile">Join</app-button>
        </li>
        }
        }
      </div>
      }

    </ul>
    }
  </div>
  <div class="is-flex is-justify-content-center mt-4-tablet mb-5-and-half-mobile mb-40px-tablet">
    <app-button type="primary"
      href="https://support.yogaeasy.de/hc/en-001/categories/4909489243921-YogaEasy-live-classes-workshops">FAQ</app-button>
  </div>
</div>