<input type="text" style="display: none" />
<!-- ↑ to avoid autofocus on other elements -->
<ul class="edit-bookmarks-list-menu">
  <li class="menu-item">
    <app-icon-button-label i18n-label label="Namen ändern" (onClick)="changeName()">
      <svg-edit></svg-edit>
    </app-icon-button-label>
  </li>
  <li class="menu-item">
    <app-icon-button-label i18n-label label="Liste löschen" (onClick)="deleteList()">
      <svg-delete></svg-delete>
    </app-icon-button-label>
  </li>
</ul>
<a class="close is-hidden-mobile is-block-tablet is-hidden-desktop" (click)="close()">
  <svg-exit class="close-icon"></svg-exit>
</a>