import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fullscreen-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fullscreen-overlay.component.html',
  styleUrls: ['./fullscreen-overlay.component.sass'],
})
export class FullscreenOverlayComponent {
  @Input() slideIn?: 'left' | 'right' | 'top' | 'bottom';
  @Input() open = false;
}
