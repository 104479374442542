import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeUrl',
  standalone: true
})
export class RelativeUrlPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (!value) return '';
    if (!value.startsWith('http') && !value.startsWith('www')) return value;

    try {
      const url = new URL(value);
      return url?.pathname?.replace(/^\//g, '');
    } catch (e) {
      console.error(`${e}: ${value}`);
      return value;
    }
  }
}
