import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { QuoteSvg } from 'src/app/svg/quote/quote.svg';
import { StarSvg } from 'src/app/svg/star/star.svg';

@Component({
  selector: 'app-rating-tile',
  standalone: true,
  imports:  [CommonModule, QuoteSvg, StarSvg],
  templateUrl: './rating-tile.component.html',
  styleUrls: ['./rating-tile.component.sass'],
})
export class RatingTileComponent {
  @Input() minHeight?: number;
}
