import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-circle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.sass'],
})
export class LoadingCircleComponent {
  @Input() color = '#009090';
}
