import { Component } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.sass'],
})
export class SearchButtonComponent {
  public active = false;
  public searchTerm = '';
  public isLoading = false;

  constructor(private store: Store) {}

  public executeSearch(t: NgbTooltip) {
    this.isLoading = true;
    const encodedSearchTerm = encodeURIComponent(this.searchTerm);
    this.store
      .dispatch(
        new Navigate(['/', 'search'], { q: this.searchTerm, utf8: '✓' }),
      )
      .pipe(take(1))
      .subscribe(() => {
        t.close();
        this.searchTerm = '';
        this.isLoading = false;
      });
  }
}
