import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VideoTeasersSlider } from 'src/app/api/models/video-teasers-slider';
import { DashboardActions } from '../dashboard.actions';
import { TeaserList } from '../dashboard.state';

@Component({
  selector: 'app-show-more-page',
  templateUrl: './show-more-page.component.html',
  styleUrls: ['./show-more-page.component.sass'],
})
export class ShowMorePageComponent implements OnInit, OnDestroy {
  @Select(state => state.dashboard?.fullTeaserList)
  teaserList$: Observable<TeaserList>;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  teaserList: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() type: 'video'; // TODO: currently this works for videos only!
  @Input() subPath: string;
  @Input() categorySlug: string;
  @Input() headline: string;
  @Input() subheadline: string;
  @Input() backLink = '/';
  limit = 20;
  page = 0;
  videosCount = 0;
  loading = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.addTeasers(this.limit, 0);
    this.teaserList$.pipe(takeUntil(this.destroy$)).subscribe(teaserList => {
      if (teaserList) {
        if (this.type === 'video') {
          if (this.page === 0) {
            this.teaserList = <VideoTeasersSlider>{
              videos: [],
              videos_count: 0,
            };
          }
          // biome-ignore lint/complexity/useLiteralKeys: at this point, only videos are returned
          this.videosCount = teaserList['videos_count'];
          this.teaserList.videos = this.teaserList.videos.concat(
            // biome-ignore lint/complexity/useLiteralKeys: TODO: adapt the other APIs to return type TeaserSlider
            teaserList['videos'],
          );
          this.page++;
        } else {
          this.teaserList = teaserList;
        }
        this.loading = false;
      }
    });
  }

  addTeasers(limit, page) {
    this.loading = true;
    this.store.dispatch(
      new DashboardActions.FetchFullTeaserList(
        this.type,
        this.subPath,
        limit,
        page,
      ),
    );
  }

  onScroll() {
    if (this.page * this.limit < this.videosCount && !this.loading) {
      this.addTeasers(this.limit, this.page);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
