import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { constants } from 'src/app/constants';
import { CoreActions } from '../core.actions';
import { Viewport } from '../core.state';

@Injectable({
  providedIn: 'root',
})
export class ViewportService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  // TODO: inject Window here
  constructor(private store: Store) {}

  init() {
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroy$),
        startWith({ currentTarget: window }),
        debounceTime(100),
        withLatestFrom(this.store.select(state => state.core.viewport)),
      )
      .subscribe(([event, currentViewport]) => {
        const windowWidth = (event.currentTarget as Window)?.innerWidth;
        if (!windowWidth) return;

        let newViewport: Viewport;
        if (windowWidth >= constants.breakpoints.xlarge) {
          newViewport = 'desktop';
        } else if (windowWidth >= constants.breakpoints.medium) {
          newViewport = 'tablet';
        } else {
          newViewport = 'mobile';
        }

        if (newViewport !== currentViewport) {
          this.store.dispatch(new CoreActions.ViewportChanged(newViewport));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
