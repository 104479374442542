import { SortByOption } from '../api/models/sort-by-option';

export namespace BookmarksActions {
  export class CreateList {
    static readonly type = '[Bookmarks] Create list';
    constructor(public name: string) {}
  }

  export class FetchList {
    static readonly type = '[Bookmarks] Fetch list';
    constructor(public id: number, public sortBy?: SortByOption, public limit?: number) {}
  }

  export class FetchLists {
    static readonly type = '[Bookmarks] Fetch lists';
    constructor(public limit?: number) {}
  }

  export class RenameList {
    static readonly type = '[Bookmarks] Rename list';
    constructor(public id: number, public newName: string) {}
  }

  export class DeleteList {
    static readonly type = '[Bookmarks] Delete list';
    constructor(public id: number) {}
  }

  export class FetchItemLists {
    static readonly type = '[Bookmarks] Fetch item lists';
    constructor(public itemId: number) {}
  }

  export class RefetchItemLists {
    static readonly type = '[Bookmarks] Refetch item lists';
    constructor(public itemId: number) {}
  }

  export class AddItemToList {
    static readonly type = '[Bookmarks] Add item to list';
    constructor(public itemId: number, public listId: number) {}
  }

  export class RemoveItemFromList {
    static readonly type = '[Bookmarks] Remove item from list';
    constructor(public itemId: number, public listId: number) {}
  }

  export class UpdateItemPosition {
    static readonly type = '[Bookmarks] Update item position';
    constructor(public listId: number, public itemId: number, public target: number) {}
  }
}
