import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckmarkSvg } from 'src/app/svg/checkmark/checkmark.svg';

@Component({
  selector: 'app-clickable-chip',
  standalone: true,
  imports: [CommonModule, FormsModule, CheckmarkSvg],
  templateUrl: './clickable-chip.component.html',
  styleUrls: ['./clickable-chip.component.sass'],
})
export class ClickableChipComponent {
  @Input() label: string;
  @Input() model: boolean;
  @Input() disabled = false;
  @Output() modelChange = new EventEmitter<boolean>();
}
