import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SiteParams } from 'src/app/api/models/site-params';

@Component({
  selector: 'app-cta-button-section',
  templateUrl: './cta-button-section.component.html',
  styleUrls: ['./cta-button-section.component.sass'],
})
export class CtaButtonSectionComponent implements OnInit {
  public siteParams$: Observable<SiteParams>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.siteParams$ = this.store.select(state => state.core.siteParams);
  }

  defaultCTAText(): string {
    return $localize`:@@buttonSectionCTA:Jetzt anmelden`;
  }
}
