import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'bypassSecurity',
  standalone: true,
})
export class BypassSecurityPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  public transform(
    // rome-ignore lint/suspicious/noExplicitAny: first param of a pipe is always 'any'
    value: any,
    type: string,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.domSanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.domSanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.domSanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.domSanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(
          `Invalid trust type specified: ${type} - allowed types: html, style, script, url, resourceUrl`,
        );
    }
  }
}
