import { CommonModule, IMAGE_LOADER, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ArticleTeaser } from 'src/app/api/models/article-teaser';
import { imageKitLoaderWithFallback } from 'src/app/core/image-kit-loader-with-fallback/image-kit-loader-with-fallback';
import { ArrowSvg } from 'src/app/svg/arrow/arrow.svg';
import { Utility } from '../utility';

@Component({
  selector: 'app-article-teaser',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ArrowSvg],
  templateUrl: './article-teaser.component.html',
  styleUrls: ['./article-teaser.component.sass'],
  providers: [
    {
      provide: IMAGE_LOADER,
      useValue: imageKitLoaderWithFallback,
    },
  ],
})
export class ArticleTeaserComponent {
  @Input()
  set teaser(teaser: ArticleTeaser) {
    this._teaser = teaser;
    this.imagePath = Utility.getRelativePath(
      teaser?.image_url || teaser?.images?.large?.replace('/large_', '/'),
    );
  }
  get teaser(): ArticleTeaser {
    return this._teaser;
  }
  private _teaser: ArticleTeaser;
  public imagePath: string;
  @Input() showImg = false;
  @Input() largeOnDesktop = false;
}
