import { Component } from '@angular/core';

@Component({
  selector: 'app-fitness-videos',
  template: `<app-show-more-page
    type="video"
    subPath="fitness_videos"
    categorySlug="Fit-Werden"
    headline="Fit werden"
    subheadline="Finde Sequenzen, die dich herausfordern"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class FitnessVideosComponent {}
