import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CoreActions } from 'src/app/core/core.actions';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgOptimizedImage],
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.sass'],
})
export class NotFoundPageComponent implements OnInit, OnDestroy {
  public searchTerm = new UntypedFormControl('');

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(
      new CoreActions.AddMetaTags([
        { name: 'prerender-status-code', content: '404' },
      ]),
    );
  }

  onSubmit() {
    // TODO: remove reference to window.location and inject it
    location.replace(
      `/search?q=${encodeURIComponent(this.searchTerm.value)}&utf8=%E2%9C%93`,
    );
  }

  ngOnDestroy() {
    this.store.dispatch(
      new CoreActions.RemoveMetaTags(['prerender-status-code']),
    );
  }
}
