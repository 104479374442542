import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreActions } from '../core/core.actions';
import { HomepageActions } from './homepage.actions';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.sass'],
})
export class HomepageComponent implements OnInit {
  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new HomepageActions.FetchParams());
    this.store.dispatch(new CoreActions.FetchSeoData());
  }
}
