import {
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, combineLatest, fromEvent } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { SiteParams } from 'src/app/api/models/site-params';
import { User } from 'src/app/api/models/user';
import { RouterStateParams } from 'src/app/app-routing.serializer';
import { RoutesWithPathMatch, Utility } from 'src/app/shared/utility';
import { CoreActions } from '../core.actions';

// TODO: use route.data for this: https://angular.io/api/router/Route#data
const HIDE_ON_ROUTES: RoutesWithPathMatch = [
  { path: '/free', pathMatch: 'prefix' },
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Select(state => state.core.siteParams) siteParams$: Observable<SiteParams>;
  private scroll$: Observable<Event>;
  private routerState$: Observable<RouterStateParams>;
  private user$: Observable<User>;
  public hideNavbar$: Observable<boolean>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isLoggedIn: boolean;
  public shadowShown = false;
  public ctaButtonShown = false;
  public liveClassesLink: string;

  constructor(
    private store: Store,
    private window: Window,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit() {
    this.liveClassesLink = $localize`/live-klassen`;
    this.scroll$ = fromEvent(this.window, 'scroll').pipe(
      startWith(new CustomEvent('scroll')),
    );
    this.routerState$ = this.store.select(state => state.router.state);
    this.user$ = this.store.select(state => state.user.user);
    this.hideNavbar$ = this.routerState$.pipe(
      map(routerState => {
        return Utility.routeMatches(routerState?.url, HIDE_ON_ROUTES);
      }),
      tap(hideNavbar => {
        if (hideNavbar) {
          this.renderer.removeClass(window.document.body, 'with-navbar');
        } else {
          this.renderer.addClass(window.document.body, 'with-navbar');
        }
      }),
    );

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.checkUserLoggedIn(user));

    this.scroll$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => this.checkShadowShown(this.window.scrollY));

    combineLatest([
      this.store.select(state => state.router.state),
      this.scroll$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([routerState, _]) =>
        this.checkCtaButtonShown(routerState?.url, this.window.scrollY),
      );
  }

  checkShadowShown(scrollY: number) {
    this.shadowShown = scrollY > 5;
  }

  checkUserLoggedIn(user: User) {
    if (user === undefined) this.isLoggedIn = undefined;
    else if (user === null) this.isLoggedIn = false;
    else this.isLoggedIn = true;
  }

  checkCtaButtonShown(url: string, scrollY: number) {
    this.ctaButtonShown = url !== '/' || scrollY > 220;
  }

  openMobileNav() {
    this.store.dispatch(new CoreActions.OpenMobileNavigation());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
