import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';

@Component({
  selector: 'app-bookmarks-list-teaser',
  standalone: true,
  templateUrl: './bookmarks-list-teaser.component.html',
  styleUrls: ['./bookmarks-list-teaser.component.sass'],
  imports: [CommonModule],
})
export class BookmarksListTeaserComponent {
  @Input() teaser: BookmarksList;
}
