import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IsForArticleGuard {
  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return state.url.includes('/artikel/');
  }
}
