import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortByOption } from 'src/app/api/models/sort-by-option';
import { CoreActions } from 'src/app/core/core.actions';
import { Viewport } from 'src/app/core/core.state';
import { SortBookmarksListComponent } from '../sort-bookmarks-list/sort-bookmarks-list.component';

@Component({
  selector: 'app-sort-bookmarks-list-wrapper',
  templateUrl: './sort-bookmarks-list-wrapper.component.html',
  styleUrls: ['./sort-bookmarks-list-wrapper.component.sass'],
})
export class SortBookmarksListWrapperComponent implements OnInit, OnDestroy {
  @Select(state => state.bookmarks?.selectedSortOption)
  selectedSortOption$: Observable<SortByOption>;
  selectedSortLabel = $localize`Sortieren nach...`;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private popoverOpen$: Subject<boolean> = new Subject<boolean>();
  public buttonActivated$: Observable<boolean>;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedSortOption$
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedSortOption => {
        this.selectedSortLabel =
          selectedSortOption?.label || this.selectedSortLabel;
      });
  }

  openSortMenu(popover: NgbPopover) {
    const viewport: Viewport = this.store.selectSnapshot(
      state => state.core.viewport,
    );

    switch (viewport) {
      case 'desktop':
        popover.open();
        break;
      case 'tablet':
        this.store.dispatch(
          new CoreActions.OpenModal(
            SortBookmarksListComponent.MODAL_ID,
            SortBookmarksListComponent,
          ),
        );
        break;
      default:
        this.store.dispatch(
          new CoreActions.SetBottomsheet(SortBookmarksListComponent),
        );
    }
  }

  popoverShown() {
    this.popoverOpen$.next(true);
  }

  popoverHidden() {
    this.popoverOpen$.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
