import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Fetching } from '../api/models/fetching';
import { FilterId } from '../api/models/filter-id';
import { FilterOptions } from '../api/models/filter-options';
import { FilterOptionsResponse } from '../api/models/filter-options-response';
import { FilterParams } from '../api/models/filter-params';
import { VideoTeasersSlider } from '../api/models/video-teasers-slider';
import { FilterService } from '../api/services/filter.service';
import { Utility } from '../shared/utility';
import { FilterActions } from './filter.actions';

export type MobileFilterScreenState = FilterId | 'list';
export const FILTER_LIST_LIMIT = 40;

export interface FilterStateModel {
  filteredTeasers: Fetching<VideoTeasersSlider>;
  mobileFilterOpen: MobileFilterScreenState;
  openedFilterIds: Array<string>;
  options: FilterOptions;
  selected: FilterParams;
  selectedVideoCount: Fetching<number>;
  sortBy: string;
  tooltipOpen: boolean;
}

@State<FilterStateModel>({
  name: 'filter',
  defaults: {
    filteredTeasers: {
      fetching: false,
      value: undefined,
      error: null,
    },
    mobileFilterOpen: null,
    options: undefined,
    openedFilterIds: undefined,
    selected: {},
    selectedVideoCount: {
      fetching: false,
      value: null,
      error: null,
    },
    sortBy: undefined,
    tooltipOpen: false,
  },
})
@Injectable()
export class FilterState {
  private tooltipOpenTimer: ReturnType<typeof setTimeout>;
  constructor(private filterService: FilterService) {}

  @Action(FilterActions.FetchFilteredList)
  fetchFilteredList(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.FetchFilteredList,
  ) {
    if (ctx.getState().filteredTeasers.fetching) return EMPTY;
    ctx.patchState({
      filteredTeasers: { fetching: true, value: undefined, error: undefined },
    });
    const { selected, sortBy } = ctx.getState();
    return this.filterService
      .getVideos(selected, sortBy, action.limit, action.page)
      .pipe(
        tap(teasers =>
          ctx.patchState({
            filteredTeasers: {
              fetching: false,
              value: teasers,
              error: null,
            },
          }),
        ),
        catchError(error => {
          ctx.patchState({
            filteredTeasers: {
              fetching: false,
              value: null,
              error,
            },
          });
          return EMPTY;
        }),
      );
  }

  @Action(FilterActions.FetchOptions)
  fetchOptions(ctx: StateContext<FilterStateModel>, _) {
    const selected = ctx.getState().selected;
    const selectedEmpty = Utility.objectIsEmpty(selected);
    ctx.patchState({ selectedVideoCount: { fetching: true } });

    return this.filterService.getOptions(selected).pipe(
      tap((response: FilterOptionsResponse) => {
        ctx.patchState({
          options: response.options,
          selectedVideoCount: {
            value: selectedEmpty ? null : response.video_count,
            fetching: false,
            error: null,
          },
        });
      }),
      catchError(error => {
        ctx.patchState({ selectedVideoCount: { fetching: false, error } });
        return EMPTY;
      }),
    );
  }

  @Action(FilterActions.NavigateToFilterUrl)
  navigateToFilterUrl(ctx: StateContext<FilterStateModel>, _) {
    const { selected, sortBy } = ctx.getState();
    ctx.dispatch(new Navigate([], { ...selected, sort_by: sortBy }));
  }

  @Action(FilterActions.Reset)
  reset(ctx: StateContext<FilterStateModel>, action: FilterActions.Reset) {
    const selected = { ...ctx.getState().selected };
    selected[action.filterId] = [];
    ctx.patchState({ selected });
    ctx.dispatch(new FilterActions.FetchOptions());
  }

  @Action(FilterActions.ResetAll)
  resetAll(ctx: StateContext<FilterStateModel>, _) {
    ctx.patchState({ mobileFilterOpen: null, selected: {} });
    ctx.dispatch(new Navigate([]));
  }

  @Action(FilterActions.SetAllParams)
  setAllParams(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.SetAllParams,
  ) {
    ctx.patchState({ selected: action.params });
    ctx.dispatch(new FilterActions.FetchOptions());
  }

  @Action(FilterActions.SetParam)
  setParam(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.SetParam,
  ) {
    const selected = { ...ctx.getState().selected };
    let selectedIds = selected[action.filterId]
      ? [...selected[action.filterId]]
      : [];

    if (action.selected) {
      selectedIds.push(action.optionId);
    } else {
      selectedIds = selectedIds.filter(
        optionId => optionId !== action.optionId,
      );
    }

    selected[action.filterId] = selectedIds;
    ctx.patchState({ selected });
    ctx.dispatch(new FilterActions.FetchOptions());
  }

  @Action(FilterActions.SetSortBy)
  setSortBy(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.SetSortBy,
  ) {
    ctx.patchState({
      ...ctx.getState().selected,
      sortBy: action.key,
    });
  }

  @Action(FilterActions.SetTooltipOpen)
  setTooltipOpen(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.SetTooltipOpen,
  ) {
    if (action.open) {
      ctx.patchState({ tooltipOpen: true });
      clearTimeout(this.tooltipOpenTimer);
    } else {
      this.tooltipOpenTimer = setTimeout(() => {
        ctx.patchState({ tooltipOpen: false });
      }, 100);
    }
  }

  @Action(FilterActions.SetMobileFilterState)
  setOpenMobileFilter(
    ctx: StateContext<FilterStateModel>,
    action: FilterActions.SetMobileFilterState,
  ) {
    ctx.patchState({ mobileFilterOpen: action.mobileFilterState });
  }
}
