import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HomepageParams } from '../api/models/home-params';
import { HomepageService } from '../api/services/homepage.service';
import { HomepageActions } from './homepage.actions';

export interface HomepageStateModel {
  params: HomepageParams;
}

@State<HomepageStateModel>({
  name: 'homepage',
  defaults: {
    params: undefined,
  },
})
@Injectable()
export class HomepageState {
  constructor(private homepageService: HomepageService) {}

  @Action(HomepageActions.FetchParams)
  fetchParams(ctx: StateContext<HomepageStateModel>, _) {
    return this.homepageService
      .getParams()
      .pipe(
        tap((homepageParams) => ctx.patchState({ params: homepageParams })),
      );
  }
}
