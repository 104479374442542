<div class="sort-by-wrapper" (click)="openSortMenu(popover)">
  <span class="selected">{{ selectedSortLabel }}</span>
  <app-icon-button
    [activated]="buttonActivated$ | async"
    triggers="manual"
    [ngbPopover]="sortBookmarksList"
    placement="bottom-right"
    #popover="ngbPopover"
    (onClick)="openSortMenu(popover)"
    (shown)="popoverShown()"
    (hidden)="popoverHidden()"
  >
    <svg-arrow class="arrow"></svg-arrow>
  </app-icon-button>
  <ng-template #sortBookmarksList><app-sort-bookmarks-list></app-sort-bookmarks-list></ng-template>
</div>
