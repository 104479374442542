<div class="is-inline-block is-position-relative" *ngIf="largeText && smallText">
  <svg class="is-hidden-tablet" width="83" height="86" viewBox="0 0 83 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3671 74.4494C-1.39551 65.1265 -5.34026 41.5075 8.34358 20.7208C29.3604 -11.1759 52.1286 2.37851 62.195 5.71653C76.7137 10.5213 81.0676 22.9293 82.5244 34.7135C85.2743 56.8995 76.0753 79.6419 55.5659 84.3286C32.9941 89.4874 26.676 81.7155 14.3671 74.4494Z"
      fill="#00A7A7" />
  </svg>
  <svg class="is-hidden-mobile" width="168" height="173" viewBox="0 0 168 173" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0513 149.005C-2.82183 130.346 -10.7984 83.0742 16.8713 41.4711C59.3687 -22.3677 105.408 4.76042 125.763 11.4412C155.12 21.0575 163.924 45.8912 166.87 69.4764C172.43 113.88 153.83 159.397 112.358 168.777C66.7164 179.102 53.9407 163.547 29.0513 149.005Z"
      fill="#00A7A7" />
  </svg>
  <div class="is-position-absolute is-all-0 is-flex is-flex-direction-column is-align-items-center has-text-white has-text-centered">
    <div class="mt-2-mobile mt-4-tablet is-size-26px-mobile is-size-55px-tablet is-line-height-125 has-text-weight-bold">
      {{ largeText }}</div>
    <div class="px-2-mobile px-4-tablet is-size-18px-mobile is-size-30px-tablet is-line-height-90 has-text-weight-semibold">
      {{ smallText }}</div>
  </div>
</div>