import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HomepageParams } from '../models/home-params';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  constructor(private httpClient: HttpClient) {}
  path = `${environment.apiPath}/v1/homepage`;

  getParams(): Observable<HomepageParams> {
    return this.httpClient
      .get<HomepageParams>(this.path)
      .pipe(catchError(this.handleError));
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.error.message}`;
    }

    return throwError(errorMessage);
  }
}
