import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BypassSecurityPipe } from '../bypass-security.pipe';

@Component({
  selector: 'app-h1-deco',
  standalone: true,
  imports: [CommonModule, BypassSecurityPipe],
  templateUrl: './h1-deco.component.html',
  styleUrls: ['./h1-deco.component.sass'],
})
export class H1DecoComponent {
  @Input() title: string;
}
