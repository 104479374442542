import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LiveClass } from '../models/live-class';

@Injectable({
  providedIn: 'root',
})
export class LiveClassesService {
  path = `${environment.apiPath}/v1/live_classes`;

  constructor(private httpClient: HttpClient) {}

  fetchAll(): Observable<LiveClass[]> {
    return this.httpClient
      .get<LiveClass[]>(this.path)
      .pipe(catchError(this.handleError));
  }

  requestLiveClassLink(id: number) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    return this.httpClient.post<any>(`${this.path}/${id}/book`, {});
  }

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof Error) {
      errorMessage = `Error occurred ${err.error.message}`;
    } else {
      errorMessage = `System Error occurred ${err.error.message}`;
    }
    return throwError(errorMessage);
  }
}
