import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { FilterActions } from 'src/app/filter/filter.actions';
import { FILTER_LIST_LIMIT } from 'src/app/filter/filter.state';

const MAX_VIDEOS = 8;

// TODO: this is a mess!!! make it reactive and put page in the store
@Component({
  selector: 'app-filtered-list',
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.sass'],
})
export class FilteredListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public loading$: Observable<boolean>;

  displayedTeasers: Array<VideoTeaser> = [];
  showButton = false;
  firstTimeShowMore = true;
  limit = FILTER_LIST_LIMIT;
  page = 0;
  videosCount = 0;

  constructor(private store: Store) {}

  ngOnInit() {
    this.loading$ = this.store
      .select(state => state.filter.filteredTeasers)
      .pipe(map(filteredTeasers => filteredTeasers?.fetching));

    this.store
      .select(state => state.router.state)
      .pipe(takeUntil(this.destroy$))
      .subscribe(queryParams => {
        this.page = 0;
        this.displayedTeasers = [];
      });

    this.store
      .select(state => state.filter.filteredTeasers)
      .pipe(
        takeUntil(this.destroy$),
        map(filteredTeasers => filteredTeasers?.value),
        filter(filteredTeaserList => !!filteredTeaserList),
      )
      .subscribe(filteredTeaserList => {
        this.videosCount = filteredTeaserList.videos_count;
        if (this.page === 0 && filteredTeaserList.videos.length > MAX_VIDEOS) {
          this.displayedTeasers = filteredTeaserList.videos.slice(
            0,
            MAX_VIDEOS,
          );
          this.showButton = true;
        } else {
          this.displayedTeasers = this.displayedTeasers.concat(
            filteredTeaserList.videos,
          );
          if (
            filteredTeaserList.videos.length < MAX_VIDEOS ||
            this.displayedTeasers.length === this.videosCount
          )
            this.showButton = false;
        }
        this.page++;
      });
  }

  showMoreButton() {
    this.displayedTeasers = this.store.selectSnapshot(
      state => state.filter.filteredTeasers.value.videos,
    );
    if (!this.firstTimeShowMore && this.page * this.limit < this.videosCount) {
      this.store.dispatch(
        new FilterActions.FetchFilteredList(this.limit, this.page),
      );
    }
    if (this.displayedTeasers.length === this.videosCount)
      this.showButton = false;

    this.firstTimeShowMore = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
