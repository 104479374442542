<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.6549 47.1502C2.15933 41.6216 -0.217022 27.6149 8.02625 15.2879C20.687 -3.62755 34.4028 4.41051 40.4669 6.39004C49.2131 9.23935 51.8359 16.5976 52.7135 23.5859C54.37 36.7427 48.8285 50.2295 36.4734 53.0088C22.876 56.0681 19.0699 51.4592 11.6549 47.1502Z"
    fill="#F2EBE4"
  />
  <path
    d="M33.5173 24.8753C33.5173 24.6682 33.3793 24.3921 33.1724 24.3231L25.5862 19.4915C25.3793 19.3534 25.1035 19.3534 24.8966 19.4915C24.6897 19.5605 24.5518 19.7676 24.5518 20.0437V29.707C24.5518 29.9831 24.6897 30.1901 24.8966 30.3282C25.0345 30.3972 25.1035 30.3972 25.2414 30.3972C25.3793 30.3972 25.5173 30.3972 25.5862 30.2591L33.1724 25.4275C33.3793 25.3585 33.5173 25.0824 33.5173 24.8753ZM25.9311 28.4645V21.2861L31.5173 24.8753L25.9311 28.4645Z"
    fill="#0E5C63"
  />
  <path
    d="M47.3103 9H40.4138H15.5862H8.68966C8.27586 9 8 9.27609 8 9.69024V17.2828V24.8754V32.468V40.0606C8 40.4057 8.27586 40.7508 8.68966 40.7508H15.5862H28C28.3448 40.7508 28.6897 40.4057 28.6897 40.0606C28.6897 39.7155 28.3448 39.3704 28 39.3704H16.2759V32.468V24.8754V17.2828V10.3805H39.7241V17.2828V24.8754C39.7241 25.2205 40.069 25.5657 40.4138 25.5657H46.6207V31.0875C46.6207 31.4327 46.9655 31.7778 47.3103 31.7778C47.6552 31.7778 48 31.4327 48 31.0875V24.8754V17.2828V9.69024C48 9.27609 47.6552 9 47.3103 9ZM9.37931 25.5657H14.8966V31.7778H9.37931V25.5657ZM14.8966 24.1852H9.37931V17.9731H14.8966V24.1852ZM9.37931 39.3704V33.1583H14.8966V39.3704H9.37931ZM14.8966 16.5926H9.37931V10.3805H14.8966V16.5926ZM46.6207 10.3805V16.5926H41.1034V10.3805H46.6207ZM41.1034 24.1852V17.9731H46.6207V24.1852H41.1034Z"
    fill="#0E5C63"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M31 39.5333C31 34.8205 34.8205 31 39.5333 31C44.2462 31 48.0667 34.8205 48.0667 39.5333C48.0667 44.2462 44.2462 48.0667 39.5333 48.0667C34.8205 48.0667 31 44.2462 31 39.5333ZM46.9286 39.5333C46.9286 35.4489 43.6175 32.1378 39.5331 32.1378C35.4486 32.1378 32.1375 35.4489 32.1375 39.5333C32.1375 43.6178 35.4486 46.9289 39.5331 46.9289C43.6175 46.9289 46.9286 43.6178 46.9286 39.5333Z"
    fill="#0E5C63"
  />
  <path
    d="M39.5333 30.9C34.7653 30.9 30.9 34.7653 30.9 39.5333H31.1C31.1 34.8757 34.8757 31.1 39.5333 31.1V30.9ZM48.1667 39.5333C48.1667 34.7653 44.3014 30.9 39.5333 30.9V31.1C44.1909 31.1 47.9667 34.8757 47.9667 39.5333H48.1667ZM39.5333 48.1667C44.3014 48.1667 48.1667 44.3014 48.1667 39.5333H47.9667C47.9667 44.1909 44.1909 47.9667 39.5333 47.9667V48.1667ZM30.9 39.5333C30.9 44.3014 34.7653 48.1667 39.5333 48.1667V47.9667C34.8757 47.9667 31.1 44.1909 31.1 39.5333H30.9ZM39.5331 32.2378C43.5623 32.2378 46.8286 35.5041 46.8286 39.5333H47.0286C47.0286 35.3937 43.6727 32.0378 39.5331 32.0378V32.2378ZM32.2375 39.5333C32.2375 35.5041 35.5038 32.2378 39.5331 32.2378V32.0378C35.3934 32.0378 32.0375 35.3937 32.0375 39.5333H32.2375ZM39.5331 46.8289C35.5038 46.8289 32.2375 43.5626 32.2375 39.5333H32.0375C32.0375 43.673 35.3934 47.0289 39.5331 47.0289V46.8289ZM46.8286 39.5333C46.8286 43.5626 43.5623 46.8289 39.5331 46.8289V47.0289C43.6727 47.0289 47.0286 43.673 47.0286 39.5333H46.8286Z"
    fill="#0E5C63"
  />
  <path d="M38.7842 40.422H34.7802V39.058H38.7842V34.768H40.2142V39.058H44.2182V40.422H40.2142V44.712H38.7842V40.422Z" fill="#0E5C63" />
</svg>
