import { Component } from '@angular/core';

@Component({
  selector: 'app-live-classes-videos',
  template: `<app-show-more-page
    type="video"
    subPath="live_classes_videos"
    categorySlug="Live-Aufzeichnungen"
    headline="Live-Aufzeichnungen Videos"
    subheadline=""
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class LiveClassesVideosComponent {}
