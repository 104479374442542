export class Program {
  id: string;
  title: string;
  path: string;
  is_new: boolean;
  picture_with_text: string;
  new_program_picture: string;
  description: string;
  video_count: number;
  level: string;
  effort: string;
  stills?: string[];
  style: string;
  unit_count: number;
  private days: number;

  constructor(programObject: object) {
    Object.assign(this, programObject);
    this.days = this.unit_count;
  }

  // This logic is currently used only for DE version
  duration(): string {
    if (this.days > 14) return this.durationInWeeks();

    return this.durationInDays();
  }

  numberOfVideos(locale: string): string {
    // For DE version we just use video count number (without suffix part)
    if (locale === 'de') return this.video_count.toString();

    // for other locales we use suffix part (e.g. 1 class, 2 classes, etc.)
    if (this.video_count === 1) return `1 ${$localize`:@@video:Klasse`}`;

    return `${this.video_count} ${$localize`:@@videos:Klassen`}`;
  }

  private durationInWeeks(): string {
    return `${Math.ceil(this.days / 7)} ${$localize`:@@weeks:Wochen`}`;
  }

  private durationInDays(): string {
    if (this.days === 1) return `1 ${$localize`:@@day:Tag`}`;

    return `${this.days} ${$localize`:@@days:Tage`}`;
  }
}
