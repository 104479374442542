<section class="has-background-white">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>
      Yoga-Programme für deine persönlichen Ziele
    </ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-5">
    <ng-container i18n>
      Du möchtest eine Yoga-Morgenroutine aufbauen, mit dem Meditieren beginnen, Stress abbauen oder deine Rückenschmerzen lindern? Wähle
      aus
      über 50 Programmen jederzeit das passende für dich.
    </ng-container>
  </div>

  @if(locale==='de'){
  <owl-carousel-o class="has-dots-under" [options]="autoplay">
    <ng-container *ngFor="let teaser of programTeasers">
      <ng-template carouselSlide>
        <div class="is-flex is-justify-content-center">
          <a [href]="teaser.path">
            <!-- width and class have to be included both to overwrite .owl-item img -->
            <img [ngSrc]="teaser.mobile" width="343" height="343" [alt]="teaser.title"
              class="img-mobile has-border-radius-5 is-width-343px" />
            <img [ngSrc]="teaser.tablet" width="730" height="450" [alt]="teaser.title"
              class="img-tablet-only has-border-radius-5 is-width-1170px" />
            <img [ngSrc]="teaser.desktop" width="1170" height="418" [alt]="teaser.title" class="img-desktop has-border-radius-5" />
          </a>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  } @else {
  <owl-carousel-o class="has-dots-under" [options]="autoplay">
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/homepage/programs-section/21-mornings-with-Yoga-mobile.jpg" width="343" height="320"
          alt="Live-Classes" class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/21-mornings-with-Yoga-tablet.jpg" width="730" height="320"
          alt="Live-Classes" class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/21-mornings-with-Yoga.jpg" width="1170" height="418"
          alt="Live-Classes" class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-absolute is-bottom-141px-desktop is-left-40px-desktop is-bottom-80px-mobile is-margin-right-111px-mobile is-bottom-95px-tablet-only is-left-38px-tablet-only"
          type="primary" href="/programme/21-mornings-with-yoga">View program</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/homepage/programs-section/Just-Yin-Yoga-mobile.jpg" width="343" height="320" alt="Academy"
          class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/Just-Yin-Yoga-tablet.jpg" width="730" height="320" alt="Academy"
          class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/Just-Yin-Yoga.jpg" width="1170" height="418" alt="Academy"
          class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-fixed is-bottom-121px-desktop is-left-96px-desktop is-bottom-80px-mobile is-margin-right-123px-mobile is-bottom-94px-tablet-only is-left-40px-tablet-only"
          type="primary" href="/programme/just-yin-yoga">View program</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/homepage/programs-section/3-week-Yoga-workout-mobile.jpg" width="343" height="320"
          alt="Academy" class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/3-week-Yoga-workout-tablet.jpg" width="730" height="320"
          alt="Academy" class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/3-week-Yoga-workout.jpg" width="1170" height="418" alt="Academy"
          class="img-desktop has-border-radius-5" />
        <app-button
          class="is-position-fixed is-bottom-150px-desktop is-right-62px-desktop is-bottom-80px-mobile is-margin-left-111px-mobile is-bottom-94px-tablet-only is-left-32px-tablet-only"
          type="primary" href="/programme/3-week-yoga-workout">View program</app-button>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="is-flex is-justify-content-center">
        <!-- width and class have to be included both to overwrite .owl-item img -->
        <img ngSrc="production/uploads/components/homepage/programs-section/Yoga-for-beginners-mobile.jpg" width="343" height="320"
          alt="Academy" class="img-mobile has-border-radius-5 is-width-343px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/Yoga-for-beginners-tablet.jpg" width="730" height="320"
          alt="Academy" class="img-tablet-only has-border-radius-5 is-width-1170px" />
        <img ngSrc="production/uploads/components/homepage/programs-section/Yoga-for-beginners.jpg" width="1170" height="418" alt="Academy"
          class="img-desktop has-border-radius-5" />
        <img ngSrc="production/uploads/components/homepage/programs-section/last-slide-text.png"
          class="img-desktop is-width-446px is-position-absolute is-left-38px is-top-110px" width="446" height="180">
        <app-button
          class="is-position-fixed is-bottom-42px-desktop is-left-38px-desktop is-bottom-80px-mobile is-margin-left-111px-mobile is-bottom-35px-tablet-only is-left-32px-tablet-only"
          type="primary" href="/programme/yoga-for-beginners-course">View program</app-button>
      </div>
    </ng-template>
  </owl-carousel-o>
  }
  <!-- make space for the dots -->
  <div class="mb-5">&nbsp;</div>
</section>