<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <line
    x1="2.18203"
    y1="10.4833"
    x2="13.3392"
    y2="10.4833"
    stroke="#555547"
    stroke-width="1.7"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <line x1="2.18203" y1="5.15" x2="13.3392" y2="5.15" stroke="#555547" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
</svg>
