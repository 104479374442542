import { Component } from '@angular/core';

@Component({
  selector: 'app-back-videos',
  template: `<app-show-more-page
    type="video"
    subPath="back_pain_health_videos"
    categorySlug="Rücken"
    headline="Für deinen Rücken"
    subheadline="Zum Stärken und Entspannen"
    i18n-categorySlug="Show More Page"
    i18n-headline="Show More Page"
    i18n-subheadline="Show More Page"
  ></app-show-more-page>`,
})
export class BackVideosComponent {}
