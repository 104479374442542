<div class="bookmarks-menu is-flex is-flex-direction-column">
  <input type="text" style="display: none" />
  <!-- ↑ to avoid autofocus on other elements -->
  <div class="header is-flex is-justify-content-space-between is-align-items-center">
    <span class="text">{{ title }}</span>
    <app-button type="tertiary" (onClick)="closeMenu()" i18n>Fertig</app-button>
  </div>
  <hr />
  <div class="body">
    <app-bookmarks-menu-list [videoRef]="videoRef" [listsChecked]="bookmarksListChecked$ | async"></app-bookmarks-menu-list>
  </div>
  <hr />
  <div class="footer">
    <app-bookmarks-menu-add-list-item [showDescription]="!(bookmarksLists$ | async)?.length"
      [videoId]="videoRef?.id"></app-bookmarks-menu-add-list-item>
  </div>
</div>