import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  fragment: string;
  fullUrl: string;
}

@Injectable()
export class AppRoutingSerializer
  implements RouterStateSerializer<RouterStateParams>
{
  private origin: string;

  constructor(@Inject(DOCUMENT) document: Document) {
    this.origin = document.location.origin;
  }

  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams, fragment },
    } = routerState;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params } = route;

    const fullUrl = this.origin + url;

    return { url, params, queryParams, fragment, fullUrl };
  }
}
