import { Type } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CtaClickedEvent } from '../api/models/cta-clicked-event';
import { Viewport } from './core.state';
import { ScriptOptions } from './script/script.service';
import { Toast } from './toast/toast.model';

export namespace CoreActions {
  export class AddMetaTags {
    static readonly type = '[Core] Add meta tags';
    constructor(public tags: MetaDefinition[]) {}
  }

  export class CloseAnnouncement {
    static readonly type = '[Core] Close announcement';
    constructor(public id: number) {}
  }

  export class CloseMobileNavigation {
    static readonly type = '[Core] Close mobile navigation';
  }

  export class CloseAllModals {
    static readonly type = '[Core] Close all modals';
  }

  export class CloseModal {
    static readonly type = '[Core] Close modal';
    constructor(public id: string) {}
  }

  export class DecreaseRequestCount {
    static readonly type = '[Core] Decrease request count';
  }

  export class FetchAnnouncement {
    static readonly type = '[Core] Fetch announcement';
  }

  export class FetchSeoData {
    static readonly type = '[Core] Fetch SEO data';
    constructor(
      public modelName?: string,
      public slug?: string,
    ) {}
  }

  export class FetchSiteParams {
    static readonly type = '[Core] Fetch site params';
  }

  export class FetchUnit {
    static readonly type = '[Core] Fetch unit';
    constructor(public id: string) {}
  }

  export class HideToast {
    static readonly type = '[Core] Hide toast';
    constructor(public toast: Toast) {}
  }

  export class IncreaseRequestCount {
    static readonly type = '[Core] Increase request count';
  }

  export class JoinProgram {
    static readonly type = '[Core] Join program';
    constructor(
      public slug: string,
      public delta?: number,
    ) {}
  }

  export class LoadScript {
    static readonly type = '[Core] Load script';
    constructor(
      public id: string,
      public path: string,
      public options?: ScriptOptions,
    ) {}
  }

  export class OpenMobileNavigation {
    static readonly type = '[Core] Open mobile navigation';
  }

  export class OpenModal {
    static readonly type = '[Core] Open modal';
    constructor(
      public id: string,
      public component: Type<unknown>,
      public options: NgbModalOptions = {},
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      public inputs?: any,
    ) {}
  }

  export class ReinsertScripts {
    static readonly type = '[Core] Reinsert scripts';
    constructor(public scripts: Array<HTMLScriptElement>) {}
  }

  export class RemoveMetaTags {
    static readonly type = '[Core] Remove meta tags';
    constructor(public tagNames: Array<string>) {}
  }

  export class RemoveScript {
    static readonly type = '[Core] Remove script';
    constructor(public id: string) {}
  }

  export class SendToGTM {
    static readonly type = '[Core] Send to GTM';
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    constructor(public gtmEvents: any) {}
  }

  export class SendCtaClickedEvent {
    static readonly type = '[Core] Send CTA Clicked Event';
    constructor(public ctaClickedEvent: CtaClickedEvent) {}
  }

  export class GetTrackingEvents {
    static readonly type = '[Core] Get Tracking Events';
  }

  export class SetBottomsheet {
    static readonly type = '[Core] Set bottomsheet';
    constructor(
      public component: Type<unknown>,
      public showCloseIcon = false,
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      public inputs?: any,
    ) {}
  }

  export class SetContentOnly {
    static readonly type = '[Core] Set content only';
    constructor(public contentOnly: boolean) {}
  }

  export class SetNextUrl {
    static readonly type = '[Core] Set next URL';
    constructor(public nextUrl: string) {}
  }

  export class SetNoindex {
    static readonly type = '[Core] Set noindex';
    constructor(public noindex: boolean) {}
  }

  export class SetRedirected {
    static readonly type = '[Core] Set redirected';
    constructor(public redirected: boolean) {}
  }

  export class SetSectionId {
    static readonly type = '[Core] Set section ID';
    constructor(public sectionId: string) {}
  }

  export class SetTitle {
    static readonly type = '[Core] Set title';
    constructor(public title: string) {}
  }

  export class ShowToast {
    static readonly type = '[Core] Show toast';
    constructor(public toast: Toast) {}
  }

  export class ViewportChanged {
    static readonly type = '[Core] Viewport changed';
    constructor(public viewport: Viewport) {}
  }

  export class FetchFooter {
    static readonly type = '[Core] Fetch footer';
  }
}
