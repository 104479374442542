<a *ngIf="(viewport$ | async) === 'mobile'; else desktopOrTablet" class="sort-by-button"
  tooltipClass="filter-tooltip-wrapper sort-by-tooltip-wrapper" (click)="showSlideIn()">
  {{ label$ | async }}<svg-arrow class="arrow"></svg-arrow>
</a>
<ng-template #desktopOrTablet>
  <a class="no-link sort-by-button" [ngbTooltip]="content" placement="bottom" triggers="click" [autoClose]="'outside'"
    tooltipClass="filter-tooltip-wrapper sort-by-tooltip-wrapper">
    {{ label$ | async }} <svg-arrow-down class="icon is-small"></svg-arrow-down>
  </a>
</ng-template>
<ng-template #content>
  <app-sort-by></app-sort-by>
</ng-template>