import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreActions } from 'src/app/core/core.actions';
import { DeleteBookmarksListModalComponent } from '../delete-bookmarks-list-modal/delete-bookmarks-list-modal.component';
import { RenameBookmarksListModalComponent } from '../rename-bookmarks-list-modal/rename-bookmarks-list-modal.component';

@Component({
  selector: 'app-edit-bookmarks-list-menu',
  templateUrl: './edit-bookmarks-list-menu.component.html',
  styleUrls: ['./edit-bookmarks-list-menu.component.sass'],
})
export class EditBookmarksListMenuComponent {
  public static readonly MODAL_ID = 'EditBookmarksListMenu';
  @Input() listId: number;

  constructor(private store: Store) {}

  close() {
    this.store.dispatch([new CoreActions.CloseModal(EditBookmarksListMenuComponent.MODAL_ID), new CoreActions.SetBottomsheet(null)]);
  }

  changeName() {
    this.store.dispatch(
      new CoreActions.OpenModal(
        RenameBookmarksListModalComponent.MODAL_ID,
        RenameBookmarksListModalComponent,
        {
          windowClass: 'rename-bookmarks-list-modal-window',
        },
        { listId: this.listId },
      ),
    );
    this.close();
  }

  deleteList() {
    this.store.dispatch(
      new CoreActions.OpenModal(
        DeleteBookmarksListModalComponent.MODAL_ID,
        DeleteBookmarksListModalComponent,
        {
          windowClass: 'delete-bookmarks-list-modal-window',
        },
        { listId: this.listId },
      ),
    );
    this.close();
  }
}
