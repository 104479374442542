<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
    stroke="currentColor"
    stroke-width="1.5"
  />
  <path
    d="M12.7368 6L12.7368 14.4277L11.2982 14.4277L11.2982 6L12.7368 6ZM12 16.1618C12.2807 16.1618 12.5205 16.2486 12.7193 16.422C12.9064 16.5954 13 16.815 13 17.0809C13 17.3584 12.9064 17.578 12.7193 17.7399C12.5205 17.9133 12.2807 18 12 18C11.7193 18 11.4854 17.9133 11.2982 17.7399C11.0994 17.578 11 17.3584 11 17.0809C11 16.815 11.0994 16.5954 11.2982 16.422C11.4854 16.2486 11.7193 16.1618 12 16.1618Z"
    fill="currentColor"
  />
</svg>
